import PropTypes from 'prop-types';
import React from 'react';

const statusConfig = [
    {id: 0, name: "全部"},
    {id: 3, name: "已使用"},
    {id: 2, name: "未使用"},
    {id: 1, name: "草稿"}
];

const TeachingDocStatusName = ({status}) => {
    let model = statusConfig.find(item => item.id === status);
    if (model) {
        return model.name;
    } else {
        return null;
    }
};

TeachingDocStatusName.propTypes={
    status: PropTypes.number.isRequired
};

export default TeachingDocStatusName