import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {Card, Table, Divider, Select, Space, Tabs, Descriptions, Typography, Row, Col, Button} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";
import styles from './index.module.css';
import {getPaperStudentPK} from "../../../lib/api_school_papers";
import {
    G2,
    Chart,
    Interval,
    Axis,
    Tooltip,
    Coordinate,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";

let {Column} = Table;
let {Option} = Select;
let {TabPane} = Tabs;
const {Text} = Typography;


class Pk extends Component {
    state = {
        paper_id: 0,
        books: [],
        list: [],
        papers: [],
        // currentPaper: null,
        chapters1: [],
        chapters2: [],
        chapters1_id: 0,
        chapters2_id: 0,
        student_papers: [], // 学生试卷
        students: [], // 学员列表
        topic_models: [],
        student_paper_topics: [], // 获取学生具体题目登记情况
        group_paper_topics: [], // 年级数据
        classes: [], // 所有的同年级班级
        loading: false,
        classesinfo1: null,
        classesinfo2: null,
        data1: []
    };

    componentWillMount() {

    }


    componentDidMount() {
        this.fetchPaperInfo();
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchPaperInfo = () => {
        let {paper_id, student_ids} = this.props;
        if (paper_id && student_ids) {
            getPaperStudentPK(paper_id, student_ids.join(','))
                .then((result) => {
                    if (result.code === 0) {
                        console.log(result.data);
                        this.setState({
                            student_papers: result.data,
                        })
                    }
                })
        }

    };

    fetchData = () => {
        // this.fetchClassBooks();
    };

    renderModelTable = (topic_model) => {
        let {student_papers} = this.state;
        let {class_student_paper_topics}= this.props;
        let names = student_papers.map(student_paper => student_paper.student_info.s_realname);
        let data = topic_model.topics.map(item => {
            let model = {
                topic_number: item.topic_number
            };

            let total_score = 0;
            let total_count = 0;
            student_papers.forEach(student_paper => {
                let paper_model = student_paper.paper_models.find(sm => sm.topic_model_id === topic_model.topic_model_id);
                if (paper_model) {
                    let topic = paper_model.topics.find(tt => tt.topic_id === item.topic_id);
                    model[student_paper.student_info.s_realname] = topic.score;
                    total_score += topic.score;
                    total_count += 1;
                }
            });

            model.ave_score = parseInt(total_score / total_count * 10) / 10;

            // 计算班级平均
            let class_total = 0;
            let list = class_student_paper_topics.filter(cspt=> cspt.topic_id === item.topic_id);
            list.forEach(cspt=>{
                class_total += cspt.score
            });
            model.class_ave = parseInt(class_total*10/list.length)/10
            return model;
        });


        console.log(data, names);

        return (
            <Table dataSource={data} title={() => topic_model.topic_model_name} rowKey={"id"} pagination={false}
                   size={"small"} bordered>
                <Column
                    title="题号"
                    dataIndex="topic_number"
                    key="题号"
                />
                <Column
                    title="班级平均"
                    dataIndex={"class_ave"}
                    key="班级平均"
                />
                {names.map(item => <Column
                    title={item}
                    dataIndex={item}
                    key={item}
                />)}

                <Column
                    title="PK学员平均分"
                    dataIndex="ave_score"
                    key="PK学员平均分"
                />
            </Table>
        )
    };

    render() {
        let {student_papers} = this.state;
        let {paperlist, chapters} = this.props.account;
        let {paper_id, class_id1, class_id2, class_name1, class_name2, class_student_papers,paper_models} = this.props;

        if (!student_papers) {
            return null;
        }
        let currentPaper = paperlist.find(item => item.paper_id === paper_id);

        // 计算分值段
        let data1 = [];
        let data2 = [];
        // let fields = student_papers.length > 0 ? student_papers[0].paper_models.map(item => item.topic_model_name) : [];
        console.log(student_papers);
        let class_score = 0;
        class_student_papers.forEach(item => {
            class_score += item.student_score
        });
        data1.push({
            label: "班级平均",
            score: class_score / class_student_papers.length
        });
        paper_models.forEach(item=>{
            data2.push({
                name: "班级平均",
                type: item.topic_model_name,
                score: item.class_per,
            })
        })
        student_papers.forEach(student_paper => {
            data1.push({
                label: student_paper.student_info.s_realname,
                score: student_paper.student_paper.student_score
            });

            student_paper.paper_models.forEach(item => {
                data2.push({
                    name: student_paper.student_info.s_realname,
                    type: item.topic_model_name,
                    score: item.student_score,
                })

            });

            // data1[0][student_paper.student_info.s_realname] = 100;
            // fields.push(student_paper.student_info.s_realname)
        });

        console.log(data2);

        const ds1 = new DataSet();
        const dv1 = ds1.createView().source(data1);
        dv1.transform({
            type: "fold",
            fields: ["score"],
            // 展开字段集
            key: "type",
            // key字段
            value: "value" // value字段
        });
        console.log(dv1)

        paper_models = student_papers.length > 0 ? student_papers[0].paper_models : [];

        return (
            <Card bordered={false} size={"small"}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Card size="small">
                            <Chart
                                height={200}
                                // width={500}
                                data={data1}
                                autoFit
                            >
                                <Legend/>
                                <Coordinate transpose scale={[1, -1]}/>
                                <Axis
                                    name="label"
                                    label={{
                                        offset: 12
                                    }}
                                />
                                <Axis name={"score"} position={"right"}/>

                                <Tooltip/>
                                <Interval
                                    position="label*score"
                                    color={"#3695ca"}
                                    adjust={[
                                        {
                                            type: "dodge",
                                            marginRatio: 1 / 32
                                        }
                                    ]}
                                />
                            </Chart>
                        </Card>

                    </Col>
                    <Col span={12}>
                        <Card size="small">
                            <Chart
                                height={200}
                                // width={500}
                                data={data2}
                                autoFit
                            >
                                <Legend/>
                                <Coordinate transpose scale={[1, -1]}/>
                                <Axis
                                    name="name"
                                    label={{
                                        offset: 12
                                    }}
                                />
                                {/*{fields.map(item => <Axis name={item} position={"right"}/>)}*/}
                                <Axis name="score" position={"right"}/>
                                <Tooltip/>
                                <Interval
                                    position="name*score"
                                    color={"type"}
                                    adjust={[
                                        {
                                            type: "dodge",
                                            marginRatio: 1 / 32
                                        }
                                    ]}
                                />
                            </Chart>

                        </Card>

                    </Col>
                </Row>
                {paper_models.map(item => this.renderModelTable(item))}
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Pk));
