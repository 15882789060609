/**
 * @name: reducer集合
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import {combineReducers} from 'redux';
import accountReducer, {initialAccountState} from "./account.js";
import passwordModalReducer, {initialPasswordModalState} from "./password-modal.js";
import currentClassReducer, {initialCurrentClassState} from "./current-class.js";

const initialState = {
    account: initialAccountState,
    passwordModal: initialPasswordModalState,
    currentClass: initialCurrentClassState,
    // school: initialSchoolState,
    // schoolModal: initialSchoolModalState,
};

const reducer = combineReducers({
    account: accountReducer,
    passwordModal: passwordModalReducer,
    currentClass: currentClassReducer,
    // school: schoolReducer,
    // schoolModal: schoolModalReducer,
});


export {
    reducer as default,
    initialState,
}