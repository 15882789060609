import axios from './rest-axios';

const getStudents = () => axios.get('/v1/students/');
const getMytudents = () => axios.get('/v1/students/my');
const getClassestudents = (id) => axios.get(`/v1/students/${id}`);
const getStudentInfo = (id) => axios.get(`/v1/students/info/${id}`);
const getStudentReport = (id, grade, subject_id) => axios.get(`/v1/students/info/${id}/report`, {grade, subject_id});
const putStudent = (model) => axios.put(`/v1/students/${model.id}`, model);
const postStudent = (model) => axios.post(`/v1/students/`, model);
const putStudentWx = (model) => axios.put(`/v1/students/${model.id}/qx_wx_student_id`, model);

export {
    getStudents,
    getMytudents,
    getClassestudents,
    getStudentInfo,
    getStudentReport,
    putStudent,
    postStudent,
    putStudentWx
};
