import axios from './rest-axios';

const getClassBooks = () => axios.get('/v1/class_book/');
const postClassBooks = (book_id, class_id,grade,subject_id,name) => axios.post('/v1/class_book/', {book_id, class_id,grade,subject_id,name});
const deleteClassBooks = (id) => axios.delete(`/v1/class_book/${id}`);

export {
    getClassBooks,
    postClassBooks,
    deleteClassBooks
};
