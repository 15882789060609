import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {Card, Table, Divider, Select, Space} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";

let {Column} = Table;
let {Option} = Select;

class Index extends Component {
    state = {
        book_id: 0,
        books: [],
        list: [],
        chapters1: [],
        chapters2: [],
        chapters1_id: 0,
        chapters2_id: 0,
    };


    componentDidMount() {
        let {booklist} = this.props.account;
        let {currentClass} = this.props;
        if (booklist && booklist.length > 0) {
            this.setState({
                books: booklist.filter(item => item.class_id === currentClass.class_id && item.subject_id === currentClass.subject_id)
            }, () => {
                if (this.state.books.length > 0) {
                    this.filterBooks(this.state.books[0].book_id);
                }
            });
            // this.setState({
            //     book_id: this.props.account.booklist[0].book_id
            // }, this.fetchStudents)
        }
        // this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchClassBooks = () => {
        getClassBooks(this.props.currentClass.class_id)
            .then((result) => {
                if (result.code === 0) {
                    let book_id = 0;
                    if (result.data && result.data.length > 0) {
                        book_id = result.data[0].book_id;
                    }
                    this.setState({
                        books: result.data,
                        book_id
                    }, this.fetchStudents)
                }
            })
    };

    fetchStudents = () => {
        if (this.state.book_id > 0) {
            getClassBookInfo(this.props.currentClass.class_id, this.state.book_id, this.state.chapters2_id)
                .then((result) => {
                    if (result.code === 0) {
                        // result.data.forEach(item => {
                        //     let py = pinyin(item.s_realname);
                        //     if (py && py.length > 0) {
                        //         console.log(py, py[0][0][0]);
                        //         item.py = py[0][0][0].toUpperCase();
                        //     }
                        //     // console.log(py);
                        // });
                        // const sortedData = result.data.sort((a, b) => {
                        //     return a.py.localeCompare(b.py);
                        // });
                        this.setState({
                            list: result.data,
                        })
                    }
                })
        }

    };

    fetchData = () => {
        // this.fetchClassBooks();
    };

    filterBooks = (key) => {
        console.log(key);
        this.setState({
            book_id: key,
            chapters1_id: 0,
            chapters2_id: 0,
        }, this.filterChapter1)
    };

    filterChapter1 = () => {
        let {list, book_id, chapters1_id, chapters2_id} = this.state;
        let {booklist, chapters} = this.props.account;
        let currentBook = booklist.find(item => item.book_id === book_id);
        let chapters1 = [];
        if (currentBook) {
            chapters1 = chapters.filter(item => item.grade === currentBook.grade && item.subject === currentBook.subject_id && item.pid === 0)
        }
        if (chapters1.length > 0 && !chapters1_id) {
            chapters1_id = chapters1[0].id;
        }
        console.log(chapters1_id);
        this.setState({
            chapters1: chapters1,
            chapters1_id: chapters1_id,
            chapters2_id: 0,
        }, this.filterChapter2)
    };
    filterChapter2 = () => {
        let {list, book_id, chapters1_id, chapters2_id} = this.state;
        let {booklist, chapters} = this.props.account;
        let currentBook = booklist.find(item => item.book_id === book_id);
        let chapters2 = [];
        if (chapters1_id) {
            chapters2 = chapters.filter(item => item.grade === currentBook.grade && item.subject === currentBook.subject_id && item.pid === chapters1_id)
        }
        console.log(chapters2)
        if (chapters2.length > 0 && chapters2_id === 0) {
            chapters2_id = chapters2[0].id;
        }
        this.setState({
            chapters2: chapters2,
            chapters2_id: chapters2_id,
        }, this.fetchStudents)
    };


    render() {
        let {list, book_id, chapters1_id, chapters2_id, chapters1, chapters2, books} = this.state;
        // 过滤题目
        if (!chapters2_id) {
            list = [];
        } else {
            let currentChapter2 = chapters2.find(item => item.id === chapters2_id);
            let point_main_ids = currentChapter2.point_mains.map(item => item.point_main_id);
            console.log(point_main_ids);
            list = list.filter(item => {
                for (let i in item.answers) {
                    let answer = item.answers[i];
                    if (point_main_ids.includes(answer.topic_answer_point1)
                        || point_main_ids.includes(answer.topic_answer_point2)
                        || point_main_ids.includes(answer.topic_answer_point3)
                    ) {
                        return true;
                    }
                }
                return false
            })
        }
        return (
            <PageContent title={"作业分析"}>
                <Card bordered={false}>
                    <Space>
                        作业本：
                        <Select defaultValue={0} value={book_id} style={{width: 200}} onChange={this.filterBooks}>
                            {books.map(item => <Option value={item.book_id}>(<SubjectName
                                id={item.subject_id}/>) {item.book_name}</Option>)}
                        </Select>
                        章节：
                        <Select defaultValue={0} value={chapters1_id} style={{width: 200}}
                                onChange={(value) => this.setState({
                                    chapters1_id: value,
                                    chapters2_id: 0,
                                }, this.filterChapter2)}>
                            {chapters1.map(item => <Option value={item.id}>{item.name}</Option>)}
                        </Select>
                        <Select defaultValue={0} value={chapters2_id} style={{width: 200}}
                                onChange={(value) => this.setState({chapters2_id: value})}>
                            {chapters2.map(item => <Option value={item.id}>{item.name}</Option>)}
                        </Select>
                    </Space>
                    <Divider/>
                    <Table dataSource={list} rowKey={"id"} pagination={false} size={"small"} bordered>
                        <Column
                            title="题号"
                            dataIndex="topic_number"
                            key="topic_number"
                            width={100}
                            render={(topic_number,record, index)=> index+1}
                        />

                        <Column
                            title="题目"
                            dataIndex="topic_content"
                            key="topic_content"
                            render={(topic_content) => <div dangerouslySetInnerHTML={{__html: topic_content}}/>}
                        />

                        <Column
                            title="错误名单"
                            dataIndex="student_names"
                            key="student_names"
                            width={200}
                            render={(student_names) => student_names.join(',')}
                        />


                    </Table>

                </Card>
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
