import axios from './rest-axios';

const getChapter = () => axios.get('/v1/chapter/');
const postChapter = (model) => axios.post('/v1/chapter/', model);
const putChapter = (model) => axios.put(`/v1/chapter/${model.id}`, model);
const deleteChapter = (id) => axios.delete(`/v1/chapter/${id}`);
const postChapterBind = (model) => axios.post(`/v1/chapter/bind`, model);
const postChapterUnBind = (model) => axios.post(`/v1/chapter/unbind`, model);

export {
    getChapter,
    postChapter,
    putChapter,
    deleteChapter,
    postChapterBind,
    postChapterUnBind
};
