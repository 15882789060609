import axios from './rest-axios';

const getTeachingDoc = () => axios.get('/v1/teaching_doc/');
const getTeachingDocById = (id) => axios.get(`/v1/teaching_doc/${id}`);
const getJingZhunTeachingDocById = (id) => axios.get(`/v1/teaching_doc/${id}/jingzhun`);
const postTeachingDoc = (model) => axios.post('/v1/teaching_doc/', model);
const getTeachingDocMy = () => axios.get('/v1/teaching_doc/my');
const deleteTeachingDocById = (id) => axios.delete(`/v1/teaching_doc/${id}`);
const submitTeachingDoc = (model) => axios.post('/v1/teaching_doc/submit', model);
const getTeachingDocAnswers = (id) => axios.get(`/v1/teaching_doc/${id}/answers`);
const getTeachingDocUnSubmit = (id) => axios.get(`/v1/teaching_doc/${id}/unSubmit`);
const getTeachingDocPrev = (student_id, subject, id) => axios.get(`/v1/teaching_doc/prev`, {student_id, subject, id});

export {
    getTeachingDoc,
    getTeachingDocById,
    postTeachingDoc,
    getTeachingDocMy,
    deleteTeachingDocById,
    submitTeachingDoc,
    getTeachingDocAnswers,
    getJingZhunTeachingDocById,
    getTeachingDocUnSubmit,
    getTeachingDocPrev
};
