import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {
    Card,
    Table,
    Divider,
    Select,
    Space,
    Tabs,
    Descriptions,
    Typography,
    Row,
    Col,
    Button,
    Tooltip as AntdTooltip,
    Tag
} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";
// import styles from './index.module.css';
import {getPaperInfo} from "../../../lib/api_school_papers";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";
// import Pk from './pk';
import {getStudentInfo} from "../../../lib/api_school_students";
import DengDiName from '../../components/dengdi-name';

let {Column} = Table;
let {Option} = Select;
let {TabPane} = Tabs;
const {Text} = Typography;


const data = [
    {
        month: "单元自测A",
        city: "分数",
        score: 80
    },
    {
        month: "1月阶段测试",
        city: "分数",
        score: 90
    },
    {
        month: "2月阶段测试",
        city: "分数",
        score: 85
    },
    {
        month: "单元自测A",
        city: "班级平均",
        score: 82
    },
    {
        month: "1月阶段测试",
        city: "班级平均",
        score: 79
    },
    {
        month: "2月阶段测试",
        city: "班级平均",
        score: 80
    },

];
const cols = {
    // month: {
    //     range: [0.1, 0.8]
    // },
    score: {
        alias: '分数',
    }
};

class Index extends Component {
    state = {
        student_info: null,
        isVIP: false
    };

    componentWillMount() {
        this.fetchData();
    }

    componentDidMount() {
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    fetchData = () => {
        // this.fetchClassBooks();
        getStudentInfo(this.props.id)
            .then(res => {
                if (res.code === 0) {
                    this.setState({
                        student_info: res.data,
                        isVIP: true
                    });
                }else if(res.code === 1001){
                    this.setState({
                        isVIP: false
                    })
                }
            })
    };


    render() {
        let {student_info,isVIP} = this.state;
        if(!isVIP){
            return <div>该学生不是会员</div>
        }

        let {id} = this.props;
        if (!student_info) {
            return null;
        }
        let data = [];
        let data2 = [];
        student_info.papers.forEach(item => {
            data.push({
                name: item.name,
                score_name: "分数",
                score: item.student_score
            });
            data.push({
                name: item.name,
                score_name: "班级平均",
                score: item.score_ave
            });

            data2.push({
                name: item.name,
                score_name: "得分率",
                score: item.dfl
            });
            data2.push({
                name: item.name,
                score_name: "班级平均",
                score: item.dfl_ave
            });
        });
        return (
            <PageContent title={"学生档案"}>
                <Card bordered={false} size={"small"}>
                    <Row>
                        <Col flex={"auto"}>
                            <Space size={24}>
                                <span>学生姓名：{student_info.s_realname}</span>
                                <span>性别：{student_info.sex === 1 ? "男" : "女"}</span>
                                <span>班级：{student_info.class_info.class}</span>
                            </Space>
                        </Col>
                        <Col>
                            <Link to={`/school/student/${id}/book`}>

                                <Button>查看错题本</Button>
                            </Link>
                        </Col>
                    </Row>
                </Card>
                {/*{this.renderPaperInfo()}*/}
                <Card bordered={false} size={"small"} title={"成绩走势"}>
                    <Row gurrer={24} style={{width: "100%"}}>
                        <Col span={12}>
                            <Chart height={200} width={400} data={data} scale={{
                                score: {
                                    alias: '分数',
                                    range: [0.1, 0.9]
                                },
                            }} forceFit>
                                <Legend/>
                                <Axis name="name" label={{formatter: (text)=> text.slice(0,10)}}/>
                                <Axis name="score" title/>
                                <Tooltip
                                    crosshairs={{
                                        type: "y"
                                    }}
                                />
                                <Geom
                                    type="line"
                                    position="name*score"
                                    size={2}
                                    color={"score_name"}
                                    // shape={"smooth"}
                                />
                                <Geom
                                    type="point"
                                    position="name*score"
                                    size={4}
                                    shape={"circle"}
                                    color={"score_name"}
                                    style={{
                                        stroke: "#fff",
                                        lineWidth: 1
                                    }}
                                />
                            </Chart>
                        </Col>

                        <Col span={12}>
                            <Chart height={200} width={400} data={data2} scale={{
                                score: {
                                    alias: '得分率',
                                    formatter: val => `${val}%`,
                                    range: [0.1, 0.9]
                                }
                            }} forceFit>
                                <Legend/>
                                <Axis name="name" label={{formatter: (text)=> text.slice(0,10)}}/>
                                <Axis name="score" title/>
                                <Tooltip
                                    crosshairs={{
                                        type: "y"
                                    }}
                                />
                                <Geom
                                    type="line"
                                    position="name*score"
                                    size={2}
                                    color={"score_name"}
                                    // shape={"smooth"}
                                />
                                <Geom
                                    type="point"
                                    position="name*score"
                                    size={4}
                                    shape={"circle"}
                                    color={"score_name"}
                                    style={{
                                        stroke: "#fff",
                                        lineWidth: 1
                                    }}
                                />
                            </Chart>
                        </Col>
                        <Col span={24}>
                            <Table dataSource={student_info.papers} rowKey={"id"} pagination={false} size={"small"}
                                   bordered>
                                <Column
                                    title="考试名称"
                                    dataIndex="name"
                                    key="考试名称"
                                />
                                <Column
                                    title="成绩"
                                    dataIndex="student_score"
                                    key="成绩"
                                />
                                <Column
                                    title="得分率"
                                    key="得分率"
                                    render={(record) => {
                                        return record.dfl + "%"
                                    }}
                                />
                                <Column
                                    title="班级平均"
                                    dataIndex="score_ave"
                                    key="班级平均"
                                />
                                <Column
                                    title="班级得分率"
                                    key="班级得分率"
                                    render={(record) => {
                                        return record.dfl_ave + "%"
                                    }}/>

                            </Table>


                        </Col>
                    </Row>
                </Card>

                <Card bordered={false} size={"small"} title={"作业本登记情况"}>
                    <Table dataSource={student_info.books} rowKey={"id"} pagination={false} size={"small"} bordered>
                        <Column
                            title="作业本"
                            dataIndex="name"
                            key="作业本"
                        />
                        <Column
                            title="本章总题数"
                            dataIndex="total"
                            key="本章总题数"
                        />
                        <Column
                            title="录入题数"
                            dataIndex="register"
                            key="录入题数"
                        />
                        <Column
                            title="错误题数"
                            dataIndex="wrong_count"
                            key="错误题数"
                        />
                    </Table>
                </Card>
                <Card bordered={false} size={"small"} title={"试卷登记情况"}>
                    <Table dataSource={student_info.papers} rowKey={"id"} pagination={false} size={"small"} bordered>
                        <Column
                            title="试卷名称"
                            dataIndex="name"
                            key="试卷名称"
                        />
                        <Column
                            title="试卷总分"
                            dataIndex="score"
                            key="试卷总分"
                        />
                        <Column
                            title="学生得分"
                            dataIndex="student_score"
                            key="学生得分"
                        />
                        <Column
                            title="班级平均"
                            dataIndex="score_ave"
                            key="班级平均"
                        />
                        <Column
                            title="等第"
                            key="等第"
                            render={(record) => <DengDiName student_score={record.student_score} score={record.score}/>}
                        />
                        <Column
                            title="操作"
                            key="操作"
                            render={(record) => {
                                return (
                                    <Link to={`/school/paper/${record.id}`}>查看分析报告</Link>
                                )
                            }}
                        />
                    </Table>
                </Card>
            </PageContent>
        );
    }
}

Index.propTypes = {
    id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
