import axios from './rest-axios';

const getChapters = (student_id,grade,subject) => axios.get(`/school/v1/books/chapters`, {student_id,grade,subject});
const getChapters2 = (class_id,subject) => axios.get(`/school/v1/books/chapters2`, {class_id,subject});
const postSearch = (student_id,grade,subject,point_main_id) => axios.post(`/school/v1/books/search`, {student_id,grade,subject,point_main_id});
const postSearch2 = (class_id,grade,subject,point_main_id,min_value,max_value) => axios.post(`/school/v1/books/search2`, {class_id,grade,subject,point_main_id,min_value,max_value});

export {
    getChapters,
    postSearch,
    getChapters2,
    postSearch2
};
