import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import AccountMenuComponent from '../components/account-menu/account-menu';
import {signOut} from "../reducers/account";

import {Modal, Form, Input, message} from 'antd';
import {showPasswordModal, hidePasswordModal} from "../reducers/password-modal";
import {postChangePassword} from "../lib/api_account";
import md5 from 'md5';


class ChangePassword extends Component {
    formRef = React.createRef();
    state = {
        confirmDirty: false,
    };

    validateToNextPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    };

    compareToFirstPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次密码输入不一致！');
        } else {
            callback();
        }
    };

    handleConfirmBlur = e => {
        const {value} = e.target;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };


    handleCancel = () => {
        this.props.hidePasswordModal();
    };

    handleOk = () => {
        this.formRef.current.validateFields()
            .then(values => {
                console.log(values);
                // 密码加密
                postChangePassword(md5(values.oldpassword), md5(values.password))
                    .then(result => {
                        if (result.code === 0) {
                            message.success("修改成功！");
                            this.props.hidePasswordModal();
                        }
                    })

            });
    };

    render() {
        const {passwordModal} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        return (
            <Modal
                title="修改密码"
                visible={passwordModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <Form {...formItemLayout} ref={this.formRef}>
                    <Form.Item
                        label="旧密码"
                        name="oldpassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入旧密码!',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="password"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        label="重复新密码"
                        name="confirm"
                        hasFeedback
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: '请再次输入密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('两次密码输入不一致!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}


ChangePassword.propTypes = {};

const mapStateToProps = state => ({
    passwordModal: state.passwordModal
});

const mapDispatchToProps = dispatch => ({
    showPasswordModal: () => dispatch(showPasswordModal()),
    hidePasswordModal: () => dispatch(hidePasswordModal())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword));

