import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Row,
    Col,
    Typography,
    Progress,
    Space,
    Button,
    Collapse,
    Divider,
    message,
    Modal,
    Empty,
    Checkbox,
    Radio,
    Drawer
} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getTeachingDocMy, getJingZhunTeachingDocById, getTeachingDocAnswers} from "../../../lib/api_teaching_doc";
import TeachingDocStatusName from '../../../components/teaching_doc/teaching-doc-status-name';
import SemesterName from '../../../components/semester-name';
import {SnippetsOutlined, ClockCircleOutlined, ContainerOutlined, AimOutlined, WifiOutlined} from '@ant-design/icons';
import styles from './detail.module.css';
import {putPiGai} from "../../../lib/api_topic_answer";
import config from '../../../config';
import Report from './report';

const {Panel} = Collapse;

const {Text} = Typography;
const url = window.location.host.indexOf('localhost') > -1 ? "http://127.0.0.1:8001" : `${window.location.protocol}//${window.location.host}`;
const socket = require('socket.io-client')(url);
const {CheckableTag} = Tag;

class JingZhunDetail extends Component {
    state = {
        list: [],
        data: null,
        answers: [],
        showAnswerModal: false,
        showReportModal: false,
        currentAnswerIndex: 0,
        rumen: {
            topics: []
        },  // 入门测试
        chumen: {
            topics: []
        },  // 出门测试
    };

    componentDidMount() {
        this.fetchData();

        socket.on("reconnect", () => {
            console.log("申请加入房间" + this.state.data.student_id);
            socket.emit("teacher join", {
                room_id: this.state.data.student_id,
                teaching_doc_id: this.props.id
            });
        });
        socket.on('teacher joined', (data) => {
            console.log("老师加入房间")
        });
        socket.on('student joined', (data) => {
            console.log("学生加入房间");
            console.log("发送学生未完成题目");
            socket.emit("new topic", {
                room_id: this.state.data.student_id,
                teaching_doc_id: this.props.id
            });

        });
        socket.on('send topic', (data) => {
            console.log("收到题目", data);
            this.fetchAnswers();
        });
        socket.on('send topics', (data) => {
            console.log("收到一组题目", data);
            this.fetchAnswers();
        });
        socket.on('new topic', (data) => {
            console.log("需要刷新题目", data);
            this.fetchAnswers();
        });

        socket.on('success', (data) => {
            console.log("操作成功", data);
            message.success(data.message)
        });
        socket.on('error', (data) => {
            console.log("操作失败", data);
            message.error(data.message)
        });


        socket.on('submit topic', (data) => {
            console.log("学生提交了练习");
            message.success("学生提交了练习");
            this.fetchAnswers();
        });
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getJingZhunTeachingDocById(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    console.log(result);
                    this.setState({
                        data: Object.assign(JSON.parse(result.data.config), {id: this.props.id}),
                        rumen: result.data.rumen,
                        chumen: result.data.chumen,
                        // step: 0,
                    });

                    socket.emit("teacher join", {
                        room_id: result.data.student_id,
                        teaching_doc_id: this.props.id
                    });

                    this.fetchAnswers();

                }
            })
    };


    fetchAnswers = () => {
        getTeachingDocAnswers(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    console.log(result);
                    this.setState({
                        answers: result.data
                    }, this.statCount);
                }
            })
    };

    // 数据统计
    statCount = () => {
        let {answers, data, rumen} = this.state;
        let taught_point_count = 0;  // 已授知识点
        let taught_topicType_count = 0; // 已授题型
        let taught_example_count = 0;  // 已授例题
        let training_score = 0; // 知识点得分
        let training_total_score = 0; // 知识点题目总分
        let training_persent = 0; // 知识点掌握度%
        // 入门数据统计
        rumen.right_persent = 0;  //正确率
        rumen.right1_count = 0;  //全对
        rumen.right2_count = 0;  //小错
        rumen.right3_count = 0;  //半错
        rumen.right4_count = 0;  //大错
        rumen.right5_count = 0;  //全错
        rumen.isTaught = true; // 是否已授
        if (rumen.topics && rumen.topics.length > 0) {
            rumen.topics.forEach(id => {
                let answer = answers.find(item => item.topic_id === id);
                if (answer) {
                    switch (answer.result) {
                        case 1:
                            rumen.right1_count += 1;
                            break;
                        case 2:
                            rumen.right2_count += 1;
                            break;
                        case 3:
                            rumen.right3_count += 1;
                            break;
                        case 4:
                            rumen.right4_count += 1;
                            break;
                        case 5:
                            rumen.right5_count += 1;
                            break;
                    }
                } else {
                    rumen.isTaught = false;
                }
            });
            rumen.right_persent = parseInt(rumen.right1_count * 100 / rumen.topics.length);
        }


        // 遍历所有知识点
        data.points.forEach(point => {
            point.isTaught = false;
            point.training_score = 0;   // 知识点得分
            point.training_total_score = 0;  // 知识点题目总分
            point.training_persent = 0;     // 知识点掌握度%
            // point.topicTypes.forEach(tt => {
            point.examples.forEach(example => {
                if (answers.findIndex(ans => ans.topic_id === example.topic_id) !== -1) {
                    point.isTaught = true;
                    // tt.isTaught = true;
                    taught_example_count += 1
                }
            });
            point.training1_score = 0;
            point.training1_total_score = 0;
            let answers_filter = answers.filter(item => item.result > 0 && point.training1.includes(item.topic_id));
            answers_filter.forEach(answer => {
                point.training1_total_score += 10;
                point.training1_score += answer.score;
                point.training_score += answer.score;
                point.training_total_score += 10;
            });
            point.training1_persent = point.training1_total_score === 0 ? 0 : parseInt(point.training1_score * 100 / point.training1_total_score);

            point.training2_score = 0;
            point.training2_total_score = 0;
            let answers2_filter = answers.filter(item => item.result > 0 && point.training2.includes(item.topic_id));
            answers2_filter.forEach(answer => {
                point.training2_total_score += 10;
                point.training2_score += answer.score;
                point.training_score += answer.score;
                point.training_total_score += 10;
            });
            point.training2_persent = point.training2_total_score === 0 ? 0 : parseInt(point.training2_score * 100 / point.training2_total_score);


            if (point.isTaught) {
                taught_topicType_count += 1;
            }
            // });
            if (point.isTaught) {
                taught_point_count += 1;
            }
            point.training_persent = point.training_total_score === 0 ? 0 : parseInt(point.training_score * 100 / point.training_total_score);
            training_score += point.training_score;
            training_total_score += point.training_total_score;
        });
        data.taught_point_count = taught_point_count;
        data.taught_topicType_count = taught_topicType_count;
        data.taught_example_count = taught_example_count;
        data.training_total_score = training_total_score;
        data.training_score = training_score;
        data.training_persent = data.training_total_score === 0 ? 0 : parseInt(data.training_score * 100 / data.training_total_score);

        this.setState({})
    };


    // 发送题目
    // test_type:测试类型：1教案例题，2闯关训练，3入门测试（非本教案的内容） 4出门测试，5课后练习(作业)
    // example_id: 题型（母题）id
    handleSendTopic = (point, topic, test_type, example_id) => {
        let {data} = this.state;
        // console.log(data)
        // 将当前知识点设置为已授

        socket.emit("send topic", {
            room_id: data.student_id,
            data: {
                teaching_doc_id: data.id,
                student_id: data.student_id,
                point_main_id: point ? point.point_main_id : 0,
                test_type: test_type,
                example_id: 0,
                ...topic
            }
        });
        if (point) {
            point.isTaught = true;
        }
        this.setState({});
    };

    // 发送闯关训练
    handleSendTraining = (point, topicType, id) => {
        let {data} = this.state;
        let trainingids = topicType[`training${id}`];
        let topics = topicType.exercises.filter(item => trainingids.includes(item.topic_id));
        console.log(topics);
        socket.emit("send training", {
            room_id: data.student_id,
            data: {
                teaching_doc_id: data.id,
                student_id: data.student_id,
                point_main_id: point.point_main_id,
                example_id: topicType.example_id,
                times: id,
                topics: topics
            }
        });
        point.isTaught = true;
        this.setState({});
    };

    handleSendRuMen = () => {
        let {rumen, data} = this.state;
        if (rumen.topics && rumen.topics.length > 0) {
            socket.emit("send rumen", {
                room_id: data.student_id,
                data: {
                    teaching_doc_id: data.id,
                    student_id: data.student_id,
                    topics: rumen.topics
                }
            });
        }
        rumen.isTaught = true;
        this.setState({});
    };

    // 提交批改
    handlePiGai = (id, ta, close = false) => {
        console.log(ta);
        if (!ta.result_new) {
            message.error("请选择批改情况！");
            return;
        }
        putPiGai({
            id: id,
            result: ta.result_new,
            error_reason: JSON.stringify(ta.error_reason),
        })
            .then(res => {
                if (res.code === 0) {
                    this.fetchAnswers();
                    message.success("保存成功");
                    if (close) {
                        this.setState({showAnswerModal: false})
                    }
                }
            })
    };

    // 批改情况发生改变
    handlePiGaiResultChange = (ta, result) => {
        ta.result_new = result;
        this.setState({})
    };

    // 错误情况发生改变
    handlePiGaiErrorReasonChange = (ta, values) => {
        ta.error_reason = values;
        this.setState({})
    };

    // 加载掌握度
    renderZhangWoDuTraining = (topic_ids) => {
        let {answers} = this.state;
        let answers_filter = answers.filter(item => item.result > 0 && topic_ids.includes(item.topic_id));
        console.log("topic_ids", topic_ids);
        let filterids = [...new Set(answers_filter.map(item => item.topic_id))];
        if (topic_ids.length !== filterids.length) {
            return <Text type="secondary">掌握度：-</Text>

        }
        // 计算掌握度
        let total_score = 0;
        let score = 0;
        for (let i in answers_filter) {
            let answer = answers_filter[i];
            total_score += 10;
            score += answer.score;
        }
        return <Text type="secondary">掌握度：{parseInt(score * 100 / total_score)}%</Text>

    };

    // 加载入门发送按钮
    renderRumenSendButton = () => {
        let {answers, rumen} = this.state;
        if (rumen.isTaught) {
            return (<Button type="primary" disabled>已发送</Button>)
        } else {
            return (<Button type="primary" onClick={() => this.handleSendRuMen()}>发送</Button>)
        }
    };

    // 加载发送按钮
    renderSendButton = (point, topic, test_type, example_id) => {
        let {answers} = this.state;
        if (answers.some(item => item.result === 0)) {
            return (<Button type="primary" disabled>等待批改</Button>)
        }
        let answer_models = answers.filter(item => item.topic_id === topic.topic_id);
        if (answer_models.length === 0) {
            return (<Button type="primary"
                            onClick={() => this.handleSendTopic(point, topic, test_type, example_id)}>发送</Button>)
        }
        let answer_model = answer_models[answer_models.length - 1];
        if (answer_model.result > 0) {
            return (<Button type="primary" onClick={() => this.handleSendTopic(point, topic, test_type, example_id)}>再次发送</Button>)
        }
        if (answer_model.is_submit === 0) {
            return (<Button type="primary" disabled>等待提交</Button>)
        }
        if (answer_model.result === 0) {
            return (<Button type="primary" disabled>等待批改</Button>)
        }
    };

    // 加载批改界面
    renderPiGai = () => {
        let {answers} = this.state;
        if (answers.length === 0) {
            return <Empty/>
        }
        let pigailist = answers.filter(item => item.result === 0);
        if (pigailist.length === 0) {
            return <Empty/>
        }

        let ta = pigailist[0];
        let path = ta.answer_img_path;
        if (path) {
            path = path.replace('public', url);
        }

        return (
            <div>
                {/*<div>*/}
                {/*剩余批改题目：{pigailist.length}*/}
                {/*</div>*/}
                {path ? <img className={styles.topicWrapper} src={`${path}`} style={{maxWidth: "100%"}}/>
                    : <p className={styles.topicWrapper}
                         dangerouslySetInnerHTML={{__html: ta.origin.topic_content}}/>
                }

                {ta.origin && <div>
                    <Text style={{fontSize: 18}} strong><AimOutlined/> 答案</Text>
                    {ta.origin.answers && ta.origin.answers.map(answer => (
                        <div dangerouslySetInnerHTML={{__html: answer.topic_answer_content}}/>))}
                    <Text style={{fontSize: 18}} strong><AimOutlined/> 解析</Text>
                    {ta.origin.analysis && <div dangerouslySetInnerHTML={{__html: ta.origin.analysis}}/>}
                </div>}
                <Space direction="vertical" size={12}>
                    <div>
                        批改情况：
                        <Radio.Group
                            value={ta.result_new}
                            onChange={(e) => this.handlePiGaiResultChange(ta, e.target.value)}
                        >
                            {[
                                {id: 1, name: "全对"},
                                {id: 2, name: "小错"},
                                {id: 3, name: "半错"},
                                {id: 4, name: "大错"},
                                {id: 5, name: "全错"},
                            ].map(item => <Radio value={item.id}>{item.name}</Radio>)}

                        </Radio.Group>
                    </div>
                    <div>
                        错误原因：
                        <Checkbox.Group
                            value={ta.error_reason}
                            onChange={(values) => this.handlePiGaiErrorReasonChange(ta, values)}
                        >
                            {["概念不清", "计算错误", "粗心大意", "方法错误", "审题错误", "用错公式", "不理解", "其他原因"].map((item, index) =>
                                <Checkbox value={index + 1}>{item}</Checkbox>)}

                        </Checkbox.Group>
                    </div>
                    <div style={{textAlign: "center", marginTop: 30}}>
                        {/*{this.state.currentAnswerIndex > 0 && <Button*/}
                        {/*onClick={() => this.setState({currentAnswerIndex: this.state.currentAnswerIndex - 1})}>上一题</Button>}*/}
                        {/*{this.state.currentAnswerIndex < this.state.answers.length - 1 &&*/}
                        {/*<Button*/}
                        {/*onClick={() => this.setState({currentAnswerIndex: this.state.currentAnswerIndex + 1})}>下一题</Button>}*/}
                        <Space>
                            <Button onClick={() => this.handlePiGai(ta.id, ta, true)}>确定并返回</Button>

                            {pigailist.length > 1 && <Button onClick={() => this.handlePiGai(ta.id, ta)}>批改下一题</Button>}
                        </Space>

                    </div>
                </Space>

            </div>

        )
    };


    render() {
        let {list, filter, data, rumen, chumen, answers} = this.state;
        if (!data) return null;
        let points = data.points;
        let chumen_index = 0;
        return (
            <PageContent title={"精准教学"} extra={<Space size={24}>
                <Button onClick={() => this.setState({showReportModal: true})}>报告&作业</Button>
                <Button>作业批改T</Button>
                <Button onClick={() => this.setState({showAnswerModal: true})}>实时批改</Button>
            </Space>}>
                <Card bordered={false} size={"small"}>
                    <Row>
                        <Col flex={"auto"}>
                            <Space size={24}>
                                <Text style={{fontSize: 24}}>
                                    <SnippetsOutlined/> 授课进度
                                </Text>
                                <Text>
                                    知识点：
                                    <Text type="secondary">
                                        {data.taught_point_count}/{data.point_count}
                                    </Text>
                                </Text>
                                <Text>
                                    例题：
                                    <Text type="secondary">
                                        {data.taught_example_count}/{data.example_count}
                                    </Text>
                                </Text>
                                <Text>
                                    本节课知识点掌握度：
                                    <Text type="secondary">
                                        <Progress percent={data.training_persent} style={{width: 100}}/>
                                    </Text>
                                </Text>
                            </Space>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </Card>
                <Collapse bordered={false} defaultActiveKey={[0]}
                          style={{marginBottom: 12, backgroundColor: "#ffffff"}}>
                    <Panel header={
                        <Row>
                            <Col flex={"auto"}>
                                <Space size={16}>
                                    <Text style={{fontSize: 18}}>
                                        <ClockCircleOutlined/> 入门测试
                                    </Text>
                                    <Text type="secondary">{rumen.isTaught ? "已授" : "未授"}</Text>
                                    <Text>
                                        正确率：
                                        <Text type="secondary">
                                            {rumen.right_persent}%
                                        </Text>
                                    </Text>
                                    <Text>
                                        全对：
                                        <Text type="secondary">
                                            {rumen.right1_count}
                                        </Text>
                                    </Text>
                                    <Text>
                                        小错：
                                        <Text type="secondary">
                                            {rumen.right2_count}
                                        </Text>
                                    </Text>
                                    <Text>
                                        半错：
                                        <Text type="secondary">
                                            {rumen.right3_count}
                                        </Text>
                                    </Text>
                                    <Text>
                                        大错：
                                        <Text type="secondary">
                                            {rumen.right4_count}
                                        </Text>
                                    </Text>
                                    <Text>
                                        全错：
                                        <Text type="secondary">
                                            {rumen.right5_count}
                                        </Text>
                                    </Text>
                                </Space>
                            </Col>
                            <Col>
                                {/*<Space size={24}>*/}
                                {/*{this.renderRumenSendButton()}*/}
                                {/*</Space>*/}
                            </Col>
                        </Row>

                    } key={0}>
                        {rumen.topics.map((se, ex_index) => {
                            // index += 1;
                            return (<>
                            <Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                <Col flex={"auto"}>
                                    <h3>
                                        <ContainerOutlined/> 入门测-{ex_index + 1}
                                    </h3>
                                    <p className={styles.topicWrapper}
                                       dangerouslySetInnerHTML={{__html: se.topic_content}}/>
                                    难度系数：{(se.difficult_level / 100.0).toFixed(2)}
                                </Col>
                                <Col>
                                    {this.renderSendButton(null, se, 3, 0)}
                                    {/*<Button type="primary" onClick={() => this.handleSendTopic(se)}>发送</Button>*/}
                                </Col>
                            </Row>
                            <Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>
                            </>);
                        })}
                    </Panel>
                </Collapse>
                {/*<Card bordered={false} size={"small"}>*/}

                {/*</Card>*/}

                <Collapse bordered={false} defaultActiveKey={[0]}
                          style={{marginBottom: 12, backgroundColor: "#ffffff"}}>
                    {points.map((item, index) => <Panel header={
                        <Row>
                            <Col flex={"auto"}>
                                <Space>
                                    <Text strong>
                                        {item.point_main_name}
                                    </Text>
                                    <Text>{item.isTaught ? "已授" : "未授"}</Text>
                                    <Text>
                                        掌握度：
                                        <Text type="secondary">
                                            {item.training_persent}%
                                        </Text>
                                    </Text>

                                </Space>
                            </Col>
                            <Col>
                                <Space>
                                    <Text>例题: {item.example_count} 题</Text>
                                    <Text>练习题: {item.exercises_count} 题</Text>
                                </Space>
                            </Col>
                        </Row>
                    } key={index}>
                        <Row style={{flexWrap: "nowrap"}} gutter={12}>
                            <Col flex={"auto"}>
                                {item.examples.map((se, ex_index) => {
                                    // index += 1;
                                    let answer = answers.find(item => item.topic_id === se.topic_id);
                                    console.log(answer);
                                    let path = "";
                                    if (answer) {
                                        // console.log(answer)
                                        path = answer.answer_img_path;
                                        if (path) {
                                            path = path.replace('public', url);
                                            console.log(path)
                                        }
                                    }

                                    return (<>
                                    <Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                        <Col flex={"auto"}>
                                            <h3>
                                                <ContainerOutlined/> 例题-{ex_index + 1}
                                            </h3>
                                            {answer && path
                                                ? <img className={styles.topicWrapper} src={`${path}`}
                                                       style={{maxWidth: "100%"}}/>
                                                : <p className={styles.topicWrapper}
                                                     dangerouslySetInnerHTML={{__html: se.topic_content}}/>
                                            }

                                            难度系数：{(se.difficult_level / 100.0).toFixed(2)}
                                        </Col>
                                        <Col>
                                            {this.renderSendButton(item, se, 1, item.example_id)}
                                            {/*<Button type="primary" onClick={() => this.handleSendTopic(se)}>发送</Button>*/}
                                        </Col>
                                    </Row>
                                    <Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>
                                    </>);
                                })}
                                {(item.training1.length > 0 || item.training2.length > 0) &&
                                <Collapse bordered={false}
                                          className={styles.topicWrapper}
                                          style={{marginBottom: 12, backgroundColor: "#ffffff"}}>
                                    {item.training1.length > 0 &&
                                    <Panel header={<h3>
                                        闯关训练1 <Text type="secondary">掌握度：{item.training1_persent}%</Text></h3>}
                                           style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                        {item.exercises.filter(item1 => item.training1.includes(item1.topic_id)).map((se, ex_index) => {
                                            // index += 1;
                                            return (<>
                                            <Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                                <Col flex={"auto"}>
                                                    <h3>
                                                        <ContainerOutlined/> 练习题-{ex_index + 1}
                                                    </h3>
                                                    <p className={styles.topicWrapper}
                                                       dangerouslySetInnerHTML={{__html: se.topic_content}}/>
                                                    难度系数：{(se.difficult_level / 100.0).toFixed(2)}
                                                </Col>
                                                <Col>
                                                    {this.renderSendButton(item, se, 2, item.example_id)}
                                                    {/*<Button type="primary" onClick={() => this.handleSendTopic(se)}>发送</Button>*/}
                                                </Col>
                                            </Row>
                                            <Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>
                                            </>);
                                        })}
                                    </Panel>}
                                    {item.training2.length > 0 &&
                                    <Panel header={<h3>
                                        闯关训练2 <Text type="secondary">掌握度：{item.training2_persent}%</Text></h3>}
                                           style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                        {item.exercises.filter(item1 => item.training2.includes(item1.topic_id)).map((se, ex_index) => {
                                            // index += 1;
                                            return (<>
                                            <Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                                <Col flex={"auto"}>
                                                    <h3>
                                                        <ContainerOutlined/> 练习题-{ex_index + 1}
                                                    </h3>
                                                    <p className={styles.topicWrapper}
                                                       dangerouslySetInnerHTML={{__html: se.topic_content}}/>
                                                    难度系数：{(se.difficult_level / 100.0).toFixed(2)}
                                                </Col>
                                                <Col>
                                                    {this.renderSendButton(item, se, 2, item.example_id)}
                                                    {/*<Button type="primary" onClick={() => this.handleSendTopic(se)}>发送</Button>*/}
                                                </Col>
                                            </Row>
                                            <Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>
                                            </>);
                                        })}
                                    </Panel>}
                                </Collapse>
                                }

                            </Col>
                            {item.point_contents.length > 0 && <Col flex={"250px"}>
                                <div className={styles["point_content_content_wrapper"]}>
                                    {item.point_contents.filter(pc => pc.isShow).map((pc, index) => <>
                                    <h3>
                                        <WifiOutlined/> 知识点-{index + 1}
                                    </h3>
                                    <p dangerouslySetInnerHTML={{__html: pc.point_content_content}}
                                       className={styles["point_content_content"]}/>

                                    </>)}
                                </div>
                            </Col>
                            }

                        </Row>
                    </Panel>)}

                </Collapse>

                <Collapse bordered={false} defaultActiveKey={[0]}
                          style={{marginBottom: 12, backgroundColor: "#ffffff"}}>
                    <Panel header={
                        <Row>
                            <Col flex={"auto"}>
                                <Space size={16}>
                                    <Text style={{fontSize: 18}}>
                                        <ClockCircleOutlined/> 出门测试
                                    </Text>
                                    <Text>已授T</Text>
                                    <Text>
                                        正确率：
                                        <Text type="secondary">
                                            50%T
                                        </Text>
                                    </Text>

                                </Space>
                            </Col>
                            <Col>
                                {/*<Space size={24}>*/}
                                {/*<Button type="primary">发送</Button>*/}
                                {/*</Space>*/}
                            </Col>
                        </Row>
                    } key={0}>
                        {points.map(point => <>
                        {point.topicTypes.filter(item => !item.isRemove).map((tt, tt_index) => <div>
                            {/*<h3>*/}
                            {/*题型-{tt_index + 1}*/}
                            {/*</h3>*/}
                            {tt.chumen && tt.chumen.map((se, ex_index) => {
                                // index += 1;
                                chumen_index += 1
                                return (<>
                                <Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>
                                    <Col flex={"auto"}>
                                        <h3>
                                            <ContainerOutlined/> 出门测-{chumen_index}
                                        </h3>
                                        <p className={styles.topicWrapper}
                                           dangerouslySetInnerHTML={{__html: se.topic_content}}/>
                                        难度系数：{(se.difficult_level / 100.0).toFixed(2)}
                                    </Col>
                                    <Col>
                                        {this.renderSendButton(null, se, 4, tt.example_id)}
                                        {/*<Button type="primary" onClick={() => this.handleSendTopic(se)}>发送</Button>*/}
                                    </Col>
                                </Row>
                                <Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>
                                </>);
                            })}
                        </div>)}
                        </>)}
                        {/*{chumen.filter(item=> item.topics.length > 0).map((tt, tt_index)=>)}*/}

                    </Panel>
                </Collapse>

                <Card bordered={false} size={"small"}>
                    <Row>
                        <Col flex={"auto"}>
                            <Space size={16}>
                                <Text style={{fontSize: 18}}>
                                    <ClockCircleOutlined/> 巩固提升
                                </Text>
                                <Text>已授T</Text>
                                <Text>
                                    正确率：
                                    <Text type="secondary">
                                        50%T
                                    </Text>
                                </Text>

                            </Space>
                        </Col>
                        <Col>
                            <Space size={24}>
                                <Button type="primary">发送</Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
                {/*<Modal*/}
                {/*title="实时批改"*/}
                {/*visible={this.state.showAnswerModal}*/}
                {/*width={"100%"}*/}
                {/*style={{top: 0}}*/}
                {/*// onOk={this.handleOk}*/}
                {/*onCancel={() => this.setState({showAnswerModal: false})}*/}
                {/*footer={null}*/}
                {/*>*/}
                {/*{points.map((item, index) =>*/}
                {/*<>*/}
                {/*{item.selectExamples.map((se) => {*/}
                {/*index += 1;*/}
                {/*let ansindexs = this.state.answers.filter(ans => ans.topic_id === se.topic_id);*/}
                {/*let answer = ansindexs.length > 0 ? ansindexs[ansindexs.length - 1] : null;*/}
                {/*let path = "";*/}
                {/*if (answer) {*/}
                {/*path = answer.answer_img_path.replace('public', url);*/}
                {/*if (!se.result) se.result = answer.result;*/}
                {/*if (!se.error_reason) se.error_reason = answer.error_reason;*/}
                {/*}*/}
                {/*return (<>*/}
                {/*<Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>*/}
                {/*<Col flex={"auto"}>*/}
                {/*<h3>*/}
                {/*<ContainerOutlined/> 例题-{index}*/}
                {/*</h3>*/}
                {/*{path*/}
                {/*? <p className={styles.topicWrapper}><img src={`${path}`}*/}
                {/*style={{maxWidth: "100%"}}/></p>*/}
                {/*: <p className={styles.topicWrapper}*/}
                {/*dangerouslySetInnerHTML={{__html: se.topic_content}}/>}*/}
                {/*<div>*/}
                {/*<Text style={{fontSize: 18}} strong><AimOutlined/> 答案</Text>*/}
                {/*{se.answers && se.answers.map(answer => (*/}
                {/*<div dangerouslySetInnerHTML={{__html: answer.topic_answer_content}}/>))}*/}
                {/*<Text style={{fontSize: 18}} strong><AimOutlined/> 解析</Text>*/}
                {/*{se.analysis && <div>*/}
                {/*{se.analysis}*/}
                {/*</div>}*/}
                {/*</div>*/}
                {/*</Col>*/}
                {/*<Col flex={"300px"}>*/}
                {/*<div>*/}
                {/*批改情况：*/}
                {/*<Radio.Group*/}
                {/*value={se.result}*/}
                {/*onChange={(e) => this.handlePiGaiResultChange(se, e.target.value)}*/}
                {/*>*/}
                {/*{[*/}
                {/*{id: 1, name: "全对"},*/}
                {/*{id: 2, name: "小错"},*/}
                {/*{id: 3, name: "半错"},*/}
                {/*{id: 4, name: "大错"},*/}
                {/*{id: 5, name: "全错"},*/}
                {/*].map(item => <Radio value={item.id}>{item.name}</Radio>)}*/}

                {/*</Radio.Group>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*错误原因：*/}
                {/*<Checkbox.Group*/}
                {/*value={se.error_reason}*/}
                {/*onChange={(values) => this.handlePiGaiErrorReasonChange(se, values)}*/}
                {/*>*/}
                {/*{["概念不清", "计算错误", "粗心大意", "方法错误", "审题错误", "用错公式", "不理解", "其他原因"].map((item, index) =>*/}
                {/*<Checkbox value={index + 1}>{item}</Checkbox>)}*/}

                {/*</Checkbox.Group>*/}
                {/*</div>*/}
                {/*<Button type="primary" onClick={() => this.handleSendTopic(se)}>发送</Button>*/}
                {/*<Button type="primary" onClick={() => this.handlePiGai(answer ? answer.id : 0, se)}>保存批改</Button>*/}
                {/*</Col>*/}
                {/*</Row>*/}
                {/*<Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>*/}
                {/*{se.exercises && se.exercises.length > 0 && se.exercises.map((ex, exindex) => {*/}
                {/*let ansindexs = this.state.answers.filter(ans => ans.topic_id === ex.topic_id);*/}
                {/*let answer = ansindexs.length > 0 ? ansindexs[ansindexs.length - 1] : null;*/}
                {/*let path = "";*/}
                {/*if (answer) {*/}
                {/*path = answer.answer_img_path.replace('public', url);*/}
                {/*if (!ex.result) ex.result = answer.result;*/}
                {/*if (!ex.error_reason) ex.error_reason = answer.error_reason;*/}
                {/*// ex.result = answer.result;*/}
                {/*// ex.error_reason = answer.error_reason;*/}
                {/*}*/}
                {/*return (<>*/}
                {/*<Row style={{flexWrap: "nowrap"}} align="middle" gutter={12}>*/}
                {/*<Col flex={"auto"}>*/}
                {/*<h3>*/}
                {/*<AimOutlined/> 例题-{index} 闯关训练-{exindex + 1}*/}
                {/*</h3>*/}
                {/*{path*/}
                {/*? <p className={styles.topicWrapper}><img src={`${path}`}*/}
                {/*style={{maxWidth: "100%"}}/></p>*/}
                {/*: <p className={styles.topicWrapper}*/}
                {/*dangerouslySetInnerHTML={{__html: ex.topic_content}}/>}*/}

                {/*/!*<p className={styles.topicWrapper}*!/*/}
                {/*/!*dangerouslySetInnerHTML={{__html: ex.topic_content}}/>*!/*/}
                {/*<div>*/}
                {/*<Text style={{fontSize: 18}} strong><AimOutlined/> 答案</Text>*/}
                {/*{ex.answers && ex.answers.map(answer => (*/}
                {/*<div*/}
                {/*dangerouslySetInnerHTML={{__html: answer.topic_answer_content}}/>))}*/}
                {/*<Text style={{fontSize: 18}} strong><AimOutlined/> 解析</Text>*/}
                {/*{ex.analysis && <div>*/}
                {/*{ex.analysis}*/}
                {/*</div>}*/}
                {/*</div>*/}
                {/*</Col>*/}
                {/*<Col flex={"300px"}>*/}
                {/*<div>*/}
                {/*批改情况：*/}
                {/*<Radio.Group*/}
                {/*value={ex.result}*/}
                {/*onChange={(e) => this.handlePiGaiResultChange(ex, e.target.value)}*/}
                {/*>*/}
                {/*{[*/}
                {/*{id: 1, name: "全对"},*/}
                {/*{id: 2, name: "小错"},*/}
                {/*{id: 3, name: "半错"},*/}
                {/*{id: 4, name: "大错"},*/}
                {/*{id: 5, name: "全错"},*/}
                {/*].map(item => <Radio value={item.id}>{item.name}</Radio>)}*/}

                {/*</Radio.Group>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*错误原因：*/}
                {/*<Checkbox.Group*/}
                {/*value={ex.error_reason}*/}
                {/*onChange={(values) => this.handlePiGaiErrorReasonChange(ex, values)}*/}
                {/*>*/}
                {/*{["概念不清", "计算错误", "粗心大意", "方法错误", "审题错误", "用错公式", "不理解", "其他原因"].map((item, index) =>*/}
                {/*<Checkbox value={index + 1}>{item}</Checkbox>)}*/}

                {/*</Checkbox.Group>*/}
                {/*</div>*/}
                {/*<Button type="primary" onClick={() => this.handleSendTopic(ex)}>发送</Button>*/}
                {/*<Button type="primary"*/}
                {/*onClick={() => this.handlePiGai(answer ? answer.id : 0, ex)}>保存批改</Button>*/}
                {/*</Col>*/}
                {/*</Row>*/}
                {/*<Divider dashed style={{borderColor: "#cccccc", margin: "12px 0"}}/>*/}
                {/*</>)*/}
                {/*})}*/}
                {/*</>);*/}
                {/*})}*/}
                {/*</>*/}
                {/*)}*/}


                {/*</Modal>*/}

                {/*0428版本，通过上一题下一题切换*/}
                <Modal
                    title="实时批改"
                    visible={this.state.showAnswerModal}
                    width={780}
                    style={{top: 20}}
                    // onOk={this.handleOk}
                    onCancel={() => this.setState({showAnswerModal: false})}
                    footer={null}
                >
                    {this.renderPiGai()}
                    {/*{this.state.answers.length === 0*/}
                    {/*? <Empty/>*/}
                    {/*: <div>*/}
                    {/*<div>*/}
                    {/*{[1].map(() => {*/}
                    {/*let ta = this.state.answers[this.state.currentAnswerIndex];*/}
                    {/*let path = ta.answer_img_path;*/}
                    {/*path = path.replace('public', "http://localhost:8001");*/}


                    {/*return (<div>*/}
                    {/*<img src={`${path}`} style={{maxWidth: "100%"}}/>*/}
                    {/*{ta.origin && <div>*/}
                    {/*<Text style={{fontSize: 18}} strong><AimOutlined/> 答案</Text>*/}
                    {/*{ta.origin.answers && ta.origin.answers.map(answer => (<div>*/}
                    {/*{answer.topic_answer_content}*/}
                    {/*</div>))}*/}
                    {/*<Text style={{fontSize: 18}} strong><AimOutlined/> 解析</Text>*/}
                    {/*{ta.origin.analysis && <div>*/}
                    {/*{ta.origin.analysis}*/}
                    {/*</div>}*/}
                    {/*</div>}*/}
                    {/*<Space direction="vertical" size={12}>*/}
                    {/*<div>*/}
                    {/*批改情况：*/}
                    {/*<Radio.Group*/}
                    {/*value={ta.result}*/}
                    {/*onChange={(e) => this.handlePiGaiResultChange(ta, e.target.value)}*/}
                    {/*>*/}
                    {/*{[*/}
                    {/*{id: 1, name: "全对"},*/}
                    {/*{id: 2, name: "小错"},*/}
                    {/*{id: 3, name: "半错"},*/}
                    {/*{id: 4, name: "大错"},*/}
                    {/*{id: 5, name: "全错"},*/}
                    {/*].map(item => <Radio value={item.id}>{item.name}</Radio>)}*/}

                    {/*</Radio.Group>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*错误原因：*/}
                    {/*<Checkbox.Group*/}
                    {/*value={ta.error_reason}*/}
                    {/*onChange={(values) => this.handlePiGaiErrorReasonChange(ta, values)}*/}
                    {/*>*/}
                    {/*{["概念不清", "计算错误", "粗心大意", "方法错误", "审题错误", "用错公式", "不理解", "其他原因"].map((item, index) =>*/}
                    {/*<Checkbox value={index + 1}>{item}</Checkbox>)}*/}

                    {/*</Checkbox.Group>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*{this.state.currentAnswerIndex > 0 && <Button*/}
                    {/*onClick={() => this.setState({currentAnswerIndex: this.state.currentAnswerIndex - 1})}>上一题</Button>}*/}
                    {/*{this.state.currentAnswerIndex < this.state.answers.length - 1 &&*/}
                    {/*<Button*/}
                    {/*onClick={() => this.setState({currentAnswerIndex: this.state.currentAnswerIndex + 1})}>下一题</Button>}*/}

                    {/*<Button onClick={() => this.handlePiGai(ta)}>保存批改</Button>*/}

                    {/*</div>*/}
                    {/*</Space>*/}

                    {/*</div>)*/}
                    {/*})}*/}


                    {/*</div>*/}
                    {/*</div>*/}
                    {/*}*/}
                </Modal>
                <Drawer
                    title="作业与报告"
                    placement={"top"}
                    closable={true}
                    onClose={() => this.setState({showReportModal: false})}
                    visible={this.state.showReportModal}
                    // key={placement}
                    height={"100%"}
                    destroyOnClose
                >
                    <Report data={this.state.data} answers={answers}/>
                </Drawer>
            </PageContent>
        )
    }
}


JingZhunDetail.propTypes = {
    id: PropTypes.number.isRequired
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(JingZhunDetail));


