import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    Form,
    Steps,
    Row,
    Col,
    Input,
    Select,
    Radio,
    Typography,
    Tree,
    Space,
    Empty,
    Affix,
    Collapse,
    Modal,
    Tabs
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDoc} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined, DragOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import PointSelect from './point-select';
import MyStudentsSelect from './my-students-select';
import {getMytudents} from "../../lib/api_students";
import GradeName from '../../components/grade-name';
import {getExample, getExampleChild} from "../../lib/api_example";
import TopicOpt from '../../components/teaching_doc/topic-opt2';
import TopicOpt3 from '../../components/teaching_doc/topic-opt3';
import moment from 'moment';
import {getPoint} from "../../lib/api_point";
import styles from './step2.module.css';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import Setp3OrderPoints from './step3-2-order-points';
import Setp3OrderTraining from './step3-order-training';
import Setp3TrainingModal from './step3-2-training-modal';

const {CheckableTag} = Tag;
const {Text} = Typography;
const {DirectoryTree} = Tree;
const {Panel} = Collapse;


class Step2 extends Component {

    formRef = React.createRef();


    state = {
        step: 0,
        data: {
            name: "", // 教案名称
            teacher_id: 0, // 老师id
            grade: 0, // 年级
            subject: 0, // 学科
            semester: 1, // 学期
            student_id: 0, // 学员id
            points: [], // 知识点列表,
            topics: [], // 例题列表

        },
        myStudentList: [],
        studentList: [],
        subjectList: [],
        currentStudent: null,
        point: [],
        currentMuti: null,
        childTopics: [],
        filter: {
            nandu: 0,
            tixing: 0,
            laiyuan: 0,
            year: 0
        },
        example: null,

        topicType: null // 当前闯关训练设置的题型
    };

    componentWillMount() {
        let {datame} = this.props;
        console.log(datame);
        this.setState({
            data: datame
        })
    }

    componentDidMount() {
        // // 数据格式化
        // let {data} = this.props;
        // let point = JSON.parse(data.point);
        // this.setState({
        //     point: point
        // }, ()=>{
        //     this.fetchData()
        // });
        // console.log(point);
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        // 读取所有母题(一次性获取所有数据)
        // console.log(this.props.data);
        let {data} = this.state;
        let pointids = data.points.map(item => item.point_main_id).join(',');
        console.log(pointids);
        // getExample(pointids)
        //     .then(res => {
        //         console.log(res);
        //         if (res.code === 0) {
        //             // 将母题数据增加到知识点内
        //             res.data.forEach(item => {
        //                 // 找到知识点
        //                 let point = data.points.find(po => po.point_main_id === item.point_main_uid);
        //                 console.log(point);
        //                 let examples = point.examples || [];
        //                 if (examples.findIndex(ex => ex.example_id === item.example_id) === -1) {
        //                     examples.push(item)
        //                 }
        //                 point.examples = examples;
        //             });
        //             console.log(data.points);
        //             this.resetTree();
        //         }
        //     })
        this.resetTree();
    };

    // // 获取题目数据
    // fetchTopicData = (id) => {
    //     getExampleChild(id)
    //         .then(res => {
    //             console.log(res)
    //             if (res.code === 0) {
    //                 res.data.forEach(item => item.point_main_uid = this.state.currentMuti.point_main_uid);
    //                 // 初始化例题和练习状态
    //
    //                 this.setState({
    //                     childTopics: res.data
    //                 })
    //             }
    //         })
    // };

    // 重置树结构
    resetTree = () => {
        let treeData = [];
        let {points} = this.state.data;
        let index = 0;
        points.forEach(point => {
            if (point.topicType_count > 0) {
                treeData.push({
                    title: point.point_main_name,
                    key: point.point_main_id,
                    type: "point",
                    data: point,
                    children: point.topicTypes.filter(se => !se.isRemove).map((se) => {
                        // console.log(se)
                        index += 1;
                        return {
                            title: `题型-${index}`,
                            key: se.topic_id,
                            pid: point.point_main_id,
                            type: "topic",
                            data: se,
                            isLeaf: true
                        }
                    })
                })
            }
        });
        console.log(treeData);
        this.setState({
            treeData
        })
    };

    onSelect = (keys, event) => {
        console.log('Trigger Select', keys, event);
        // if (keys.length > 0) {
        //     // console.log(event.node.data);
        //     this.setState({
        //         currentMuti: event.node.data
        //     }, () => {
        //         // if (!event.node.data.isRemove) {
        //         //     this.fetchTopicData(keys[0]);
        //         // }
        //     });
        // }
        // this.
        // this.props.onChange && this.props.onChange(keys[0], event.node.title);
    };

    // 获得知识点
    getPoint = (data) => {
        let {points} = this.state.data;

        let point = points.find(item => item.point_main_id === data.point_main_uid);
        if (!point.selectExamples) {
            point.selectExamples = [];
        }
        if (!point.exercises) {
            point.exercises = [];
        }
        return point;
    };


    handleSave = () => {
        this.props.onSave && this.props.onSave(this.state.data);
    };

    onDragEnter = info => {
        console.log(info);
        // expandedKeys 需要受控时设置
        // this.setState({
        //   expandedKeys: info.expandedKeys,
        // });
    };

    findPoint = (point_main_id) => {
        let {points} = this.state.data;
        for (let i in points) {
            if (points[i].point_main_id === point_main_id) {
                return {
                    data: points[i],
                    index: i
                }
            }
        }
    };

    findselectExamples = (selectExamples, topic_id) => {
        for (let i in selectExamples) {
            if (selectExamples[i].topic_id === topic_id) {
                return {
                    data: selectExamples[i],
                    index: i
                }
            }
        }
    }

    onDrop = info => {
        console.log(info);
        let {points} = this.state.data;
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        if (info.dragNode.type === "point") {
            // 拖动知识点
            let drag = this.findPoint(info.dragNode.data.point_main_id);
            if (info.node.type === "point") {
                console.log(111);
                // 目标是知识点，移动到知识点后面
                let drop = this.findPoint(info.node.data.point_main_id);
                points.splice(drag.index, 1);
                points.splice(drop.index, 0, drag.data);
                this.resetTree();
            } else if (info.node.type === "topic") {
                // 目标是题目，找到知识点
                let drop = this.findPoint(info.node.data.point_main_uid);
                points.splice(drag.index, 1);
                points.splice(drop.index, 0, drag.data);
                this.resetTree();
            }
        }
        if (info.dragNode.type === "topic") {
            // 拖动题目，只能内部拖动
            let droppoint = this.findPoint(info.dragNode.data.point_main_uid);
            let dragNode = this.findselectExamples(droppoint.data.selectExamples, info.dragNode.data.topic_id);

            if (dragNode) {
                if (info.node.type === "point") {
                    // console.log(droppoint, info);
                    if (droppoint.data.point_main_id === info.node.data.point_main_id) {
                        let dropNode = this.findselectExamples(droppoint.data.selectExamples, info.dragNode.data.topic_id);
                        if (dropNode) {
                            droppoint.data.selectExamples.splice(dropNode.index, 1);
                            droppoint.data.selectExamples.splice(0, 0, info.dragNode.data);
                            this.resetTree();
                        }
                    }
                } else if (info.node.type === "topic") {
                    if (info.dragNode.data.point_main_id === info.node.data.point_main_id) {
                        if (info.dropToGap) {
                            droppoint.data.selectExamples.splice(dragNode.index, 1);
                            let dropNode = this.findselectExamples(droppoint.data.selectExamples, info.node.data.topic_id);
                            if (dropNode) {
                                if (info.dropPosition === -1) {
                                    console.log("info.dropPosition", dropNode);
                                    droppoint.data.selectExamples.splice(dropNode.index, 0, info.dragNode.data);
                                    this.resetTree();
                                } else {
                                    droppoint.data.selectExamples.splice(dropNode.index + 1, 0, info.dragNode.data);
                                    this.resetTree();
                                }
                            }

                        } else {
                            droppoint.data.selectExamples.splice(dragNode.index, 1);
                            let dropNode = this.findselectExamples(droppoint.data.selectExamples, info.node.data.topic_id);
                            if (dropNode) {
                                droppoint.data.selectExamples.splice(dropNode.index + 1, 0, info.dragNode.data);
                                this.resetTree();
                            }
                        }
                    }
                }
            }

        }
    };

    handlePrev = () => {
        this.props.onPrev && this.props.onPrev(this.state.data);
    };

    handleNext = () => {
        this.props.onNext && this.props.onNext(this.state.data);
    };


    render() {
        const {step, data, studentList, subjectList, currentStudent, treeData, filter, childTopics, currentMuti} = this.state;


        // 当前知识点
        // let currentPoint = currentMuti ? this.getPoint(currentMuti) : null;


        let points = data.points.filter(point => point.selectExamples && point.selectExamples.length > 0);


        // if(this.state.example){
        //     let point = this.findPoint(this.state.example.point_main_uid);
        //     console.log(point)
        //
        // }


        return (
            <div>
                <Card bordered={false} size={"small"}>
                    <Row align="middle">
                        <Col flex={"auto"}>
                            <Space size={24}>
                                <Text>
                                    教案名称：<Text type="secondary">{data.name}</Text>
                                </Text>
                                <Text>
                                    姓名：<Text type="secondary">{data.student.realname}</Text>
                                </Text>
                                <Text>
                                    年级：<Text type="secondary"><GradeName id={data.student.grade}/></Text>
                                </Text>
                                <Text>
                                    涉及知识点：<Text type="secondary">{data.point_count}</Text>
                                </Text>
                                <Text>
                                    例题：<Text type="secondary">{data.example_count}</Text>
                                </Text>
                                <Text>
                                    练习题：<Text type="secondary">{data.exercises_count}</Text>
                                </Text>
                            </Space>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={this.handleSave}>
                                    保存草稿
                                </Button>
                                <Button type="primary" onClick={this.handlePrev}>
                                    上一步
                                </Button>
                                <Button type="primary" onClick={this.handleNext}>
                                    下一步
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
                <Row style={{flexWrap: "nowrap"}} gutter={[16, 16]}>
                    <Col flex="240px">
                        <Affix offsetTop={10}>
                            <Card bordered={false} title={"鼠标拖动排序"} size={"small"} bodyStyle={{padding: 0}}>
                                <Setp3OrderPoints items={data.points} onChange={() => this.setState({})}/>

                            </Card>
                            {/*<DirectoryTree*/}
                            {/*className="draggable-tree"*/}

                            {/*multiple={false}*/}
                            {/*draggable*/}
                            {/*blockNode*/}
                            {/*// height={300}*/}
                            {/*defaultExpandAll*/}
                            {/*defaultExpandParent*/}
                            {/*onSelect={this.onSelect}*/}
                            {/*// onExpand={onExpand}*/}
                            {/*treeData={treeData}*/}
                            {/*onDragEnter={this.onDragEnter}*/}
                            {/*onDrop={this.onDrop}*/}
                            {/*/>*/}
                        </Affix>
                    </Col>
                    <Col flex="auto">
                        <Collapse>
                            {data.points.map(point => <Panel
                                header={point.point_main_name} extra={<Text>
                                <Space size={24}>
                                    <Text>例题: {point.example_count} 题</Text>
                                    <Text>练习题: {point.exercises_count} 题</Text>
                                </Space>
                            </Text>}>
                                <Card bordered size={"small"} extra={<Space>
                                    <a type="link" onClick={() => this.setState({topicType: point})}><PlusOutlined/>
                                        闯关训练</a>
                                </Space>}>
                                    {point.examples.map(se =>
                                        <TopicOpt
                                            data={se}
                                            onExercise={(data) => {
                                                this.setState({example: data})
                                            }}
                                        />)}
                                </Card>
                            </Panel>)}
                        </Collapse>


                    </Col>
                </Row>

                <Setp3TrainingModal topicType={this.state.topicType} onCancel={() => this.setState({topicType: null})}/>

            </div>
        )
    }
}

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
    return result;
};
const grid = 8;

// 设置样式
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 12,
    // margin: `0 0 ${grid}px 0`,

    // 拖拽的时候背景变化
    background: isDragging ? "lightgreen" : "#ffffff",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = () => ({
    background: 'black',
    padding: grid,
    width: 250
});

class ReactBeautifulDnd extends Component {
    state = {
        topicType: null,
        // exercises: []
    };

    componentWillMount() {
        // if (!this.props.example.exercises) this.props.example.exercises = [];
        this.setState({
            topicType: this.props.topicType,
            // exercises: this.props.exercises || []
        })
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.example.exercises,
            result.source.index,
            result.destination.index
        );

        this.state.example.exercises = items;
        this.setState({});
    };

    addExercises = (data) => {
        let {exercises} = this.state.example;
        let model = exercises.find(item => item.topic_id === data.topic_id);
        if (!model) {
            exercises.push(data);
            data.pid = this.state.example.topic_id;
        }
        this.state.example.exercises = exercises;
        this.setState({})
    };

    removeExercises = (data) => {
        let {exercises} = this.state.example;
        let index = exercises.findIndex(item => item.topic_id === data.topic_id);
        if (index !== -1) {
            const [removed] = exercises.splice(index, 1);
            removed.pid = 0;
            this.state.example.exercises = exercises;
            this.setState({})
        }
    };

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Row gutter={24} style={{flexWrap: "nowrap"}}>

                    <Col flex={"200px"}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    //provided.droppableProps应用的相同元素.
                                    {...provided.droppableProps}
                                    // 为了使 droppable 能够正常工作必须 绑定到最高可能的DOM节点中provided.innerRef.
                                    ref={provided.innerRef}
                                    // style={getListStyle(snapshot)}
                                >
                                    {this.state.example.exercises.map((item, index) => (
                                        <Draggable key={item.topic_id + ""} draggableId={item.topic_id + ""}
                                                   index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <DragOutlined/> 闯关训练-{index + 1}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                    <Col flex={"auto"}>
                        {this.state.example.exercises.map(se =>
                            <TopicOpt3
                                data={se}
                                pid={this.state.example.topic_id}
                                onExercise={(data, pid) => {
                                    this.addExercises(data)
                                }}
                                onUnExercise={(data, pid) => {
                                    this.removeExercises(data)
                                }}
                            />)}
                        {this.state.exercises.filter(item => !item.pid).map(se =>
                            <TopicOpt3
                                data={se}
                                pid={this.state.example.topic_id}
                                onExercise={(data, pid) => {
                                    this.addExercises(data)
                                }}
                                onUnExercise={(data, pid) => {
                                    this.removeExercises(data)
                                }}
                            />)}
                    </Col>
                </Row>
            </DragDropContext>
        );
    }
}


Step2.propTypes = {
    // classes_id: PropTypes.number
    datame: PropTypes.object,
    currentStudent: PropTypes.object,
    onSubmit: PropTypes.func,
    onPrev: PropTypes.func,
    onSave: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2));


