import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getStudents} from "../../lib/api_students";
import {
    Table,
    Divider,
    Card,
    Button
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import UserEditModal from './students-edit-modal';
import UserResetPasswordModal from './teachers-resetpassword-modal';
import config from '../../config';
import TeachersSelectModal from './wx-student-select-modal';
import StudentAddModal from './students-add-modal';

const {Column} = Table;


class Students extends Component {
    state = {
        users: [],
        roles: []
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getStudents()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        users: result.data
                    })
                }
            })
    };

    render() {
        let {users, roles} = this.state;
        return (
            <PageContent title={"学员管理"} extra={
                <Button type={"primary"} onClick={()=>this.setState({showAddModal: true})}>添加学员</Button>

            }>
                <Card bordered={false}>
                    <Table dataSource={users} rowKey={"id"} pagination={false} size={"small"}>
                        <Column
                            title="序号"
                            dataIndex="id"
                            key="id"
                            width={80}
                        />

                        <Column
                            title="姓名"
                            dataIndex="realname"
                            key="realname"
                        />
                        <Column
                            title="账号"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="性别"
                            dataIndex="sex"
                            key="sex"
                            render={(sex)=> sex ===  0? "男" :"女" }
                        />
                        <Column
                            title="手机号"
                            dataIndex="mobile"
                            key="mobile"
                        />
                        <Column
                            title="微信绑定"
                            key="微信绑定"
                            render={(record)=>{
                                if(record.wx_user){
                                    return record.wx_user.s_realname
                                }else{
                                    return "-"
                                }
                            }}
                        />

                        <Column
                            title="操作"
                            key="action"
                            width={250}
                            render={(text, record) => (
                                <div>
                                    <a onClick={() => {
                                        this.setState({editRecord: record})
                                    }}>编辑</a>
                                    <Divider type="vertical"/>
                                    <a onClick={() => {
                                        this.setState({resetPassword: record})
                                    }}>重置密码</a>
                                    <Divider type="vertical"/>
                                    <a onClick={() => this.setState({wxRecord: record})}>微信绑定</a>
                                </div>
                            )}
                        />
                    </Table>

                </Card>
                <UserEditModal
                    data={this.state.editRecord}
                    roles={roles}
                    onOk={() => {
                        this.setState({editRecord: null});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({editRecord: null})
                    }}
                />
                <UserResetPasswordModal
                    data={this.state.resetPassword}
                    onOk={() => {
                        this.setState({resetPassword: null});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({resetPassword: null})
                    }}
                />
                {this.state.wxRecord &&
                <TeachersSelectModal
                    data={this.state.wxRecord}
                    onCancel={() => this.setState({wxRecord: null})}
                    onOk={() => {
                        this.setState({wxRecord: null});
                        this.fetchData()
                    }}
                />}
                <StudentAddModal
                    visible={this.state.showAddModal}
                    onCancel={() => this.setState({showAddModal: false})}
                    onOk={() => {
                        this.setState({showAddModal: false});
                        this.fetchData()
                    }}
                />
            </PageContent>
        )
    }
}


Students.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Students));


