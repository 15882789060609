import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getBindStudentsInfo} from "../../lib/api_teachers";
import {
    Modal,
    Input,
    Form,
    Transfer
} from 'antd';
import md5 from 'md5';

const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}


class UserBindStudentModal extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        students: [],
        bindlist: []
    };

    componentDidMount() {
        this.fetchData(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.fetchData(nextProps)

    }

    fetchData = (props) => {
        console.log(props);
        if (props.data && props.data.id) {
            getBindStudentsInfo(props.data.id)
                .then(res => {
                    if (res.code === 0) {
                        console.log(res)
                        this.setState({
                            students: res.data.students,
                            bindlist: res.data.bindlist
                        })
                    }
                })
        }
    };

    // onSubmit = (values) => {
    //     // values.id = this.props.data.id;
    //
    //     resetPassword(this.props.data.id, md5(values.password))
    //         .then(result => {
    //             console.log(result);
    //             // 刷新数据
    //             this.handleOK();
    //         })
    // };


    handleFinish = () => {
        this.formRef.current
            .validateFields()
            .then(values => {
                console.log(values)
                this.onSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    searchUser = () => {
        const targetKeys = [];
        const mockData = [];
        for (let i = 0; i < 20; i++) {
            const data = {
                key: i.toString(),
                title: `content${i + 1}`,
                description: `description of content${i + 1}`,
                chosen: Math.random() * 2 > 1,
            };
            if (data.chosen) {
                targetKeys.push(data.key);
            }
            mockData.push(data);
        }
        this.setState({mockData, targetKeys});
    };


    handleChange = (nextTargetKeys, direction, moveKeys) => {
        this.setState({targetKeys: nextTargetKeys});

        console.log('targetKeys: ', nextTargetKeys);
        console.log('direction: ', direction);
        console.log('moveKeys: ', moveKeys);
    };

    handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        this.setState({selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]});

        console.log('sourceSelectedKeys: ', sourceSelectedKeys);
        console.log('targetSelectedKeys: ', targetSelectedKeys);
    };

    handleScroll = (direction, e) => {
        console.log('direction:', direction);
        console.log('target:', e.target);
    };

    handleDisable = disabled => {
        this.setState({disabled});
    };
    handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    render() {
        const {data, roles} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };
        const {selectedKeys, disabled} = this.state;

        const {students, bindlist} = this.state;
        let dataSource = students.map(item => ({
            key: item.id,
            title: item.name
        }));

        let targetKeys = bindlist.map(item => item.id);

        return (
            <Modal
                title="绑定学生"
                visible={!!data}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                {data &&
                <Transfer
                    dataSource={dataSource}
                    showSearch
                    titles={['全部学生', '已绑定']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={this.handleChange}
                    onSelectChange={this.handleSelectChange}
                    onScroll={this.handleScroll}
                    onSearch={this.handleSearch}
                    render={item => item.title}
                    disabled={disabled}
                />
                }
            </Modal>
        )
    }
}


UserBindStudentModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserBindStudentModal));
