import PropTypes from 'prop-types';
import React, {Component} from "react";
import ReactDOM from "react-dom";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import Setp3OrderTopicTypes from './step3-order-topicTypes';
import Setp3OrderExamples from './step3-2-order-examples';

// fake data generator
const getItems = count =>
    Array.from({length: count}, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = list; // Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8,
    // margin: `0 0 ${grid}px 0`,
    marginBottom: 4,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#ffffff",

    border: "solid 1px #ccc",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "#ffffff",
    padding: 4,
    // width: 250
    // border:"solid 1px #ccc"
});

class Setp3OrderPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: props.items
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );
        console.log(items)

        this.setState({
            items
        });
        this.props.onChange && this.props.onChange(items);
    }

    handleChange = () => {
        this.props.onChange && this.props.onChange(this.state.items);
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {this.state.items.map((item, index) => {
                                // if (item.topicType_count === 0) return null;
                                return (
                                    <Draggable key={item.point_main_id + ""} draggableId={item.point_main_id + ""}
                                               index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div
                                                    {...provided.dragHandleProps}
                                                >
                                                    {item.point_main_name}
                                                </div>
                                                <Setp3OrderExamples items={item.examples} onChange={this.handleChange} />
                                                {/*<Setp3OrderTopicTypes items={item.topicTypes}*/}
                                                                      {/*onChange={this.handleChange}/>*/}
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

Setp3OrderPoints.propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func
};

export default Setp3OrderPoints