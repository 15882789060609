import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {resetPassword} from "../../lib/api_teachers";
import {
    Modal,
    Input,
    Form,
} from 'antd';
import md5 from 'md5';

class UserResetPasswordModal extends Component {
    formRef = React.createRef();

    state = {
        loading: false
    };

    onSubmit = (values) => {
        // values.id = this.props.data.id;

        resetPassword(this.props.data.id, md5(values.password))
            .then(result => {
                console.log(result);
                // 刷新数据
                this.handleOK();
            })
    };


    handleFinish = () => {
        this.formRef.current
            .validateFields()
            .then(values => {
                console.log(values)
                this.onSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {data, roles} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        return (
            <Modal
                title="重置密码"
                visible={!!data}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                {data &&
                <Form {...formItemLayout}
                      ref={this.formRef}
                      initialValues={{
                          password: "123456"
                      }}
                >
                    <Form.Item
                        label={"新密码"}
                        name="password"
                        rules={[{required: true, message: '请输入新密码'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
                }
            </Modal>
        )
    }
}


UserResetPasswordModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserResetPasswordModal));
