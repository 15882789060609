import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getTeachers} from "../../lib/api_teachers";
import {
    Table,
    Divider,
    Card
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import UserEditModal from './teachers-edit-modal';
import UserResetPasswordModal from './teachers-resetpassword-modal';
import config from '../../config';

const {Column} = Table;


class Students extends Component {
    state = {
        users: [],
        roles: []
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getTeachers()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        users: result.data.users,
                        roles: result.data.roles
                    })
                }
            })
    };

    render() {
        let {users, roles} = this.state;
        return (
            <PageContent title={"用户管理"}>
                <Card bordered={false}>
                    <Table dataSource={users} rowKey={"id"} pagination={false} size={"small"}>
                        <Column
                            title="序号"
                            dataIndex="id"
                            key="id"
                            width={80}
                        />

                        <Column
                            title="教师姓名"
                            dataIndex="realname"
                            key="realname"
                        />
                        <Column
                            title="账号"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="角色"
                            dataIndex="roles"
                            key="roles"
                            render={(roless, record) => {
                                if (roless) {
                                    console.log("roless", roless);
                                    let user_roles = JSON.parse(roless);
                                    if (user_roles) {
                                        let role_names = user_roles.map(role => {
                                            let ro = roles.find(rol => rol.id === role);
                                            if (ro) {
                                                return ro.name
                                            }
                                        });
                                        return role_names.join(',')
                                    }
                                }
                                return null
                            }}
                        />
                        <Column
                            title="科目"
                            dataIndex="subjects"
                            key="subjects"
                            render={(roless, record) => {
                                if (roless) {
                                    console.log("roless", roless);
                                    let user_roles = JSON.parse(roless);
                                    if (user_roles) {
                                        let role_names = user_roles.map(role => {
                                            let ro = config.subjects.find(rol => rol.id === role);
                                            if (ro) {
                                                return ro.name
                                            }
                                        });
                                        return role_names.join(',')
                                    }
                                }
                                return null
                            }}
                        />
                        <Column
                            title="操作"
                            key="action"
                            width={250}
                            render={(text, record) => (
                                <div>
                                    <a onClick={() => {
                                        this.setState({editRecord: record})
                                    }}>编辑</a>
                                    <Divider type="vertical"/>
                                    <a onClick={() => {
                                        this.setState({resetPassword: record})
                                    }}>重置密码</a>
                                    <Divider type="vertical"/>
                                    <Link to={`/users/${record.id}`}>
                                        学生绑定
                                    </Link>
                                </div>
                            )}
                        />
                    </Table>

                </Card>
                <UserEditModal
                    data={this.state.editRecord}
                    roles={roles}
                    onOk={() => {
                        this.setState({editRecord: null});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({editRecord: null})
                    }}
                />
                <UserResetPasswordModal
                    data={this.state.resetPassword}
                    onOk={() => {
                        this.setState({resetPassword: null});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({resetPassword: null})
                    }}
                />
            </PageContent>
        )
    }
}


Students.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Students));


