import PropTypes from 'prop-types';
import React from 'react';
import config from '../config';

const GradeName = ({id}) => {
    let model = config.grade2.find(item => item.id === id);
    if (model) {
        return model.name;
    } else {
        return null;
    }
};

GradeName.propTypes={
    id: PropTypes.number.isRequired
};

export default GradeName