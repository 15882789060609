import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Select
} from 'antd';
import {getSchools} from "../../lib/api_school";

const {Option} = Select;



class SchoolSelect extends Component {
    state = {
        list: [],
        filter: {}
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getSchools()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data,
                    })
                }
            })
    };

    render() {
        let {list} = this.state;
        let {onChange} = this.props;

        return (
            <Select
                showSearch
                allowClear
                style={{ width: 200 }}
                placeholder="选择学校"
                optionFilterProp="children"
                onChange={onChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {list.map(item=><Option value={item.id}>{item.school_name}</Option>)}
            </Select>
        )
    }
}


SchoolSelect.propTypes = {
    onChange: PropTypes.func
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolSelect));


