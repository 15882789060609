import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Button,
    Space,
    Select,
    Table,
    Input,
    Descriptions,
    Modal,
    Form,
    DatePicker,
    message,
    Tag,
    Row,
    Col,
    Tabs,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import GradeName from '../../components/grade-name';
import SchoolSelect from '../mystudents/school-select';
import {getStudentInfo, getStudentReport} from '../../lib/api_students';
import RelationTypeName from '../../components/relation_type_name';
import {postContact} from "../../lib/api_contact";
import moment from 'moment';
import printJS from 'print-js'
import styles from './report-print.module.css';

const {Column} = Table;
const {CheckableTag} = Tag;


const reanderCell = (value1, value2) => {
    if (!value1 || value1 === 0) return "-";
    if (!value2 || value2 === 0) return value1;
    let text = "";
    // if (value1 > value2) {
    //     text = "↑"
    // }
    if (value1 < value2) {
        text = "↓"
    }
    return <span>{value1} <span style={{float: "right"}}>{text}</span></span>
}

class ReportPrint extends Component {
    state = {
        report_remark: ""
    };

    componentDidMount() {
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handlePrint = () => {
        printJS({
            printable: "print",
            type: "html",
            targetStyles: "*",
        })
        // printJS('print', 'html');
    }


    render() {
        let {visible, onCancel, chapter, school_name, class_name, student_name} = this.props;
        return (
            <Modal
                visible={visible}
                footer={null}
                onCancel={onCancel}
                width={1000}
                destroyOnClose={true}
            >
                <Button onClick={this.handlePrint}>打印</Button>
                <div id="print">
                    <h3 style={{textAlign: "center"}}>作业情况检测结果报告单</h3>
                    <Row>
                        <Col span={12}>
                            就读学校：<b>{school_name}</b><br/>
                            就读班级：<b>{class_name}</b><br/>
                            学生姓名：<b>{student_name}</b><br/>
                        </Col>
                        <Col span={12}>
                            检测类型：<b>日常作业</b><br/>
                            检测内容：<b>{chapter.name}</b><br/>
                            检测时间：<b>{moment().format("YYYY/MM/DD")}</b><br/>
                        </Col>
                    </Row>
                    <Table
                        className={styles["table"]}
                        dataSource={chapter.points}
                        columns={[{
                            title: "知识点名称",
                            dataIndex: "point_main_name",
                            key: "知识点名称",
                            width: 140,
                            ellipsis: true,
                        }, {
                            title: "正确题数",
                            children: [{
                                title: "学生数据",
                                dataIndex: "right_count",
                                key: "学生数据",
                                className: styles["column-title"],
                                render: (right_count, record) => {
                                    return reanderCell(
                                        right_count,
                                        parseInt(record.grade_right_count / record.grade_student_count)
                                    );
                                }
                            }, {
                                title: "参考值",
                                key: "参考值",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_student_count === 0) return "-";
                                    return parseInt(record.grade_right_count / record.grade_student_count);
                                }
                            }]
                        }, {
                            title: "正确率",
                            children: [{
                                title: "学生数据",
                                key: "学生数据",
                                className: styles["column-title"],
                                render: (record) => {
                                    return reanderCell(
                                        parseInt(record.right_count * 100 / (record.right_count + record.wrong_count)),
                                        parseInt(record.grade_right_count * 100 / (record.grade_right_count + record.grade_wrong_count))
                                    );
                                },
                            }, {
                                title: "参考值",
                                key: "参考值",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_right_count + record.grade_wrong_count === 0) return "-";
                                    return parseInt(record.grade_right_count * 100 / (record.grade_right_count + record.grade_wrong_count));
                                },
                            }]
                        }, {
                            title: "困难题正确率",
                            children: [{
                                title: "学生数据",
                                key: "学生数据1",
                                className: styles["column-title"],
                                render: (record) => {
                                    return reanderCell(
                                        parseInt(record.level1_right_count * 100 / (record.level1_right_count + record.level1_wrong_count)),
                                        parseInt(record.grade_level1_right_count * 100 / (record.grade_level1_right_count + record.grade_level1_wrong_count))
                                    );
                                },
                            }, {
                                title: "参考值",
                                key: "参考值1",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_level1_right_count + record.grade_level1_wrong_count === 0) return "-";
                                    return parseInt(record.grade_level1_right_count * 100 / (record.grade_level1_right_count + record.grade_level1_wrong_count));
                                },
                            }]
                        }, {
                            title: "较难题正确率",
                            children: [{
                                title: "学生数据",
                                key: "学生数据2",
                                className: styles["column-title"],
                                render: (record) => {
                                    return reanderCell(
                                        parseInt(record.level2_right_count * 100 / (record.level2_right_count + record.level2_wrong_count)),
                                        parseInt(record.grade_level2_right_count * 100 / (record.grade_level2_right_count + record.grade_level2_wrong_count))
                                    );
                                },
                            }, {
                                title: "参考值",
                                key: "参考值2",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_level2_right_count + record.grade_level2_wrong_count === 0) return "-";
                                    return parseInt(record.grade_level2_right_count * 100 / (record.grade_level2_right_count + record.grade_level2_wrong_count));
                                },
                            }]
                        }, {
                            title: "中档题正确率",
                            children: [{
                                title: "学生数据",
                                key: "学生数据3",
                                className: styles["column-title"],
                                render: (record) => {
                                    return reanderCell(
                                        parseInt(record.level3_right_count * 100 / (record.level3_right_count + record.level3_wrong_count)),
                                        parseInt(record.grade_level3_right_count * 100 / (record.grade_level3_right_count + record.grade_level3_wrong_count))
                                    );
                                },
                            }, {
                                title: <span>参考值</span>,
                                key: "参考值3",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_level3_right_count + record.grade_level3_wrong_count === 0) return "-";
                                    return parseInt(record.grade_level3_right_count * 100 / (record.grade_level3_right_count + record.grade_level3_wrong_count));
                                },
                            }]
                        }, {
                            title: "较易题正确率",
                            children: [{
                                title: "学生数据",
                                key: "学生数据4",
                                className: styles["column-title"],
                                render: (record) => {
                                    return reanderCell(
                                        parseInt(record.level4_right_count * 100 / (record.level4_right_count + record.level4_wrong_count)),
                                        parseInt(record.grade_level4_right_count * 100 / (record.grade_level4_right_count + record.grade_level4_wrong_count))
                                    );
                                },
                            }, {
                                title: "参考值",
                                key: "参考值4",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_level4_right_count + record.grade_level4_wrong_count === 0) return "-";
                                    return parseInt(record.grade_level4_right_count * 100 / (record.grade_level4_right_count + record.grade_level4_wrong_count));
                                },
                            }]
                        }, {
                            title: "容易题正确率",
                            children: [{
                                title: "学生数据",
                                key: "学生数据5",
                                className: styles["column-title"],
                                render: (record) => {
                                    return reanderCell(
                                        parseInt(record.level5_right_count * 100 / (record.level5_right_count + record.level5_wrong_count)),
                                        parseInt(record.grade_level5_right_count * 100 / (record.grade_level5_right_count + record.grade_level5_wrong_count))
                                    );
                                }
                            }, {
                                title: "参考值",
                                key: "参考值5",
                                className: styles["column-title"],
                                render: (record) => {
                                    if (record.grade_level5_right_count + record.grade_level5_wrong_count === 0) return "-";
                                    return parseInt(record.grade_level5_right_count * 100 / (record.grade_level5_right_count + record.grade_level5_wrong_count));
                                }
                            }]
                        }]}
                        rowKey={"point_main_id"}
                        pagination={false}
                        size={"small"}>
                    </Table>
                    <div>
                        {this.state.report_remark}
                    </div>
                </div>
                <Input.TextArea rows={3} onChange={(e)=> this.setState({report_remark: e.target.value})}/>
            </Modal>
        )
    }
}


ReportPrint.propTypes = {
    id: PropTypes.number,
    visible: PropTypes.bool,
    chapter: PropTypes.object,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportPrint));


