import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Tree, Row, Col, Table, Checkbox} from 'antd';


import {getPoint} from "../../lib/api_point";
import PointTree from './point-tree';
import {getPointMain,getPointsStat} from "../../lib/api_point";
import {SolutionOutlined} from '@ant-design/icons';


const {DirectoryTree} = Tree;
const {Column} = Table;


class PointSelect extends Component {
    state = {
        currentValue: "",
        pointTitle: "从左侧选择目录",
        points: [], // 查询结果
        selectedPoints: [], // 已选知识点
    };

    componentDidMount() {
        this.fetchData(this.props.n, this.props.x)
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentWillReceiveProps(nextProps) {
        // console.log("recive nextProps")

        if (this.state.currentValue !== nextProps.value && nextProps.value) {
            // this.fetchData(nextProps.n, nextProps.x);
            // console.log("need reload", nextProps.value);
            this.setState({
                selectedPoints: JSON.parse(nextProps.value)
            })
        }
    }

    fetchData = (n, x) => {
        // if(this.props.student_id){
        //     getPointsStat(this.props.student_id,this.state.selectedPoints.map(item=> item.point_main_id))
        //         .then(res=>{
        //             if(res.code === 0){
        //                 console.log(res)
        //             }
        //         })
        //
        // }
    };


    handlePointChange = (id, title) => {
        getPointMain(id)
            .then(res => {
                console.log(res);
                if (res.code === 0) {
                    this.setState({
                        pointTitle: title,
                        points: res.data
                    })
                }
            })
    };

    // 单选
    handlePointSelectChange = (record, selected, selectedRows, nativeEvent) => {
        // selectedPoints
        let {selectedPoints} = this.state;
        console.log(record, selected, selectedRows);
        if (selected) {
            let model = selectedPoints.find(item => item.point_main_id === record.point_main_id);
            if (!model) {
                selectedPoints.push(record);
            }
        } else {
            selectedPoints = selectedPoints.filter(item => item.point_main_id !== record.point_main_id)
        }
        this.setState({
            selectedPoints: [...selectedPoints]
        }, this.handleChange)
    };
    // 全选
    handlePointSelectAllChange = (selected, selectedRows, changeRows) => {
        let {selectedPoints} = this.state;
        console.log(selected, selectedRows, changeRows)
        if (selected) {
            for (let i in changeRows) {
                let model = selectedPoints.find(item => item.point_main_id === changeRows[i].point_main_id);
                if (!model) {
                    selectedPoints.push(changeRows[i]);
                }
            }
        } else {
            for (let i in changeRows) {
                selectedPoints = selectedPoints.filter(item => item.point_main_id !== changeRows[i].point_main_id)
            }
        }
        this.setState({
            selectedPoints: [...selectedPoints]
        }, this.handleChange)
    };

    handlePointRemove = (point_main_id) => {
        console.log(point_main_id);
        let {selectedPoints} = this.state;
        selectedPoints = selectedPoints.filter(item => item.point_main_id !== point_main_id);
        this.setState({
            selectedPoints: [...selectedPoints]
        }, this.handleChange)
    };

    // 重点
    handlePointImportantChange = (checked, point_main_id) => {
        let {selectedPoints} = this.state;
        let model = selectedPoints.find(item => item.point_main_id === point_main_id);
        if (model) {
            model.important = checked;
            this.setState({}, this.handleChange)
        }
    };

    // 难点
    handlePointDifficultChange = (checked, point_main_id) => {
        let {selectedPoints} = this.state;
        let model = selectedPoints.find(item => item.point_main_id === point_main_id);
        if (model) {
            model.difficult = checked;
            this.setState({}, this.handleChange)
        }
    };

    //触发更改
    handleChange = () => {
        let {selectedPoints, currentValue} = this.state;
        if (selectedPoints.length > 0) {
            // currentValue = JSON.stringify(selectedPoints.map(item => {
            //     return {
            //         point_main_id: item.point_main_id,
            //         point_main_name: item.point_main_name,
            //         important: !!item.important,
            //         difficult: !!item.difficult,
            //     }
            // }))
            currentValue = JSON.stringify(selectedPoints)
        }
        this.setState({
            currentValue: currentValue
        }, () => {
            this.props.onChange && this.props.onChange(currentValue)
        });
    };

    render() {
        const {pointTitle, points, selectedPoints} = this.state;
        const {n, x, prevPoints} = this.props;

        return (
            <div>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <PointTree n={n} x={x} onChange={this.handlePointChange}/>
                    </Col>
                    <Col span={9}>
                        <Table dataSource={points} size={"small"}
                               pagination={false}
                               scroll={{y: 220}}
                               rowKey={"point_main_id"}
                               bordered
                               title={() => pointTitle}
                               rowSelection={{
                                   type: 'checkbox',
                                   fixed: true,
                                   hideDefaultSelections: true,
                                   columnWidth: 40,
                                   columnTitle: null,
                                   selectedRowKeys: selectedPoints.map(item => item.point_main_id),
                                   onSelect: this.handlePointSelectChange,
                                   onSelectAll: this.handlePointSelectAllChange
                                   // getCheckboxProps: record => ({
                                   //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
                                   //     name: record.name,
                                   // }),
                               }}
                        >
                            <Column
                                title="知识点名称"
                                dataIndex="point_main_name"
                                key="point_main_name"
                                // width={100}
                                ellipsis={true}
                            />

                            <Column
                                title="错题数"
                                dataIndex="wrong_count"
                                key="wrong_count"
                                width={60}
                            />

                            <Column
                                title="错误率"
                                dataIndex="wrong_count"
                                key="wrong_persent"
                                width={60}
                                render={(wrong_count, record) => {
                                    let total = record.wrong_count + record.right_count;
                                    if(total > 0){
                                        return record.wrong_count * 100 / total + "%"
                                    }else{
                                        return "-";
                                    }
                                }}
                            />
                            <Column
                                title="状态"
                                // dataIndex="point_main_name"
                                // key="point_main_name"
                                width={60}
                                render={(record) => {
                                    let total = record.wrong_count + record.right_count;
                                    if(total > 0){
                                        return"已授";
                                    }else{
                                        return"未授";
                                    }
                                }}
                            />

                        </Table>

                    </Col>
                    <Col span={9}>
                        <Table dataSource={prevPoints} size={"small"} pagination={false}
                               title={() => "上次课知识点"}
                               bordered
                               rowKey="point_main_id"
                               rowSelection={{
                                   type: 'checkbox',
                                   fixed: true,
                                   hideDefaultSelections: true,
                                   columnWidth: 40,
                                   columnTitle: null,
                                   selectedRowKeys: selectedPoints.map(item => item.point_main_id),
                                   onSelect: this.handlePointSelectChange,
                                   onSelectAll: this.handlePointSelectAllChange
                                   // getCheckboxProps: record => ({
                                   //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
                                   //     name: record.name,
                                   // }),
                               }}
                        >
                            <Column
                                title="知识点名称"
                                dataIndex="point_main_name"
                                key="point_main_name"
                                // width={100}
                                ellipsis={true}
                            />

                            <Column
                                title="掌握度"
                                // dataIndex="point_main_name"
                                // key="point_main_name"
                                width={60}
                                render={() => {
                                    return "T";
                                }}
                            />



                        </Table>

                    </Col>
                    <Col span={24}>
                        <div className="ant-pro-table-toolbar">
                            <div className="ant-pro-table-toolbar-title"><SolutionOutlined/> 已选知识点</div>
                        </div>
                        <Table dataSource={selectedPoints} size={"small"}
                               pagination={false}
                            // scroll={{y: 220}}
                               rowKey={"point_main_id"}
                               bordered
                        >
                            <Column
                                title="知识点名称"
                                dataIndex="point_main_name"
                                key="point_main_name"
                                // width={100}
                                ellipsis={true}
                            />


                            <Column
                                title="总题目数"
                                key="总题目数"
                                render={(record)=>{
                                    return record.motif + record.subtopic
                                }}
                            />

                            <Column
                                title="学生错题数"
                                dataIndex="wrong_count"
                                key="wrong_count"
                            />
                            <Column
                                title="学生错误率"
                                dataIndex="wrong_count"
                                key="wrong_persent"
                                render={(wrong_count, record) => {
                                    let total = record.wrong_count + record.right_count;
                                    if(total > 0){
                                        return record.wrong_count * 100 / total + "%"
                                    }else{
                                        return "-";
                                    }
                                }}
                            />
                            <Column
                                title="已授次数"
                                dataIndex="learn_times"
                                key="learn_times"
                            />
                            <Column
                                title="重点"
                                dataIndex="important"
                                // key="point_main_name"
                                width={50}
                                render={(important, record) => (
                                    <Checkbox
                                        checked={important}
                                        onChange={(e) => this.handlePointImportantChange(e.target.checked, record.point_main_id)}/>
                                )}
                            />
                            <Column
                                title="难点"
                                dataIndex="difficult"
                                // key="point_main_name"
                                width={50}
                                render={(difficult, record) => (
                                    <Checkbox
                                        checked={difficult}
                                        onChange={(e) => this.handlePointDifficultChange(e.target.checked, record.point_main_id)}/>
                                )}
                            />
                            <Column
                                title="操作"
                                dataIndex="point_main_id"
                                // key="point_main_name"
                                width={50}
                                render={(point_main_id) => (
                                    <span>
                                        <a onClick={() => this.handlePointRemove(point_main_id)}>移除</a>
                                    </span>
                                )}
                            />

                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}


PointSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    n: PropTypes.number,
    x: PropTypes.number,
    student_id: PropTypes.number, // 学生id
    prevPoints: PropTypes.array, // 上节课知识点
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PointSelect));


