import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Button,
    Select,
    Table,
    Row,
    Col,
    Input,
    Form,
    Typography,
    Popconfirm,
    message,
    Tree
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getClassPaper, postClassPaper, deleteClassPaper} from "../../lib/api_class_paper";
import GradeName from "../../components/grade-name";
import SchoolSelect from "../../containers/mystudents/school-select";
import {
    getChapter,
    postChapter,
    putChapter,
    deleteChapter,
    postChapterBind,
    postChapterUnBind
} from "../../lib/api_chapter";
import {getPointsAll} from "../../lib/api_point";
import styles from './chapter.module.css';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'

const {Column} = Table;
const {Paragraph} = Typography;
const {TreeNode} = Tree;

const treeData = [
    {
        title: 'parent 1',
        key: '0-0',
        children: [
            {
                title: 'parent 1-0',
                key: '0-0-0',
                // disabled: true,
                checkable: false,
                children: [
                    {
                        title: 'leaf',
                        key: '0-0-0-0',
                        disableCheckbox: true,
                    },
                    {
                        title: 'leaf',
                        key: '0-0-0-1',
                    },
                ],
            },
            {
                title: 'parent 1-1',
                key: '0-0-1',
                children: [{title: <span style={{color: '#1890ff'}}>sss</span>, key: '0-0-1-0'}],
            },
        ],
    },
];

class ClassPaper extends Component {
    state = {
        chapter: [],
        chapter_filtered: [],
        grade: 1,
        subject: 1,
        points: [],
        point_main: [],
        chapter_point: [],
        loading: false,
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchChapter = () => {
        getChapter()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        chapter: result.data.list,
                        chapter_point: result.data.chapter_point
                    }, this.filterChapter)
                }
            });
    };

    fetchData = () => {

        this.fetchChapter();

        getPointsAll()
            .then((result) => {
                if (result.code === 0) {
                    this.setState(result.data, this.dealTreeData)
                }
            });
    };

    filterChapter = () => {
        let {grade, subject, chapter, chapter_point} = this.state;
        let chapter_filtered = [];
        if (grade > 0 && subject > 0) {
            chapter_filtered = chapter.filter(item => item.grade === grade && item.subject === subject);
            this.dealTreeData();
        }
        this.setState({
            chapter_filtered,
            // pid:0,
            // capter_id:0
        })
    };

    // 生成树
    dealTreeData = () => {
        let {points, point_main, grade, subject, capter_id, chapter_point} = this.state;
        let treeData = [], checkedKeys = [];
        if (grade > 0 && subject > 0) {
            // 找到所有选中项目
            // let chapter_points = chapter_point.filter(item=> item.chapter_id === capter_id);
            if (capter_id > 0) {
                checkedKeys = chapter_point.filter(item => item.chapter_id === capter_id).map(item => item.point_main_id);

                let rootPoints = [];
                if ([1, 2, 3, 4, 5, 6, 21, 22, 23, 24, 25, 26].includes(grade)) {
                    rootPoints = points.filter(item => item.point_pid === 0 && item.n === grade && item.x === subject);
                }
                if ([7, 8, 9, 15, 16, 17, 13].includes(grade)) {
                    rootPoints = points.filter(item => item.point_pid === 0 && item.type === 2 && item.x === subject);
                }
                if ([10, 11, 12, 18, 19, 20, 14].includes(grade)) {
                    rootPoints = points.filter(item => item.point_pid === 0 && item.type === 3 && item.x === subject);
                }

                for (let i in rootPoints) {
                    let point = rootPoints[i];
                    let rootNode = {
                        title: point.point_name,
                        key: point.point_id,
                        checkable: false,
                        children: []
                    };
                    let leafPoints = points.filter(item => item.point_pid === point.point_id);
                    for (let j in leafPoints) {
                        let leafPoint = leafPoints[j];
                        let leafNode = {
                            title: leafPoint.point_name,
                            key: leafPoint.point_id,
                            checkable: false,
                            children: []
                        };
                        let pm = point_main.filter(item => item.point_id === leafPoint.point_id);
                        for (let k in pm) {
                            leafNode.children.push({
                                title: pm[k].point_main_name,
                                key: pm[k].point_main_id,
                                isLeaf: true,
                            })
                        }
                        rootNode.children.push(leafNode);
                    }
                    treeData.push(rootNode);
                }
            }
        }
        this.setState({treeData, checkedKeys})
    };

    handleChapterAdd = (values, pid = 0) => {
        console.log(values);
        if (values.name) {
            this.setState({
                loading: true,
            });
            postChapter({
                pid: pid,
                name: values.name,
                grade: this.state.grade,
                subject: this.state.subject
            })
                .then(res => {
                    if (res.code === 0) {
                        this.fetchChapter();
                    }
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                })
        }
    };

    handleChapterEdit = (item, value) => {
        console.log(value);
        this.setState({
            loading: true,
        });
        putChapter(Object.assign(item, {name: value}))
            .then(res => {
                if (res.code === 0) {
                    this.fetchChapter();
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            })
    };

    handleChapterDelete = (id) => {
        // 判断是否有子节点
        let {chapter} = this.state
        if (chapter.some((item) => item.pid === id)) {
            message.error("请先删除子节点!")
            return
        }
        this.setState({
            loading: true,
        });
        deleteChapter(id)
            .then(res => {
                if (res.code === 0) {
                    this.fetchChapter();
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            })
    };

    handlePointMainCheck = (checkedKeys, e) => {
        if (this.state.capter_id > 0) {
            console.log(e.checked, e.node.key);
            if (e.checked) {
                postChapterBind({
                    chapter_id: this.state.capter_id,
                    point_main_id: e.node.key,
                    grade: this.state.grade,
                    subject_id: this.state.subject
                })
                    .then(res => {
                        if (res.code === 0) {
                            this.fetchChapter();
                        }
                    })
            } else {
                postChapterUnBind({
                    chapter_id: this.state.capter_id,
                    point_main_id: e.node.key
                })
                    .then(res => {
                        if (res.code === 0) {
                            this.fetchChapter();
                        }
                    })
            }
        }
    };

    render() {
        let {chapter, chapter_filtered, treeData,loading} = this.state;

        return (
            <PageContent title={"章节设置"}>
                <Card bordered={false} size={"small"}>
                    <Select
                        style={{width: 200}}
                        // allowClear
                        placeholder="选择年级"
                        optionFilterProp="children"
                        value={this.state.grade}
                        onChange={(value) => this.setState({grade: value, pid: 0, capter_id: 0}, this.filterChapter)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {config.grade2.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                    </Select>

                    <Select
                        style={{width: 200}}
                        // allowClear
                        placeholder="选择科目"
                        optionFilterProp="children"
                        value={this.state.subject}
                        onChange={(value) => this.setState({subject: value, pid: 0, capter_id: 0}, this.filterChapter)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {config.subjects.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                    </Select>

                </Card>
                <Card bordered={false} size={"small"}>
                    <Row gutter={12}>
                        <Col span={6}>
                            {/*一级目录*/}
                            <Card title={"章"} size={"small"}>
                                <div className={styles["item-wrapper"]}>
                                    {chapter_filtered.filter(item => item.pid === 0).map(item => (
                                        <div onClick={() => this.setState({
                                            pid: item.id,
                                            capter_id: 0
                                        }, this.dealTreeData)}
                                             className={item.id === this.state.pid ? styles["active"] : ""}
                                        >
                                            <Paragraph
                                                editable={{onChange: (value) => this.handleChapterEdit(item, value)}}>
                                                {item.name}
                                            </Paragraph>
                                            <span>
                                                <Popconfirm
                                                    title="确定要删除这条记录吗?"
                                                    onConfirm={() => this.handleChapterDelete(item.id)}
                                                >
                                                <DeleteOutlined/>
                                                </Popconfirm>
                                            </span>
                                        </div>))}
                                </div>
                                <Form onFinish={this.handleChapterAdd}>
                                    <Input.Group compact>
                                        <Form.Item noStyle name={"name"}>
                                            <Input style={{width: '70%'}}/>
                                        </Form.Item>
                                        <Button style={{width: '30%'}} htmlType={"submit"} loading={loading}>添加</Button>
                                    </Input.Group>
                                </Form>
                            </Card>
                        </Col>
                        <Col span={6}>
                            {/*二级目录*/}
                            <Card title={"节"} size={"small"}>
                                <div className={styles["item-wrapper"]}>
                                    {chapter.filter(item => item.pid === this.state.pid && item.pid !== 0).map(item => (
                                        <div onClick={() => this.setState({capter_id: item.id}, this.dealTreeData)}
                                             className={item.id === this.state.capter_id ? styles["active"] : ""}
                                        >
                                            <Paragraph
                                                editable={{onChange: (value) => this.handleChapterEdit(item, value)}}>
                                                {item.name}
                                            </Paragraph>
                                            <span>
                                                <Popconfirm
                                                    title="确定要删除这条记录吗?"
                                                    onConfirm={() => this.handleChapterDelete(item.id)}
                                                >
                                                <DeleteOutlined/>
                                                </Popconfirm>
                                            </span>
                                        </div>))}
                                </div>
                                {this.state.pid > 0 &&
                                <Form onFinish={(values) => this.handleChapterAdd(values, this.state.pid)}>
                                    <Input.Group compact>
                                        <Form.Item noStyle name={"name"}>
                                            <Input style={{width: '70%'}}/>
                                        </Form.Item>
                                        <Button style={{width: '30%'}} htmlType={"submit"} loading={loading}>添加</Button>
                                    </Input.Group>
                                </Form>}

                            </Card>
                        </Col>
                        <Col span={12}>
                            {/*知识点*/}
                            <Card title={"知识点"} size={"small"}>

                                <Tree.DirectoryTree
                                    checkable
                                    checkedKeys={this.state.checkedKeys}
                                    // defaultExpandedKeys={['0-0-0', '0-0-1']}
                                    // defaultSelectedKeys={['0-0-0', '0-0-1']}
                                    // defaultCheckedKeys={['0-0-0', '0-0-1']}
                                    // onSelect={onSelect}
                                    onCheck={this.handlePointMainCheck}
                                    treeData={treeData}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </PageContent>
        )
    }
}


ClassPaper.propTypes = {};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClassPaper));


