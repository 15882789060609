import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {Layout, PageHeader,Icon,Breadcrumb} from 'antd';
import styles from './page-content.module.css';
const routes = [
    {
        path: '/',
        breadcrumbName: "首页",
    },
    {
        path: 'first',
        breadcrumbName: 'Second-level Menu',
    },
    {
        path: 'second',
        breadcrumbName: 'Third-level Menu',
    },
];
function itemRender(route, params, routes, paths) {
    console.log(paths.join('/'));
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
}
const PageContent = ({children, wrapperClassName, title,subTitle,routes, extra, ...restProps}) => (
    <div className={wrapperClassName}>
        <PageHeader
            style={{
                background: "#fff",
                borderBottom: "1px solid #e8e8e8"
            }}
            title={title}
            breadcrumb={{itemRender, routes}}
            // breadcrumb={{
            //      routes,
            //      itemRender : itemRender
            //  }}
            subTitle={subTitle}
            extra={extra}
        />
        {children ? <Layout.Content className={styles.content}>{children}</Layout.Content> : null}
    </div>
);

PageContent.propTypes = {
    title: PropTypes.string,
    wrapperClassName: PropTypes.string,
};


export default PageContent;