import axios from './rest-axios';

const getPoint = (n, x) => axios.get('/v1/point/', {n, x});
const getPointMain = (id) => axios.get(`/v1/point/${id}`);
const getPointsAll = (id) => axios.get(`/v1/point/all`);
const getPointsStat = (student_id, ids) => axios.get(`/v1/point/stat`, {student_id, ids});

export {
    getPoint,
    getPointMain,
    getPointsAll,
    getPointsStat
};
