import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getRoles} from "../../lib/api_roles";
import {
    Button,
    Modal,
    Table,
    Divider,
    Menu,
    Dropdown,
    Icon,
    Popconfirm,
    message,
    Switch,
    Card
} from 'antd';
import PageContent from '../../components/page-content/page-content';
// import StudentAddModal from './teacher-add-modal';
// import StudentEditModal from './teacher-edit-modal';
// import {getTeachers,putTeacherFile} from "../../lib/api_teachers";
import RoleEditModal from './role-edit-modal';

const {Column} = Table;


class Students extends Component {
    state = {
        list: [],
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getRoles()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                }
            })
    };

    render() {
        let {list} = this.state;
        return (
            <PageContent title={"角色管理"}>
                <Card bordered={false}>
                    <Table dataSource={list} rowKey={"id"} pagination={false} size={"small"}>
                        <Column
                            title="序号"
                            dataIndex="id"
                            key="id"
                            width={80}
                        />

                        <Column
                            title="角色名称"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="操作"
                            key="action"
                            width={150}
                            render={(text, record) => (
                                <div>
                                    <a onClick={() => {
                                        this.setState({editRecord: record})
                                    }}>编辑</a>
                                    <Divider type="vertical"/>
                                    <a disabled onClick={() => this.showEditModal(record)}>查看权限</a>
                                </div>
                            )}
                        />
                    </Table>

                </Card>
                <RoleEditModal
                    data={this.state.editRecord}
                    onOk={() => {
                        this.setState({editRecord: null});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({editRecord: null})
                    }}
                />
            </PageContent>
        )
    }
}


Students.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Students));


