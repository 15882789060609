import PropTypes from 'prop-types';
import React from 'react';
import {Card, Divider, Row, Col, Space, Button, Checkbox, Modal, Typography, Radio,Popover} from 'antd';
import {FileDoneOutlined, CloseOutlined, PlusOutlined, HeartOutlined, HeartFilled} from '@ant-design/icons';
import moment from 'moment';

const {Text} = Typography;


function info(answers) {
    Modal.info({
        // title: 'This is a notification message',
        icon: null,
        maskClosable: true,
        width: 650,
        content: (
            <div>
                {answers.map(item => <p dangerouslySetInnerHTML={{__html: item.topic_answer_content}}/>)}
            </div>
        ),
        onOk() {
        },
    });
}


const Bar1 = ({data, onExample, onUnExample, onRemove, onAdd, onNotUse, onUse, isRemove}) => (
    <Row align="middle">
        <Col flex={"auto"}>
            <Text type="secondary">
                <Space>
                    <span>难度系数：{(data.difficult_level / 100.0).toFixed(2)}</span>
                    <span>使用次数：T</span>
                </Space>
            </Text>
        </Col>
        <Col>
            <Space>
                {data.answers && data.answers.length > 0 &&
                <a type="link" onClick={() => info(data.answers)}><FileDoneOutlined/> 解析</a>}

                {/*<Button>移除题型</Button>*/}
                {isRemove
                    ?
                    <>
                    <a type="link" onClick={() => onAdd && onAdd(data)} style={{color: "#fa8c16"}}><PlusOutlined/> 添加题型</a>
                    </>
                    : <>
                    <a type="link" onClick={() => onRemove && onRemove(data)}
                       style={{color: "#fa8c16"}}><CloseOutlined/> 移除题型</a>
                    {/*<Radio.Group value={data.showType}>*/}
                    {/*<Radio value={1} style={{color:"#1890ff"}}*/}
                    {/*onClick={(e) => {*/}
                    {/*onExample && onExample(data)*/}
                    {/*}}*/}
                    {/*>设为例题</Radio>*/}
                    {/*<Radio value={2} style={{color:"#f5222d"}}>不使用</Radio>*/}
                    {/*</Radio.Group>*/}

                    <Checkbox
                        checked={data.isExample}
                        onChange={(e) => {
                            e.target.checked ? onExample && onExample(data) : onUnExample && onUnExample(data)
                        }}
                        style={{color: "#1890ff"}}
                    >设为例题</Checkbox>
                    {/*<Checkbox*/}
                    {/*checked={data.isNotUse}*/}
                    {/*onChange={(e) => {*/}
                    {/*e.target.checked ? onNotUse && onNotUse(data) : onUse && onUse(data)*/}
                    {/*}}*/}
                    {/*style={{color: "#f5222d"}}*/}
                    {/*>不使用</Checkbox>*/}
                    </>}

            </Space>
        </Col>
    </Row>
);
const Bar2 = ({data, onExample, onUnExample, onRemove, onUnCollect, onCollect, onExercise, onUnExercise, onNotUse, onUse}) => (
    <Row align="middle">
        <Col flex={"auto"}>
            <Text type="secondary">
                <Space>
                    <span>收录：{moment(data.topic_time).format("YYYY-MM-DD")}</span>
                    <span>难度系数：{(data.difficult_level / 100.0).toFixed(2)}</span>
                    <span>使用次数：{data.use_times}</span>
                    <span>来源：{data.source}</span>
                </Space>
            </Text>
        </Col>
        <Col>
            <Space>
                {data.answers && data.answers.length > 0 &&
                <a type="link" onClick={() => info(data.answers)}><FileDoneOutlined/> 解析</a>}
                {data.isCollect
                    ? <a type="link" onClick={() => onUnCollect && onUnCollect(data)}><HeartFilled
                        style={{color: "#ff0000"}}/> 取消收藏</a>
                    : <a type="link" onClick={() => onCollect && onCollect(data)}><HeartOutlined/> 收藏</a>
                }


                <Checkbox
                    checked={data.isExample}
                    onChange={(e) => {
                        e.target.checked ? onExample && onExample(data) : onUnExample && onUnExample(data)
                    }}>设为例题</Checkbox>
                <Checkbox
                    checked={data.isNotUse}
                    onChange={(e) => {
                        e.target.checked ? onNotUse && onNotUse(data) : onUse && onUse(data)
                    }}
                    style={{color: "#f5222d"}}
                >不使用</Checkbox>
                <Checkbox
                    checked={data.isExercise}
                    onChange={(e) => {
                        e.target.checked ? onExercise && onExercise(data) : onUnExercise && onUnExercise(data)
                    }}
                >加入练习</Checkbox>
            </Space>
        </Col>
    </Row>
);

const TopicOpt = ({data, ...props}) => {
    return (
        <Card bordered={false} size={"small"}>
            {data.wrong &&
                <Popover content={<span>
                    {moment(data.wrong.ctime*1000).format("YYYY-MM-DD")}<br/>
                    {data.wrong.from}
                </span>}>
                    <div style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        padding: "5px 10px",
                        backgroundColor: "#ffffff",
                        borderRadius: 5,
                        border: "solid 1px red",
                        color: "red"
                    }}>学生错题</div>
                </Popover>
            }

            <div dangerouslySetInnerHTML={{__html: data.topic_content}}/>
            <Divider dashed style={{margin: "12px 0"}}/>
            <div>
                {/*{data.example_uid && data.example_uid > 0 ?*/}
                    {/*<Bar2 data={data} {...props} /> : <Bar1 data={data} {...props}/>*/}
                {/*}*/}

                <Bar2 data={data} {...props} />
            </div>
        </Card>
    )
};

TopicOpt.propTypes = {
    data: PropTypes.object.isRequired,
    onExample: PropTypes.func,  // 设为例题
    onUnExample: PropTypes.func,  // 取消设为例题
    onExercise: PropTypes.func, // 设为练习题
    onUnExercise: PropTypes.func, // 取消设为练习题
    onRemove: PropTypes.func, // 移除题型
    onAdd: PropTypes.func, // 添加题型
    onCollect: PropTypes.func, // 收藏
    onUnCollect: PropTypes.func, // 取消收藏
    onUse: PropTypes.func, // 使用
    onNotUse: PropTypes.func, // 不使用
    isRemove: PropTypes.bool, // 是否移除
};

export default TopicOpt