import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    Form,
    Steps,
    Row,
    Col,
    Input,
    Select,
    Radio,
    Typography,
    Tree,
    Space,
    Empty,
    Affix,
    Collapse,
    Modal,
    Tabs
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDoc} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined, DragOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import PointSelect from './point-select';
import MyStudentsSelect from './my-students-select';
import {getMytudents} from "../../lib/api_students";
import GradeName from '../../components/grade-name';
import {getExample, getExampleChild} from "../../lib/api_example";
import TopicOpt from '../../components/teaching_doc/topic-opt2';
import TopicOpt3 from '../../components/teaching_doc/topic-opt3';
import moment from 'moment';
import {getPoint} from "../../lib/api_point";
import styles from './step2.module.css';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import Setp3OrderPoints from './step3-order-points';
import Setp3OrderTraining from './step3-order-training';

const {CheckableTag} = Tag;
const {Text} = Typography;
const {DirectoryTree} = Tree;
const {Panel} = Collapse;


class Step3TrainingModal extends Component {

    state = {};

    componentWillMount() {

    }

    componentDidMount() {

    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    addExercises = (data, id) => {
        let {topicType} = this.props;
        if (id === 1) {
            topicType.training1.push(data.topic_id);
        }
        if (id === 2) {
            topicType.training2.push(data.topic_id);
        }
        this.setState({});
    };

    removeExercises = (data, id) => {
        let {topicType} = this.props;
        if (id === 1) {
            let index = topicType.training1.findIndex(item => item === data.topic_id);
            if (index !== -1) {
                topicType.training1.splice(index, 1);
                this.setState({})
            }
        }
        if (id === 2) {
            let index = topicType.training2.findIndex(item => item === data.topic_id);
            if (index !== -1) {
                topicType.training2.splice(index, 1);
                this.setState({})
            }
        }

    };


    getTopics = (arr, orderarr) => {
        let list = [];
        for (let i in orderarr) {
            let obj = arr.find(item=> item.topic_id === orderarr[i]);
            if(obj){
                list.push(obj)
            }
        }
        return list;
    };

    render() {
        let {topicType, onCancel} = this.props;
        let training1 = [];
        let training2 = [];
        let others = [];
        if (topicType) {
            training1 = this.getTopics(topicType.exercises, topicType.training1);
            training2 = this.getTopics(topicType.exercises, topicType.training2);
            others = topicType.exercises.filter(item => !(topicType.training1.includes(item.topic_id) || topicType.training2.includes(item.topic_id)));
        }
        return (
            <Modal
                // title="设置闯关训练"
                visible={topicType}
                width={1000}
                // onOk={this.handleOk}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                footer={null}
                bodyStyle={{
                    // backgroundColor: "#f5f5f5"
                }}
            >
                {topicType &&
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="闯关训练1" key="1">
                        <Row gutter={24} style={{flexWrap: "nowrap"}}>

                            <Col flex={"200px"}>
                                <Setp3OrderTraining items={topicType.training1} onChange={(items) => {
                                    topicType.training1 = items;
                                    this.setState({})
                                }}/>
                            </Col>
                            <Col flex={"auto"}>
                                {training1.map(se =>
                                    <TopicOpt3
                                        data={se}
                                        // pid={this.state.example.topic_id}
                                        onExercise={(data, pid) => {
                                            this.addExercises(data, 1)
                                        }}
                                        onUnExercise={(data, pid) => {
                                            this.removeExercises(data, 1)
                                        }}
                                        checked
                                    />)}
                                {others.map(se =>
                                    <TopicOpt3
                                        data={se}
                                        // pid={this.state.example.topic_id}
                                        onExercise={(data, pid) => {
                                            this.addExercises(data, 1)
                                        }}
                                        onUnExercise={(data, pid) => {
                                            this.removeExercises(data, 1)
                                        }}
                                        checked={false}
                                    />)}
                            </Col>
                        </Row>

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="闯关训练2" key="2">
                        <Row gutter={24} style={{flexWrap: "nowrap"}}>

                            <Col flex={"200px"}>
                                <Setp3OrderTraining items={topicType.training2} onChange={(items) => {
                                    topicType.training2 = items;
                                    this.setState({})
                                }}/>
                            </Col>
                            <Col flex={"auto"}>
                                {training2.map(se =>
                                    <TopicOpt3
                                        data={se}
                                        // pid={this.state.example.topic_id}
                                        onExercise={(data, pid) => {
                                            this.addExercises(data, 2)
                                        }}
                                        onUnExercise={(data, pid) => {
                                            this.removeExercises(data, 2)
                                        }}
                                        checked
                                    />)}
                                {others.map(se =>
                                    <TopicOpt3
                                        data={se}
                                        // pid={this.state.example.topic_id}
                                        onExercise={(data, pid) => {
                                            this.addExercises(data, 2)
                                        }}
                                        onUnExercise={(data, pid) => {
                                            this.removeExercises(data, 2)
                                        }}
                                        checked={false}
                                    />)}
                            </Col>
                        </Row>

                    </Tabs.TabPane>

                </Tabs>
                }
            </Modal>

        )
    }
}


Step3TrainingModal.propTypes = {
    topicType: PropTypes.object,
    onCancel: PropTypes.func
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Step3TrainingModal));


