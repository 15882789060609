import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, Tag, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getTeachingDocMy} from "../../../lib/api_teaching_doc";
import TeachingDocStatusName from '../../../components/teaching_doc/teaching-doc-status-name';
import SemesterName from '../../../components/semester-name';

class JingZhunIndex extends Component {
    state = {
        list: [],
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getTeachingDocMy()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data,
                    })
                }
            })
    };


    render() {
        let {list, filter} = this.state;
        return (
            <PageContent title={"精准教学"}>
                <Row gutter={24}>
                    {list.map(item => {
                        return (
                            <Col span={8}>
                                <Link to={`/jingzhun/${item.id}`}>
                                <Card title={item.name}
                                      bordered={false}
                                      hoverable
                                      extra={item.student_name}>

                                    <div>
                                        {item.point_config && item.point_config.map(item => <div
                                            style={{display: "flex"}}>
                                            <div style={{flex: 1}}>{item.point_main_name}</div>
                                            <div style={{width: 30}}>T</div>
                                            <div style={{width: 30}}>{item.topicType_count}</div>
                                            <div style={{width: 30}}>{item.example_count}</div>
                                            <div style={{width: 30}}>{item.exercises_count}</div>

                                        </div>)
                                        }
                                    </div>
                                    <Tag><TeachingDocStatusName status={item.status}/></Tag>
                                    <div>
                                        {item.create_on}
                                    </div>
                                    <div>
                                        <SemesterName id={item.semester} />
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            </PageContent>
        )
    }
}


JingZhunIndex.propTypes = {};


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(JingZhunIndex));


