import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {putTeacher} from "../../lib/api_teachers";
import {
    Modal,
    Input,
    Form,
    Checkbox,
    Select
} from 'antd';
import config from '../../config';
import {getTeachers} from "../../lib/api_teachers";
// import {putStudent} from "../../lib/api_kehu";
import {postSearch} from "../../lib/api_kehu";
import {putStudentWx} from "../../lib/api_students";


class UserEditModal extends Component {
    // formRef = React.createRef();

    state = {
        loading: false,
        list: [],
        id: 0,
        teacher_id: 0,
        qx_wx_student_id:0
    };

    componentDidMount() {
        this.setState({
            qx_wx_student_id: this.props.data.qx_wx_student_id
        });
        this.fetch()
    }

    fetch = (key) => {
        postSearch(0, 0, key, 1, 100)
            .then((result) => {
                if (result.code === 0) {
                    // console.log()
                    // 去重复
                    let list = result.data.list;
                    let list2 = [];
                    list.forEach(ls1=>{
                        if(!list2.some(ls2=> ls2.id === ls1.id)){
                            list2.push(ls1)
                        }
                    });
                    this.setState({
                        list: list2,
                    })
                }
            })
    };



    handleFinish = () => {
        putStudentWx({
            id: this.props.data.id,
            qx_wx_student_id: this.state.qx_wx_student_id
        }).then(res => {
            if (res.code === 0) {
                   console.log(res);
                   this.handleOK();
            }
        })
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {list} = this.state;
        const {data} = this.props;
        return (
            <Modal
                title="选择微信学员"
                visible={!!data}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                <Select
                    style={{width: 200}}
                    showSearch
                    allowClear={false}
                    placeholder="选择微信学员"
                    optionFilterProp="children"
                    value={this.state.qx_wx_student_id}
                    onChange={(value) => this.setState({qx_wx_student_id: value})}
                    onSearch={this.fetch}
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                >
                    {list.map(item => <Select.Option value={item.id}>{item.s_realname}</Select.Option>)}
                </Select>
            </Modal>
        )
    }

}


UserEditModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditModal));
