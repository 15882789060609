import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, Progress, Row, Col} from 'antd';
import {
    PlusOutlined,
    SolutionOutlined,
    DragOutlined,
    ReadOutlined,
    CodepenOutlined,
    ContainerOutlined,
    WifiOutlined,
    ExperimentOutlined,
    SnippetsOutlined,
    AimOutlined
} from '@ant-design/icons';
import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate,
    Interaction
} from "bizcharts";

class Preview extends Component {
    render() {
        const {data, answers} = this.props;

        let str_important = [];
        let str_difficult = [];
        data.points.forEach(point => {
            if (point.important) {
                str_important.push(point.point_main_name)
            }
            if (point.difficult) {
                str_difficult.push(point.point_main_name)
            }
        });
        let tt_index = 0;
        let example_index = 0;

        let err_data = [
            {item: '概念不清', count: 0},
            {item: '计算错误', count: 0},
            {item: '粗心大意', count: 0},
            {item: '方法错误', count: 0},
            {item: '审题错误', count: 0},
            {item: '用错公式', count: 0},
            {item: '不理解', count: 0},
            {item: '其他原因', count: 0},
        ];

        answers.forEach(answer => {
            if (answer.error_reason) {
                let error_reason = JSON.parse(answer.error_reason);
                if (error_reason) {

                    error_reason.forEach(item => {
                        err_data[item].count += 1;
                    })
                }
            }
        });
        err_data = err_data.filter(item => item.count > 0);

        let result_data = [
            {item: '全对', count: 0},
            {item: '小错', count: 0},
            {item: '半错', count: 0},
            {item: '大错', count: 0},
            {item: '全错', count: 0},
        ];
        answers.forEach(answer => {
            if (answer.result) {
                result_data[answer.result - 1].count += 1;
            }
        });


        console.log(data, answers);
        return (
            <Card bordered={false} bodyStyle={{padding: "10px 40px"}}>
                <h1 style={{textAlign: "center", fontSize: 24}}>
                    <SnippetsOutlined/> {data.name}
                </h1>
                <h2>
                    <CodepenOutlined/> 本次课学情总结
                </h2>
                <div style={{marginLeft: 30}}>
                    <h3><ContainerOutlined/> 教学重难点</h3>
                    <p><strong>教学重点</strong>：{str_important.join(",")}</p>
                    <p><strong>教学难点</strong>：{str_difficult.join(",")}</p>
                    <h3><ContainerOutlined/> 教学内容</h3>
                    <p><strong>本节课掌握度</strong>：{data.training_persent}%</p>
                    <p>
                        本次课学习{data.points.map(item => item.point_main_name).join('，')}共{data.points.length}个知识点，
                        实际上课已完成{data.taught_point_count}个知识点。实际课堂进度完成100%

                    </p>
                    {data.points.map((point, index) => <div key={index}>
                        <span style={{width: 150, display: "inline-block"}}>{point.point_main_name}</span><span
                        style={{width: 200, display: "inline-block"}}><Progress
                        percent={point.training_persent}/></span>
                    </div>)}
                    <h3><ContainerOutlined/> 课堂作答情况</h3>

                    <Row>
                        <Col span={12}>
                            <Chart height={200} data={result_data} autoFit>
                                <Coordinate type="theta" radius={0.75}/>
                                <Tooltip showTitle={false}/>
                                <Axis visible={false}/>
                                <Interval
                                    position="count"
                                    adjust="stack"
                                    color="item"
                                    style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                    }}
                                    label={['count', {
                                        content: (data) => {
                                            return `${data.item}: ${data.count}`;
                                        },
                                    }]}
                                />
                                <Interaction type='element-single-selected'/>

                            </Chart>
                        </Col>
                        <Col span={12}>

                            <Chart height={200} data={err_data} autoFit>
                                <Coordinate type="theta" radius={0.75}/>
                                <Tooltip showTitle={false}/>
                                <Axis visible={false}/>
                                <Interval
                                    position="count"
                                    adjust="stack"
                                    color="item"
                                    style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                    }}
                                    label={['count', {
                                        content: (data) => {
                                            return `${data.item}: ${data.count}`;
                                        },
                                    }]}
                                />
                                <Interaction type='element-single-selected'/>

                            </Chart>
                        </Col>
                    </Row>
                    <p>
                        非常棒！今天你的课堂听课效率极高，已经对{data.taught_point_count}知识都熟悉掌握了，请继续保持你火热的学习状态哟！
                    </p>
                    <h3><ContainerOutlined/> 总结</h3>
                    <p>
                        综合作业，进门测，课堂习题的错误情况错因归类：<br/>
                        本次课课堂进度完成低于90%，说明课堂吸收理解效率不高，代表所授知识题型对我们来说理解是较为缓慢的，需要我们在课后快速复习巩固。<br/>
                        高于90%，说明课堂吸收效率很高，能够紧跟老师的思路和上课节奏，希望课后对例题和变式快速复习，区分它们之间的差异，达到完全吸收的水平。<br/>
                    </p>
                </div>
            </Card>
        )
    }
}

Preview.propTypes = {
    data: PropTypes.object,
    answers: PropTypes.object,

};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Preview));


