import PropTypes from 'prop-types';
import React from 'react';
import {Card, Divider, Row, Col, Space, Button, Checkbox, Modal, Typography, Popover} from 'antd';
import {FileDoneOutlined, CloseOutlined, PlusOutlined, HeartOutlined, HeartFilled} from '@ant-design/icons';
import moment from 'moment';

const {Text} = Typography;


function info(answers) {
    Modal.info({
        // title: 'This is a notification message',
        icon: null,
        maskClosable: true,
        width: 650,
        content: (
            <div>
                {answers.map(item => <p dangerouslySetInnerHTML={{__html: item.topic_answer_content}}/>)}
            </div>
        ),
        onOk() {
        },
    });
}


const Bar2 = ({data, onExercise}) => (
    <Row align="middle">
        <Col flex={"auto"}>
            <Text type="secondary">
                <Space>
                    <span>收录：{moment(data.topic_time).format("YYYY-MM-DD")}</span>
                    <span>难度系数：T</span>
                    <span>使用次数：T</span>
                </Space>
            </Text>
        </Col>
        <Col>
            <Space>
                {data.answers && data.answers.length > 0 &&
                <a type="link" onClick={() => info(data.answers)}><FileDoneOutlined/> 解析</a>
                }
                {/*<a type="link" onClick={()=> {onExercise && onExercise(data)}}><PlusOutlined /> 闯关训练</a>*/}
            </Space>
        </Col>
    </Row>
);

const TopicOpt = ({data, ...props}) => {
    return (
        <Card bordered={false} size={"small"}>
            {data.wrong &&
            <Popover content={<span>
                    {moment(data.wrong.ctime*1000).format("YYYY-MM-DD")}<br/>
                {data.wrong.from}
                </span>}>
                <div style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    padding: "5px 10px",
                    backgroundColor: "#ffffff",
                    borderRadius: 5,
                    border: "solid 1px red",
                    color: "red"
                }}>学生错题</div>
            </Popover>
            }
            <div dangerouslySetInnerHTML={{__html: data.topic_content}}/>
            <Divider dashed style={{margin: "12px 0"}}/>
            <div>
                <Bar2 data={data} {...props}/>
            </div>
        </Card>
    )
};

TopicOpt.propTypes = {
    data: PropTypes.object.isRequired,
    onExercise: PropTypes.func, // 设为练习题
};

export default TopicOpt