import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {putRoles} from "../../lib/api_roles";
import {
    Button,
    Modal,
    Input,
    Row,
    Col,
    Form,
} from 'antd';

class RoleEditModal extends Component {
    formRef = React.createRef();

    state={
        loading: false
    };

    onSubmit = (values)=>{
        values.id = this.props.data.id;
        putRoles(values)
            .then(result => {
                console.log(result);
                // 刷新数据
                this.handleOK();
            })
    };

    handleFinish = () => {
        this.formRef.current
            .validateFields()
            .then(values => {
                console.log(values)
                // form.resetFields();
                this.onSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });

        // console.log(values)
        // putRoles({
        //     id: this.state.user_info.id,
        //     nick_name: this.state.user_info.nick_name,
        //     mobile_number: this.state.user_info.mobile_number,
        // })
        //     .then(result => {
        //         console.log(result);
        //         // 刷新数据
        //         this.handleOK();
        //     })
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {data} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };
        return (
            <Modal
                title="修改角色信息"
                visible={!!data}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                {data &&
                <Form {...formItemLayout}
                      initialValues={data}
                      ref={this.formRef}
                >
                    <Form.Item
                        label={"角色名称"}
                        name="name"
                        rules={[{required: true, message: '请输入角色名称'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
                }
            </Modal>
        )
    }

}


RoleEditModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleEditModal));
