import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    List,
    Avatar,
    Space,
    Select,
    Cascader,
    Table,
    Upload,
    message,
    Divider
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDoc} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined, UserOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import {getMytudents} from "../../lib/api_students";
// import styles from './list.module.css';
import GradeName from '../../components/grade-name';
import SchoolSelect from '../mystudents/school-select';
import {getSchoolClasses} from "../../lib/api_school";
import {getClassestudents} from "../../lib/api_students";
import axios from 'axios';

const {Option} = Select;

const {CheckableTag} = Tag;
const {Column} = Table;

const StatusName = ({status}) => {
    switch (status) {
        case 1:
            return <Tag color="#87d068" style={{fontSize: 20, lineHeight: "30px"}}>在读</Tag>;
        case 2:
            return <Tag color="#f50" style={{fontSize: 20, lineHeight: "30px"}}>停课</Tag>;
        case 3:
            return <Tag color="#666" style={{fontSize: 20, lineHeight: "30px"}}>结课</Tag>;
        case 4:
            return <Tag color="#666" style={{fontSize: 20, lineHeight: "30px"}}>退费</Tag>;
    }
};

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}


class MyStudents extends Component {
    state = {
        list: [],
        filter: {},
        options: []
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        // axios({
        //     method: 'POST',
        //     url: 'https://qinxue.vxmsg.net/wechat/upload/testcross',
        //     data: {id:123}
        // }).then(res => {
        //     console.log(res)
        // })

        getSchoolClasses()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        options: result.data,
                    })
                }
            });

    };

    fetchStudents = (id) => {
        getClassestudents(id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data,
                    })
                }
            })
    };

    onClassChange = (value) => {
        console.log(value);
        this.fetchStudents(value[1]);
    };

    handlePostRegTopic = (file_name, total_score, student_id) => {
        const formData = new FormData();
        // fileList.forEach(file => {
        //     formData.append('myfile', file);
        // });
        formData.append('file_name', file_name);

        formData.append('upload_ocr_flag', 1);
        formData.append('total_score', total_score);
        formData.append('student_id', student_id);
        axios({
            method: 'POST',
            url: 'https://qinxue.vxmsg.net/wechat/upload/regTopic',
            data: formData
        }).then(res => {
            console.log(res)
            if (res.success) {
                message.success("上传成功！")
            } else {
                message.error(JSON.stringify(res));
            }
        })
    };

    render() {
        let {options, list} = this.state;

        const props = {
            name: 'file',
            showUploadList: false,
            // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            // headers: {
            //     authorization: 'authorization-text',
            // },
            beforeUpload: () => {
                return false
            },
//             onChange(info) {
//                 console.log(info);
//                 const { fileList } = info;
//                 const formData = new FormData();
//                 // fileList.forEach(file => {
//                 //     formData.append('myfile', file);
//                 // });
//                 formData.append('myfile', info.file);
//
//                 formData.append('upload_ocr_flag', 1);
//                 formData.append('student_id', 704);
//                 axios({
//                     method: 'POST',
//                     url: 'https://qinxue.vxmsg.net/wechat/upload/upload_ocr',
//                     data: formData
//                 }).then(res => {
//                     console.log(res)
//                     if(res.file_name){
// this.handlePostRegTopic(res.file_name, 0, )
//                     }
//                 })
//
//                 // if (info.file.status !== 'uploading') {
//                 //     console.log(info.file, info.fileList);
//                 // }
//                 // if (info.file.status === 'done') {
//                 //     // message.success(`${info.file.name} file uploaded successfully`);
//                 // } else if (info.file.status === 'error') {
//                 //     // message.error(`${info.file.name} file upload failed.`);
//                 // }
//             },
        };

        return (
            <PageContent title={"扫描录题"}>
                <Card bordered={false} size={"small"}>
                    班级：
                    <Cascader
                        // showSearch={{filter:(inputValue, path)=> {
                        //     return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                        // }}}
                        showSearch={true}
                        placeholder="选择班级"
                        options={options}
                        expandTrigger="hover"
                        // displayRender={displayRender}
                        onChange={this.onClassChange}
                        style={{width: 300}}
                    />
                </Card>
                <Table dataSource={list} rowKey={"id"} pagination={false} size={"small"}>
                    <Column
                        title="姓名"
                        dataIndex="s_realname"
                        key="s_realname"
                    />

                    <Column
                        title="操作"
                        key="action"
                        width={250}
                        render={(text, record) => (
                            <div>
                                <Upload {...props} onChange={(info) => {
                                    console.log(info);
                                    const {fileList} = info;
                                    const formData = new FormData();
                                    // fileList.forEach(file => {
                                    //     formData.append('myfile', file);
                                    // });
                                    formData.append('myfile', info.file);

                                    formData.append('upload_ocr_flag', 1);
                                    formData.append('student_id', record.id);
                                    axios({
                                        method: 'POST',
                                        url: 'https://qinxue.vxmsg.net/wechat/upload/upload_ocr',
                                        data: formData
                                    }).then(res => {
                                        console.log(res)
                                        if (res.file_name) {
                                            this.handlePostRegTopic(res.file_name, 0, record.id)

                                        }
                                    })
                                }}>
                                    <a>
                                        作业本
                                    </a>
                                </Upload>
                                <Divider type="vertical"/>
                                <Upload {...props} onChange={(info) => {
                                    console.log(info);
                                    const {fileList} = info;
                                    const formData = new FormData();
                                    // fileList.forEach(file => {
                                    //     formData.append('myfile', file);
                                    // });
                                    formData.append('myfile', info.file);

                                    formData.append('upload_ocr_flag', 1);
                                    formData.append('student_id', record.id);
                                    axios({
                                        method: 'POST',
                                        url: 'https://qinxue.vxmsg.net/wechat/upload/upload_ocr_paper',
                                        data: formData
                                    }).then(res => {
                                        console.log(res)
                                        if (res.file_name) {
                                            this.handlePostRegTopic(res.file_name, res.score, record.id)

                                        }
                                    })
                                }}>
                                    <a>
                                        试卷
                                    </a>
                                </Upload>
                            </div>
                        )}
                    />
                </Table>

            </PageContent>
        )
    }
}


MyStudents.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyStudents));


