import PropTypes from 'prop-types';
import React from 'react';

const DengDiName = ({student_score, score}) => {
    let levels = [[0.9, 1], [0.7, 0.9], [0.6, 0.7], [0, 0.6]];
    let index = -1;
    for (let i in levels) {
        let level = levels[i];
        if (student_score >= score * level[0] && student_score <= score * level[1]) {
            index = parseInt(i);
            break;
        }
    }
    switch (index) {
        case 0:
            return "优秀";
        case 1:
            return "良好";
        case 2:
            return "及格";
        case 3:
            return "不及格";
        default:
            return "";
    }
};

DengDiName.propTypes = {
    student_score: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
};

export default DengDiName;


