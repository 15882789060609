import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Tree,Radio} from 'antd';
import {getPoint} from "../../lib/api_point";
import config from '../../config';

const {DirectoryTree} = Tree;

class PointTree extends Component {
    state = {
        n: 0,
        x: 0,
        point_type: 1,
        list: [],
        treeData: []
    };

    componentDidMount() {
        this.fetchData(this.props.n, this.props.x)
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.n !== nextProps.n || this.props.x !== nextProps.x) {
            this.fetchData(nextProps.n, nextProps.x);
        }
    }

    fetchData = (n, x) => {
        if (n > 0 && x > 0) {
            getPoint(n, x)
                .then((result) => {
                    if (result.code === 0) {
                        let tree = this.dealData({key: 0}, result.data);
                        this.setState({
                            list: result.data,
                            treeData: tree.children || []
                        });
                        console.log(tree.children)
                    }
                })
        }
    };

    dealData = (node, list) => {
        let points = list.filter(item => item.point_pid === node.key);
        if (points && points.length > 0) {
            let children = [];
            for (let i in points) {
                let point = points[i];
                let leafNode = {
                    title: point.point_name,
                    key: point.point_id
                };
                this.dealData(leafNode, list);
                children.push(leafNode);
            }
            node.children = children;
        } else {
            node.isLeaf = true;
        }
        return node;
    };

    onSelect = (keys, event) => {
        console.log('Trigger Select', keys, event);
        this.props.onChange && this.props.onChange(keys[0], event.node.title);
    };

    handlePointTypeChange = (e)=>{
        console.log('radio checked', e.target.value);
        this.setState({
            point_type: e.target.value
        }, ()=>{
            let tree = this.dealData({key: 0}, this.state.list.filter(item=> item.type === this.state.point_type));
            this.setState({
                treeData: tree.children || []
            })
        })
    };


    render() {
        const {treeData} = this.state;
        const {n} = this.props;

        return (
            <div>
                {/*<Radio.Group onChange={this.handlePointTypeChange}*/}
                             {/*value={this.state.point_type}>*/}

                    {/*{[1,2,3,4,5,6].includes(n) && <Radio value={1}>章节目录</Radio>}*/}
                    {/*{[7,8,9].includes(n) && <Radio value={2}>中考目录</Radio>}*/}
                    {/*{[10,11,12].includes(n) && <Radio value={3}>高考目录</Radio>}*/}
                    {/*/!*{config.point_type.map(item=> <Radio value={item.id}>{item.name}</Radio>)}*!/*/}
                {/*</Radio.Group>*/}
                <DirectoryTree
                    multiple={false}
                    height={300}
                    defaultExpandAll
                    onSelect={this.onSelect}
                    // onExpand={onExpand}
                    treeData={treeData}
                />

            </div>
        )
    }
}


PointTree.propTypes = {
    n: PropTypes.number,
    x: PropTypes.number,
    onChange: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PointTree));


