import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    message,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDocMy,deleteTeachingDocById} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list-my';

const {CheckableTag} = Tag;


class TeachingDoc extends Component {
    state = {
        list: [],
        roles: [],
        filter: {
            grade: 0,
            subjects: 0,
            semester: 1,
            status: 0
        }
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getTeachingDocMy()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data,
                    })
                }
            })
    };

    handleGradeChange = (id, checked) => {
        if (checked) {
            this.setState({
                filter: Object.assign(this.state.filter, {grade: id})
            })
        }
    };

    handleSubjectsChange = (id, checked) => {
        if (checked) {
            this.setState({
                filter: Object.assign(this.state.filter, {subjects: id})
            })
        }
    };

    handleSemesterChange = (id, checked) => {
        if (checked) {
            this.setState({
                filter: Object.assign(this.state.filter, {semester: id})
            })
        }
    };

    handleStatusChange = (id, checked) => {
        if (checked) {
            this.setState({
                filter: Object.assign(this.state.filter, {status: id})
            })
        }
    };

    handleDelete = (id)=>{
        // console.log(id)
        deleteTeachingDocById(id)
            .then(res=>{
                if(res.code === 0){
                    message.success("删除成功");
                    this.fetchData();
                }
            })
    };

    render() {
        let {list, filter} = this.state;
        let filterList = list.filter(item => {
            if (filter.status > 0 && item.status !== filter.status) {
                return false;
            }
            if (filter.grade > 0 && item.grade !== filter.grade) {
                return false;
            }
            if (filter.subjects > 0 && item.subject !== filter.subjects) {
                return false;
            }
            if (filter.semester > 1 && item.semester !== filter.semester) {
                return false;
            }
            return true;
        });
        return (
            <PageContent title={"教案中心"}>
                <Card bordered={false}>
                    <div>
                        年级：
                        <CheckableTag
                            checked={filter.grade === 0}
                            onChange={checked => this.handleGradeChange(0, checked)}
                        >
                            全部
                        </CheckableTag>
                        {config.grade.map(item => (
                            <CheckableTag
                                key={item.id}
                                checked={filter.grade === item.id}
                                onChange={checked => this.handleGradeChange(item.id, checked)}
                            >
                                {item.name}
                            </CheckableTag>
                        ))}
                    </div>
                    <div>
                        科目：
                        <CheckableTag
                            checked={filter.subjects === 0}
                            onChange={checked => this.handleSubjectsChange(0, checked)}
                        >
                            全部
                        </CheckableTag>
                        {config.subjects.map(item => (
                            <CheckableTag
                                key={item.id}
                                checked={filter.subjects === item.id}
                                onChange={checked => this.handleSubjectsChange(item.id, checked)}
                            >
                                {item.name}
                            </CheckableTag>
                        ))}
                    </div>
                    <div>
                        学期：
                        {config.semester.map(item => (
                            <CheckableTag
                                key={item.id}
                                checked={filter.semester === item.id}
                                onChange={checked => this.handleSemesterChange(item.id, checked)}
                            >
                                {item.name}
                            </CheckableTag>
                        ))}
                    </div>
                    <div>
                        状态：
                        {[
                            {id: 0, name: "全部"},
                            {id: 3, name: "已使用"},
                            {id: 2, name: "未使用"},
                            {id: 1, name: "草稿"}
                        ].map(item => (
                            <CheckableTag
                                key={item.id}
                                checked={filter.status === item.id}
                                onChange={checked => this.handleStatusChange(item.id, checked)}
                            >
                                {item.name}
                            </CheckableTag>
                        ))}
                    </div>
                </Card>
                <Card bordered={false} bodyStyle={{padding: "0 24px"}}>
                    <div className="ant-pro-table-toolbar">
                        <div className="ant-pro-table-toolbar-title"><SolutionOutlined/> 我的教案库</div>
                        <div className="ant-pro-table-toolbar-option">

                            <div className="ant-pro-table-toolbar-item">
                                <Link to={"/teaching_doc/create"}>
                                    <Button><PlusOutlined/> 制作新教案</Button>
                                </Link>
                            </div>
                            <div className="ant-pro-table-toolbar-item">
                                <Link to={'/teaching_doc'}>
                                    <Button>公共教案库</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <TeachingDocList list={filterList} onDelete={this.handleDelete}/>
                </Card>
            </PageContent>
        )
    }
}


TeachingDoc.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TeachingDoc));


