import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {putTeacher} from "../../lib/api_teachers";
import {
    Modal,
    Input,
    Form,
    Checkbox
} from 'antd';
import config from '../../config';


class UserEditModal extends Component {
    formRef = React.createRef();

    state = {
        loading: false
    };

    onSubmit = (values) => {
        values.id = this.props.data.id;

        putTeacher(values)
            .then(result => {
                console.log(result);
                // 刷新数据
                this.handleOK();
            })
    };


    handleFinish = () => {
        this.formRef.current
            .validateFields()
            .then(values => {
                console.log(values)
                this.onSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {data, roles} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        let initialValues = {};
        if (data) {
            initialValues = {...data};
            initialValues.roles = JSON.parse(data.roles);
        }
        return (
            <Modal
                title="修改用户信息"
                visible={!!data}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                {data &&
                <Form {...formItemLayout}
                      initialValues={initialValues}
                      ref={this.formRef}
                >
                    <Form.Item
                        label={"姓名"}
                        name="realname"
                        rules={[{required: true, message: '请输入姓名'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"账号"}
                        name="name"
                        rules={[{required: true, message: '请输入账号'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"角色"}
                        name="roles"
                    >
                        <Checkbox.Group>
                            {roles.map(role => (
                                <Checkbox
                                    key={role.id}
                                    value={role.id}
                                    style={{
                                        lineHeight: '32px',
                                    }}
                                >
                                    {role.name}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        label={"科目"}
                        name="subjects"
                    >
                        <Checkbox.Group>
                            {config.subjects.map(role => (
                                <Checkbox
                                    key={role.id}
                                    value={role.id}
                                    style={{
                                        lineHeight: '32px',
                                    }}
                                >
                                    {role.name}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
                }
            </Modal>
        )
    }

}


UserEditModal.propTypes = {
    data: PropTypes.object,
    roles: PropTypes.array,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditModal));
