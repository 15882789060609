const SET_CLASS = 'current-class/SET_CLASS';

const initialState = null;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_CLASS:
            return {...action.classinfo};
        default:
            return state;
    }
};

const setClass = function (classinfo) {
    return {
        type: SET_CLASS,
        classinfo: classinfo
    }
};


export {
    reducer as default,
    initialState as initialCurrentClassState,
    setClass,
}