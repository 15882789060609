/**
 * @name: 顶部统一导航菜单
 * @description:
 * @author: Wood
 * @date: 2020/2/18
 * @update:
 */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Menu, Avatar} from 'antd';
import {signOut} from "../../reducers/account";
// import logo from '../../assets/images/logo.svg';
import styles from './account-menu.module.css';
import ChangePassword from '../../containers/change-password';
import {showPasswordModal} from "../../reducers/password-modal";
// import boy from '../../assets/images/boy.svg';
import {DownOutlined,LogoutOutlined} from '@ant-design/icons';

class AccountMenu extends Component {

    render() {
        const {account} = this.props;
        return (
            <div>

                <Menu
                    // theme="dark"
                    mode="horizontal"
                    // defaultSelectedKeys={['1']}
                    // style={{lineHeight: '64px'}}
                    // selectedKeys={['scratch']}
                    // className={styles.menu}
                    // forceSubMenuRender={true}
                >
                    <Menu.SubMenu
                                  title={
                                      <span className="submenu-title-wrapper">
                                          {account.realname}
                                          <DownOutlined />
                                          {/*<Avatar size={20} src={boy}/>{account.nick_name}*/}
                                          </span>
                                  }>
                        <Menu.Item>
                            <a onClick={() => this.props.showPasswordModal()}>修改密码</a>
                        </Menu.Item>
                        <Menu.Divider/>
                        <Menu.Item icon={<LogoutOutlined />} text='退出' as="a"
                                   onClick={this.props.signOut}>
                            退出
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
                <ChangePassword/>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(signOut()),
    showPasswordModal: ()=> dispatch(showPasswordModal()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountMenu);

