import axios from './rest-axios';

const getClassPaper = () => axios.get('/v1/class_paper/');
const postClassPaper = (paper_id, class_id,grade,subject) => axios.post('/v1/class_paper/', {paper_id, class_id,grade,subject});
const deleteClassPaper = (id) => axios.delete(`/v1/class_paper/${id}`);

export {
    getClassPaper,
    postClassPaper,
    deleteClassPaper
};
