import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {Card, Table, Divider, Select,Space} from 'antd';
import {getClassStudents, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";

let {Column} = Table;
let {Option} = Select;

class Index extends Component {
    state = {
        book_id: 0,
        books: [],
        list: []
    };


    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchClassBooks = () => {
        getClassBooks(this.props.currentClass.class_id)
            .then((result) => {
                if (result.code === 0) {
                    // let book_id = 0;
                    // if (result.data && result.data.length > 0) {
                    //     book_id = result.data[0].book_id;
                    // }
                    this.setState({
                        books: result.data,
                        // book_id
                    })
                }
            })
    };

    fetchStudents = () => {
        getClassStudents(this.props.currentClass.class_id, this.state.book_id)
            .then((result) => {
                if (result.code === 0) {
                    result.data.forEach(item => {
                        let py = pinyin(item.s_realname);
                        if (py && py.length > 0) {
                            console.log(py, py[0][0][0]);
                            item.py = py[0][0][0].toUpperCase();
                        }
                        // console.log(py);
                    });
                    const sortedData = result.data.sort((a, b) => {
                        return a.py.localeCompare(b.py);
                    });
                    this.setState({
                        list: sortedData,
                    })
                }
            })
    };

    fetchData = () => {
        // this.fetchClassBooks();
        this.fetchStudents();
    };

    filterBooks = (key)=>{
        console.log(key);
        this.setState({
            book_id: key
        }, this.fetchStudents)
    };


    render() {
        let {list,book_id} = this.state;
        let {currentClass} = this.props;
        let {booklist} = this.props.account;
        let books = booklist.filter(item=> item.class_id === currentClass.class_id && item.subject_id === currentClass.subject_id);
        return (
            <PageContent title={"班级信息"}>
                <Card bordered={false}>
                    <Space direction={'vertical'} size={10}>
                    <Space size={30}>
                        <span>学生总数：{list.length}人</span> <span>使用情况：{list.filter(item=> item.register>0).length}人</span>
                    </Space>
                        <div>
                            选择作业本：
                            <Select defaultValue={0} value={book_id} style={{width: 200}} onChange={this.filterBooks}>
                                <Option value={0}>全部</Option>
                                {books.map(item=> <Option value={item.book_id}>(<SubjectName id={item.subject_id}/>) {item.book_name}</Option>)}
                            </Select>

                        </div>
                    </Space>
                        <Divider/>
                    <Table dataSource={list} rowKey={"id"} pagination={false} size={"small"} bordered>
                        <Column
                            title=""
                            dataIndex="py"
                            key="py"
                            width={30}
                        />

                        <Column
                            title="学生姓名"
                            dataIndex="s_realname"
                            key="s_realname"
                        />
                        <Column
                            title="性别"
                            dataIndex="sex"
                            key="sex"
                            render={(sex) => {
                                return sex === 1 ? "男" : "女"
                            }}
                        />
                        <Column
                            title="登记进度"
                            key="djjd"
                            render={(record) => {
                                if(record.total > 0){
                                    return parseInt(record.register *100/ record.total) + "%"
                                }else{
                                    return "0%"
                                }
                            }}
                        />
                        <Column
                            title="录入题目数量"
                            dataIndex="register"
                            key="register"
                        />
                        <Column
                            title="错题数"
                            dataIndex="wrong_count"
                            key="wrong_count"
                        />
                        <Column
                            title="最近考试成绩"
                            dataIndex="student_score"
                            key="student_score"
                        />

                        <Column
                            title="操作"
                            key="action"
                            width={200}
                            render={(text, record) => (
                                <div>
                                    <Link to={`/school/student/${record.id}`}>
                                        查看学生档案
                                    </Link>
                                    {/*<a onClick={() => {*/}
                                        {/*this.setState({editRecord: record})*/}
                                    {/*}} disabled>查看学生档案</a>*/}
                                    <Divider type="vertical"/>
                                    <Link to={`/school/student/${record.id}/book`}>查看错题本</Link>
                                </div>
                            )}
                        />
                    </Table>

                </Card>
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass,
    account: state.account,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
