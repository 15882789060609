import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {Card, Table, Divider, Select, Space, InputNumber, Button, Row, Col, Tabs} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import {getChapters2, postSearch2} from "../../../lib/api_school_books";
import GradeName from '../../../components/grade-name2';

let {Column} = Table;
let {Option} = Select;

class Index extends Component {
    state = {
        grades: [],
        grade: null,
        error_topics: [],
        min_value: 1,
        max_value: 10,
        total:0,
        total_wrong:0
    };


    componentWillMount() {
        this.fetchData();
    }

    componentDidMount() {
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        let {currentClass} = this.props;
        // console.log(this.props.currentClass);
        // this.fetchClassBooks();
        getChapters2(currentClass.class_id, currentClass.subject_id)
            .then(res => {
                if (res.code === 0) {
                    console.log(res.data);
                    if (res.data && res.data.length > 0) {
                        this.setState({grades: res.data}, () => {
                            this.setGrade(res.data[0])
                        });
                    }
                }
            })
    };


    setGrade = (grade) => {
        this.setState({
            grade: grade
        }, () => {
            console.log(grade);
            if (grade.chapters && grade.chapters.length > 0) {
                this.setRootChapter(grade.chapters[0].id);
            }
        })
    };

    setRootChapter = (id) => {
        // console.log(id);
        let chapter = this.state.grade.chapters.find(item => item.id === id);
        this.setState({
            root_chapter: chapter
        }, () => {
            if (chapter.chapters && chapter.chapters.length > 0) {
                this.setChapter(chapter.chapters[0].id);
            }
        })
    };

    setChapter = (id) => {
        let chapter = this.state.root_chapter.chapters.find(item => item.id === id);
        this.setState({
            chapter: chapter
        }, () => {
            if (chapter.points && chapter.points.length > 0) {
                this.setPoint(chapter.points[0].point_main_id);
            }
        })
    };
    setPoint = (id) => {
        let point = this.state.chapter.points.find(item => item.point_main_id === id);
        this.setState({
            point: point
        }, ()=>{
            this.handleSearch()
        })
    };


    handleSearch = () => {
        let {currentClass} = this.props;
        let {point, min_value, max_value} = this.state;
        // 搜索操作
        postSearch2(currentClass.class_id,
            currentClass.grade,
            currentClass.subject_id,
            point.point_main_id,
            min_value,
            max_value
        )
            .then(res => {
                console.log(res);
                let students = [];
                let students_wrong = [];
                res.data.forEach(topic => {
                    students.push(...topic.right_student_names);
                    students.push(...topic.error_student_names);
                    students_wrong.push(...topic.error_student_names);
                });
                students = Array.from(new Set(students));
                students_wrong = Array.from(new Set(students_wrong));
                if (res.code === 0) {
                    this.setState({
                        error_topics: res.data,
                        total: students.length,
                        total_wrong: students_wrong.length,
                    })
                }
            })
    };

    filterBooks = (key) => {
        console.log(key);
        this.setState({
            book_id: key,
            chapters1_id: 0,
            chapters2_id: 0,
        }, this.filterChapter1)
    };

    filterChapter1 = () => {
        let {list, book_id, chapters1_id, chapters2_id} = this.state;
        let {booklist, chapters} = this.props.account;
        let currentBook = booklist.find(item => item.book_id === book_id);
        let chapters1 = [];
        if (currentBook) {
            chapters1 = chapters.filter(item => item.grade === currentBook.grade && item.subject === currentBook.subject_id && item.pid === 0)
        }
        if (chapters1.length > 0 && !chapters1_id) {
            chapters1_id = chapters1[0].id;
        }
        console.log(chapters1_id);
        this.setState({
            chapters1: chapters1,
            chapters1_id: chapters1_id,
            chapters2_id: 0,
        }, this.filterChapter2)
    };
    filterChapter2 = () => {
        let {list, book_id, chapters1_id, chapters2_id} = this.state;
        let {booklist, chapters} = this.props.account;
        let currentBook = booklist.find(item => item.book_id === book_id);
        let chapters2 = [];
        if (chapters1_id) {
            chapters2 = chapters.filter(item => item.grade === currentBook.grade && item.subject === currentBook.subject_id && item.pid === chapters1_id)
        }
        console.log(chapters2)
        if (chapters2.length > 0 && chapters2_id === 0) {
            chapters2_id = chapters2[0].id;
        }
        this.setState({
            chapters2: chapters2,
            chapters2_id: chapters2_id,
        }, this.fetchStudents)
    };


    render() {
        let {list, book_id, chapters1_id, chapters2_id, chapters1, chapters2,total,total_wrong} = this.state;
        let {booklist, chapters} = this.props.account;
        let books = booklist;
        // 过滤题目
        if (!chapters2_id) {
            list = [];
        } else {
            let currentChapter2 = chapters2.find(item => item.id === chapters2_id);
            let point_main_ids = currentChapter2.point_mains.map(item => item.point_main_id);
            console.log(point_main_ids);
            list = list.filter(item => {
                for (let i in item.answers) {
                    let answer = item.answers[i];
                    if (point_main_ids.includes(answer.topic_answer_point1)
                        || point_main_ids.includes(answer.topic_answer_point2)
                        || point_main_ids.includes(answer.topic_answer_point3)
                    ) {
                        return true;
                    }
                }
                return false
            })
        }
        let {grades, error_topics} = this.state;
        if (!grades || grades.length === 0) {
            return null;
        }
        return (
            <PageContent title={"错题分析"}>
                <Card bordered={false}>
                    <Space direction="vertical">
                        <Space>
                            知识点：
                            <Select
                                value={this.state.grade ? this.state.grade.grade : 0}
                                style={{width: 100}}>
                                {grades.map(item => <Option value={item.grade}><GradeName id={item.grade}/></Option>)}
                            </Select>
                            {this.state.root_chapter && <Select
                                value={this.state.root_chapter.id}
                                style={{width: 200}}
                                onChange={(value) => {
                                    this.setRootChapter(value)
                                }}>

                                {this.state.grade.chapters && this.state.grade.chapters.map(item => <Option
                                    value={item.id}>{item.name}</Option>)}
                            </Select>
                            }

                            {this.state.chapter && <Select
                                value={this.state.chapter.id}
                                style={{width: 200}}
                                onChange={(value) => {
                                    this.setChapter(value)
                                }}
                            >
                                {this.state.root_chapter.chapters && this.state.root_chapter.chapters.map(item =>
                                    <Option
                                        value={item.id}>{item.name}</Option>)}
                            </Select>
                            }
                            {this.state.point && <Select
                                value={this.state.point.point_main_id}
                                style={{width: 200}}
                                onChange={(value) => {
                                    this.setPoint(value)
                                }}>
                                {this.state.chapter.points && this.state.chapter.points.map(item => <Option
                                    value={item.point_main_id}>{item.point_main_name}</Option>)}
                            </Select>
                            }
                            <div>
                                已登记人数：{total}
                            </div>
                            <div>
                                错题总人数：{total_wrong}
                            </div>
                        </Space>
                        <Space>
                            <div>
                                错题人数：
                                <InputNumber min={1} max={this.state.max_value} value={this.state.min_value}
                                             defaultValue={1} style={{width: 80}}
                                             onChange={value => this.setState({min_value: value})}/> ~
                                <InputNumber min={this.state.min_value} value={this.state.max_value} defaultValue={1}
                                             style={{width: 80}} onChange={value => this.setState({max_value: value})}/>
                            </div>

                            {/*<Button type={"primary"} onClick={this.handleSearch}>查询</Button>*/}
                        </Space>
                    </Space>
                    <Divider/>
                    <Card bordered={false} size={"small"}>

                        {error_topics.map(topic => <Card>
                            <Row>
                                <Col span={18}>
                                    <div dangerouslySetInnerHTML={{__html: topic.topic_content}}/>
                                </Col>
                                <Col span={6}>
                                    <Tabs defaultActiveKey="1">
                                        <Tabs.TabPane tab="错题名单" key="1">
                                            {topic.error_student_names.join(',')}
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="正确名单" key="2">
                                            {topic.right_student_names.join(',')}
                                        </Tabs.TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Card>)}

                    </Card>
                </Card>
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
