import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {
    Card,
    Table,
    Divider,
    Select,
    Space,
    Tabs,
    Descriptions,
    Typography,
    Row,
    Col,
    Button,
    Tooltip as AntdTooltip,
    Tag,
    Checkbox
} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";
// import styles from './index.module.css';
import {getPaperInfo} from "../../../lib/api_school_papers";
import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate
} from "bizcharts";
import DataSet from "@antv/data-set";
// import Pk from './pk';
import {getStudentInfo} from "../../../lib/api_school_students";
import DengDiName from '../../components/dengdi-name';
import {getStudentPaper} from "../../../lib/api_school_papers";
import moment from 'moment';
import styles from './index.module.css';
import Pk from './pk-student';

let {Column} = Table;
let {Option} = Select;
let {TabPane} = Tabs;
const {Text} = Typography;


const cols = {
    // month: {
    //     range: [0.1, 0.8]
    // },
    score: {
        alias: '分数',
    }
};

class Index extends Component {
    state = {
        student_paper: null,
        student_info: {},
        paper_models: [],
        students: [],
        pk_ids: [],
        class_student_papers: [],
        class_student_paper_topics: [],
        paiming: 0
    };

    componentWillMount() {
        this.fetchData();
    }

    componentDidMount() {
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    fetchData = () => {
        // this.fetchClassBooks();
        getStudentPaper(this.props.id)
            .then(res => {
                if (res.code === 0) {
                    res.data.students.forEach(item => {
                        let py = pinyin(item.s_realname);
                        if (py && py.length > 0) {
                            // console.log(py, py[0][0][0]);
                            item.py = py[0][0][0].toUpperCase();
                        }
                        // console.log(py);
                    });
                    res.data.students = res.data.students.sort((a, b) => {
                        return a.py.localeCompare(b.py);
                    });

                    // 查找排名
                    let ppps = res.data.class_student_papers.filter(item=> item.student_score > res.data.student_paper.student_score);

                    this.setState({
                        student_paper: res.data.student_paper,
                        paper_models: res.data.paper_models,
                        student_info: res.data.student_info,
                        students: res.data.students,
                        pk_ids: [res.data.student_info.id],
                        class_student_papers: res.data.class_student_papers,
                        class_student_paper_topics: res.data.class_student_paper_topics,
                        paiming: ppps.length+1
                    });
                }
            })
    };

    handlePkChange=(e)=>{
        console.log(e)
        this.setState({pk_ids:e})
    };

    render() {
        let {student_paper, student_info, paper_models, students,pk_ids,class_student_papers,class_student_paper_topics} = this.state;
        if (!student_paper) {
            return null;
        }

        return (
            <PageContent title={"试卷分析"}>
                <Card bordered={false} size={"small"}>
                    <Row>
                        <Col flex={"auto"}>
                            <Space size={24}>
                                <span>学生姓名：{student_info.s_realname}</span>
                                <span>性别：{student_info.sex === 1 ? "男" : "女"}</span>
                                <span>班级：{student_info.class_info.class}</span>
                            </Space>
                        </Col>
                        <Col>
                            <Space size={24}>
                                <span>试卷名称：{student_paper.name}</span>
                                <span>登记时间：{moment(student_paper.ctime * 1000).format("YYYY-MM-DD")}</span>
                            </Space>
                        </Col>
                    </Row>
                </Card>
                <Card bordered={false} size={"small"}>
                    <Table dataSource={[{
                        score: student_paper.score,
                        student_score: student_paper.student_score,
                        paiming: this.state.paiming
                    }]} rowKey={"id"} pagination={false} size={"small"} bordered>
                        <Column
                            title="总分"
                            dataIndex="score"
                            key="总分"
                        />
                        <Column
                            title="得分"
                            dataIndex="student_score"
                            key="得分"
                        />
                        <Column
                            title="班级排名"
                            dataIndex="paiming"
                            key="班级排名"
                        />
                        {/*<Column*/}
                            {/*title="考试范围"*/}
                            {/*dataIndex="score_ave"*/}
                            {/*key="考试范围"*/}
                        {/*/>*/}
                    </Table>

                </Card>
                <Card bordered={false} size={"small"} title={"试卷分析表"}>
                    <Table dataSource={paper_models} rowKey={"id"} pagination={false} size={"small"} bordered>
                        <Column
                            title="题型"
                            dataIndex="topic_model_name"
                            key="题型"
                        />
                        <Column
                            title="题数"
                            dataIndex="topic_model_due"
                            key="题数"
                        />
                        <Column
                            title="正确题数"
                            dataIndex="right_count"
                            key="正确题数"
                        />
                        <Column
                            title="错误题数"
                            dataIndex="wrong_count"
                            key="错误题数"
                        />
                        <Column
                            title="总分"
                            dataIndex="topic_model_score"
                            key="总分"
                        />
                        <Column
                            title="得分"
                            dataIndex="student_score"
                            key="得分"
                        />
                        <Column
                            title="班级平均"
                            dataIndex="class_per"
                            key="班级平均"
                        />
                        <Column
                            title="失分"
                            dataIndex="lose_score"
                            key="失分"
                        />
                        <Column
                            title="得分率"
                            dataIndex="right_per"
                            key="得分率"
                            render={(right_per) => right_per + "%"}
                        />
                    </Table>

                </Card>

                <Row gutter={12}>
                    <Col span={8}>
                        <Card bordered={false} size={"small"} title={"得分情况"}>

                            <Chart height={200} data={paper_models} autoFit>
                                <Coordinate type="theta" radius={0.75}/>
                                <Tooltip showTitle={false}/>
                                <Axis visible={false}/>
                                <Interval
                                    position="student_score"
                                    adjust="stack"
                                    color="topic_model_name"
                                    style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                    }}
                                    label={['count', {
                                        content: (data) => {
                                            return `${data.topic_model_name}: ${data.student_score}`;
                                        },
                                    }]}
                                />
                            </Chart>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} size={"small"} title={"失分情况"}>

                            <Chart height={200} data={paper_models} autoFit>
                                <Coordinate type="theta" radius={0.75}/>
                                <Tooltip showTitle={false}/>
                                <Axis visible={false}/>
                                <Interval
                                    position="lose_score"
                                    adjust="stack"
                                    color="topic_model_name"
                                    style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                    }}
                                    label={['count', {
                                        content: (data) => {
                                            return `${data.topic_model_name}: ${data.lose_score}`;
                                        },
                                    }]}
                                />
                            </Chart>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} size={"small"} title={"得分率"}>

                            <Chart height={200} data={paper_models} autoFit>
                                <Coordinate type="theta" radius={0.75}/>
                                <Tooltip showTitle={false}/>
                                <Axis visible={false}/>
                                <Interval
                                    position="right_per"
                                    adjust="stack"
                                    color="topic_model_name"
                                    style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                    }}
                                    label={['count', {
                                        content: (data) => {
                                            return `${data.topic_model_name}: ${data.right_per} %`;
                                        },
                                    }]}
                                />
                            </Chart>
                        </Card>
                    </Col>
                </Row>

                <div className={styles["card-container"]}>
                    <Tabs type="card" defaultActiveKey="1" style={{marginBottom: 32}}>
                        <TabPane tab="试卷错题" key="1">
                            <div style={{width: 1000}}>
                                {paper_models.map(paper_model => <div>
                                    {paper_model.topics.filter(item => item.status === 2).map(topic => <div>
                                        <div style={{
                                            fontWeight: "bold",
                                            fontSize: 18,
                                            color: "#000000",
                                            margin: "12px 0"
                                        }}>题目：{paper_model.topic_model_name} &gt;&gt; 第 {topic.topic_number} 题
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: topic.topic_content}}/>
                                    </div>)}
                                </div>)}
                            </div>
                        </TabPane>
                        <TabPane tab="学员PK" key="2">
                            <Checkbox.Group style={{width: '100%'}} value={pk_ids} onChange={this.handlePkChange}>
                                {students.map(student => <Checkbox value={student.id} disabled={student.id === student_info.id}>{student.s_realname}</Checkbox>)}
                            </Checkbox.Group>
                            {/*<Button type={"primary"} disabled={pk_ids.length>3 || pk_ids<2}>学员PK</Button>*/}
                            {(pk_ids.length > 1 && pk_ids.length < 4)
                                ? <Pk key={pk_ids.join(',')}
                                      paper_id={student_paper.paper_id}
                                      student_ids={pk_ids}
                                      paper_models={paper_models}
                                      class_student_papers={class_student_papers}
                                      class_student_paper_topics={class_student_paper_topics}
                                    // class_id2={this.state.pk_class_id}
                                    // class_name1={currentClass.class}
                                    // class_name2={this.state.pk_class_name}
                                />
                            : "最多只能选择3位学员"}

                        </TabPane>
                    </Tabs>
                </div>

            </PageContent>
        );
    }
}

Index.propTypes = {
    id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
