import axios from './rest-axios';

const getAccountInfo = () => axios.get('/v1/account/info');
const postAccountLogin = (login_name, password) => axios.post('/v1/account/login', {login_name, password});
const postChangePassword = (oldpassword, newpassword) => axios.post('/v1/account/changepassword', {
    oldpassword,
    newpassword
});

export {
    getAccountInfo,
    postAccountLogin,
    postChangePassword,
};
