import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    List,
    Avatar,
    Space,
    Select
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDoc} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined, UserOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import {getMytudents} from "../../lib/api_students";
import styles from './list.module.css';
import GradeName from '../../components/grade-name';
import SchoolSelect from './school-select';

const {Option} = Select;

const {CheckableTag} = Tag;

const StatusName = ({status}) => {
    switch (status) {
        case 1:
            return <Tag color="#87d068" style={{fontSize: 20, lineHeight: "30px"}}>在读</Tag>;
        case 2:
            return <Tag color="#f50" style={{fontSize: 20, lineHeight: "30px"}}>停课</Tag>;
        case 3:
            return <Tag color="#666" style={{fontSize: 20, lineHeight: "30px"}}>结课</Tag>;
        case 4:
            return <Tag color="#666" style={{fontSize: 20, lineHeight: "30px"}}>退费</Tag>;
    }
};


class MyStudents extends Component {
    state = {
        list: [],
        filter: {}
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getMytudents()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data,
                    })
                }
            })
    };
    handleGradeChange = (value) => {
        // console.log(value)
        let {filter} = this.state;
        filter.grade = value;
        this.setState({filter: filter})
    };

    handleStatusChange = (value) => {
        console.log(value)
        let {filter} = this.state;
        filter.status = value;
        this.setState({filter: filter})
    };
    handleSchoolChange= (value) => {
        console.log(value)
        let {filter} = this.state;
        filter.school = value;
        this.setState({filter: filter})
    };

    render() {
        let {list, filter} = this.state;

        if(filter.status){
            list = list.filter(item=> item.status_0 === filter.status)
        }
        if(filter.grade){
            list = list.filter(item=> item.grade === filter.grade)
        }
        if(filter.school){
            list = list.filter(item=> item.school_id === filter.school)
        }

        return (
            <PageContent title={"我的学员"}>
                <Card bordered={false} size={"small"}>
                    <Space>
                        <div>
                            年级：
                            <Select style={{width: 120}}
                                    onChange={this.handleGradeChange}
                                    allowClear
                            >
                                {config.grade.map(item => <Option value={item.id}>{item.name}</Option>)}
                            </Select>

                        </div>
                        <div>
                            学校：
                            <SchoolSelect onChange={this.handleSchoolChange}  />
                        </div>
                        <div>
                            状态：
                            <Select style={{width: 120}}
                                    onChange={this.handleStatusChange}
                                    allowClear
                            >
                                <Option value={1}>在读</Option>
                                <Option value={2}>停课</Option>
                                <Option value={3}>结课</Option>
                                <Option value={4}>退费</Option>
                            </Select>
                        </div>
                        <div>
                            T学管师：
                            <Select style={{width: 120}} allowClear>
                            </Select>
                        </div>
                    </Space>
                </Card>
                <List
                    // className="demo-loadmore-list"
                    // loading={initLoading}
                    itemLayout="horizontal"
                    // loadMore={loadMore}
                    dataSource={list}
                    renderItem={item => (
                        <Card bordered={false} size={"small"}>
                            <List.Item
                                style={{padding: 0}}
                                className={styles.item}
                                actions={[<StatusName status={item.status_0}/>]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar size={48} icon={<UserOutlined/>}/>
                                    }
                                    title={item.realname}
                                />
                                <Space size={36}>
                                    <div style={{width: 200}}>
                                        联系人：{item.parent_name}<br/>
                                        年级：<GradeName id={item.grade}/>
                                    </div>
                                    <div style={{width: 200}}>
                                        联系电话：{item.mobile_0}<br/>
                                        已学课时：{item.study_times}/{item.total_times}
                                    </div>

                                    <div style={{width: 200}}>
                                        下次课时间：T<br/>
                                        班主任：T
                                    </div>
                                </Space>
                            </List.Item>
                        </Card>
                    )}
                />
            </PageContent>
        )
    }
}


MyStudents.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyStudents));


