import axios from './rest-axios';

const postSearch = (grade, school_id, key, page, pagesize) => axios.post('/v1/kehu/search', {
    grade,
    school_id,
    key,
    page,
    pagesize
});
const putStudent = (model)=>axios.put(`/v1/kehu/${model.id}`, model);

export {
    postSearch,
    putStudent
};
