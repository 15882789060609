import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getBindStudentsInfo, postBindStudentsInfo,deleteBindStudentsInfo} from "../../lib/api_teachers";
import {getStudents} from "../../lib/api_students";
import {
    Table,
    Divider,
    Card,
    Button,
    Select
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import UserEditModal from './teachers-edit-modal';
import UserResetPasswordModal from './teachers-resetpassword-modal';
import UserBindStudentModal from './teachers-bindstudent-modal';
import config from '../../config';

const {Column} = Table;


class BindStudents extends Component {
    state = {
        list: [],
        subject: 0,
        student_id: 0,
        students: [],
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getBindStudentsInfo(this.props.id)
            .then((result) => {
                // console.log(result)
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                    // this.setState({
                    //     users: result.data.users,
                    //     roles: result.data.roles
                    // })
                }
            });
        getStudents()
            .then(result => {
                if (result.code === 0) {
                    this.setState({
                        students: result.data
                    })
                    // this.setState({
                    //     users: result.data.users,
                    //     roles: result.data.roles
                    // })
                }
            })
    };

    onChange = (value) => {
        console.log(`selected ${value}`);
    };

    onBlur = () => {
        console.log('blur');
    };

    onFocus = () => {
        console.log('focus');
    };

    onSearch = (val) => {
        console.log('search:', val);
    };

    handleBind = () => {
        let {student_id, subject} = this.state;
        if (student_id && subject) {
            postBindStudentsInfo({
                teacher_id: this.props.id,
                student_id,
                subject
            }).then(res=>{
                if(res.code === 0){
                    this.fetchData()
                }
            })
        }
    };
    handleDelete = (id)=>{
        deleteBindStudentsInfo(id)
            .then(res=>{
                if(res.code === 0){
                    this.fetchData()
                }
            })
    };

    render() {
        let {list, students} = this.state;
        return (
            <PageContent title={"学生绑定"}>
                <Card bordered={false}>

                    <Select
                        showSearch
                        style={{width: 200}}
                        placeholder="选择学生"
                        optionFilterProp="children"
                        onChange={(value) => this.setState({student_id: value})}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {students.map(item => <Select.Option value={item.id}>{item.realname}</Select.Option>)}
                    </Select>
                    <Select
                        style={{width: 200}}
                        placeholder="选择科目"
                        optionFilterProp="children"
                        onChange={(value) => this.setState({subject: value})}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {config.subjects.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                    </Select>

                    <Button type={"primary"} onClick={this.handleBind}>添加绑定</Button>

                </Card>
                <Card bordered={false}>

                    <Table dataSource={list} rowKey={"id"} pagination={false} size={"small"}>
                        <Column
                            title="序号"
                            dataIndex="id"
                            key="id"
                            width={80}
                        />

                        <Column
                            title="学生姓名"
                            dataIndex="realname"
                            key="realname"
                        />
                        <Column
                            title="账号"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="科目"
                            dataIndex="subject"
                            key="subject"
                            render={(subject) => {
                                let obj = config.subjects.find(item => item.id === subject);
                                if (obj) {
                                    return obj.name;
                                } else {
                                    return null;
                                }
                            }}
                        />
                        <Column
                            title="操作"
                            key="action"
                            width={100}
                            render={(text, record) => (
                                <div>
                                    <a onClick={() => {
                                        this.handleDelete(record.id)
                                    }}>删除</a>
                                </div>
                            )}
                        />
                    </Table>

                </Card>

            </PageContent>
        )
    }
}


BindStudents.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BindStudents));


