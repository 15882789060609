import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PageContent from '../../components/page-content/page-content';
import {getTeachingDocById} from "../../lib/api_teaching_doc";
import Preview from './preview-2';
import {Button} from 'antd';
import printJS from 'print-js'

class PreviewPage extends Component {
    state = {
        data: null
    };

    componentDidMount() {
        this.fetchData();
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getTeachingDocById(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    console.log(result);
                    this.setState({
                        data: Object.assign(JSON.parse(result.data.config), {id: this.props.id}),
                        // step: 0,
                    })
                }
            })
    };

    handlePrint = ()=>{
        printJS({
            printable: "print",
            type: "html",
            targetStyles:"*",
        })
        // printJS('print', 'html');
    }

    render() {
        const {data} = this.state;
        return (
            <PageContent
                title={"教案预览"}
                routes={[
                    {
                        path: '//teaching_doc',
                        breadcrumbName: '教案中心',
                    },
                    {
                        path: `//${this.props.id}`,
                        breadcrumbName: '教案预览',
                    },
                ]}
                extra={<Button onClick={this.handlePrint}>打印</Button>}
            >
                <div id="print">
                {data && <Preview data={data} />}
                </div>

            </PageContent>
        )
    }
}


PreviewPage.propTypes = {
    id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewPage));


