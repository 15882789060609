import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {
    Card,
    Table,
    Divider,
    Select,
    Space,
    Tabs,
    Descriptions,
    Typography,
    Row,
    Col,
    Button,
    Tooltip as AntdTooltip,
    Tag
} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";
import styles from './index.module.css';
import {getPaperInfo} from "../../../lib/api_school_papers";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";
import Pk from './pk';

let {Column} = Table;
let {Option} = Select;
let {TabPane} = Tabs;
const {Text} = Typography;


class Index extends Component {
    state = {
        paper_id: 0,
        books: [],
        list: [],
        papers: [],
        // currentPaper: null,
        chapters1: [],
        chapters2: [],
        chapters1_id: 0,
        chapters2_id: 0,
        student_papers: [], // 学生试卷
        students: [], // 学员列表
        topic_models: [],
        student_paper_topics: [], // 获取学生具体题目登记情况
        group_paper_topics: [], // 年级数据
        classes: [], // 所有的同年级班级
        pk_class_id: 0,
    };

    // componentWillReceiveProps(nextprops){
    //     if (nextprops.account.paperlist && nextprops.account.paperlist.length > 0) {
    //         this.filterPapers(nextprops.account.paperlist[0].paper_id);
    //         // this.setState({
    //         //     book_id: this.props.account.booklist[0].book_id
    //         // }, this.fetchStudents)
    //     }
    // }

    componentDidMount() {
        if (this.props.account.paperlist && this.props.account.paperlist.length > 0) {
            this.filterPapers(this.props.account.paperlist[0].paper_id);
            // this.setState({
            //     book_id: this.props.account.booklist[0].book_id
            // }, this.fetchStudents)
        }
        // this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchClassBooks = () => {
        getClassBooks(this.props.currentClass.class_id)
            .then((result) => {
                if (result.code === 0) {
                    let book_id = 0;
                    if (result.data && result.data.length > 0) {
                        book_id = result.data[0].book_id;
                    }
                    this.setState({
                        books: result.data,
                        book_id
                    }, this.fetchStudents)
                }
            })
    };

    fetchPaperInfo = () => {
        if (this.state.paper_id > 0) {
            getPaperInfo(this.props.currentClass.class_id, this.state.paper_id)
                .then((result) => {
                    if (result.code === 0) {
                        // result.data.forEach(item => {
                        //     let py = pinyin(item.s_realname);
                        //     if (py && py.length > 0) {
                        //         console.log(py, py[0][0][0]);
                        //         item.py = py[0][0][0].toUpperCase();
                        //     }
                        //     // console.log(py);
                        // });
                        // const sortedData = result.data.sort((a, b) => {
                        //     return a.py.localeCompare(b.py);
                        // });

                        result.data.students.forEach(item => {
                            let py = pinyin(item.s_realname);
                            if (py && py.length > 0) {
                                // console.log(py, py[0][0][0]);
                                item.py = py[0][0][0].toUpperCase();
                            }
                            // console.log(py);
                        });
                        const sortedData = result.data.students.sort((a, b) => {
                            return a.py.localeCompare(b.py);
                        });

                        this.setState({
                            student_papers: result.data.student_papers,
                            students: sortedData,
                            topic_models: result.data.topic_models,
                            student_paper_topics: result.data.student_paper_topics,
                            group_paper_topics: result.data.group_paper_topics,
                            classes: result.data.classes
                        })
                    }
                })
        }

    };

    fetchData = () => {
        // this.fetchClassBooks();
    };

    filterPapers = (key) => {
        console.log(key);
        this.setState({
            paper_id: key,
            chapters1_id: 0,
            chapters2_id: 0,
        }, this.fetchPaperInfo)
    };


    renderPaperInfo = () => {
        let {paper_id, chapters1_id, chapters2_id, chapters1, chapters2, student_papers, students, topic_models, student_paper_topics, group_paper_topics, classes} = this.state;
        let {currentClass} = this.props;
        let {paperlist, chapters} = this.props.account;
        let currentPaper = paperlist.find(item => item.paper_id === paper_id);
        if (!currentPaper) {
            return null
        }
        if (topic_models.length === 0) {
            return null
        }
        let dj_score_total = 0;  // 班级总分
        let dj_student_count = 0;  // 登记人数
        let max_score = -1; // 最高分
        let min_score = -1; // 最低分
        let stat_score = []; // 分值范围统计
        stat_score.push({
            max: currentPaper.score * 1,
            min: parseInt(currentPaper.score * 0.9),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.9),
            min: parseInt(currentPaper.score * 0.8),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.8),
            min: parseInt(currentPaper.score * 0.7),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.7),
            min: parseInt(currentPaper.score * 0.6),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.6),
            min: 0,
            students: []
        });

        console.log(stat_score);

        student_papers.filter(item => item.status === 2).forEach(item => {
            dj_score_total += item.student_score;
            dj_student_count += 1;
            if (item.student_score > max_score || max_score === -1) {
                max_score = item.student_score;
            }
            if (item.student_score < min_score || min_score === -1) {
                min_score = item.student_score;
            }
            // let level = stat_score.find(ss=> ss.max >= item.student_score && ss.min <= item.student_score);
            let levelindex = stat_score.findIndex(ss => ss.max >= item.student_score && ss.min <= item.student_score);
            let level = stat_score[levelindex];
            if (level) {
                console.log(level);
                let student = students.find(stu => stu.id === item.student_id);
                if (student) {
                    level.students.push(student.s_realname)
                }
                student.dengdi = "";
                if (levelindex === 0) {
                    student.dengdi = "优秀";
                } else if (levelindex === 1 || levelindex === 2) {
                    student.dengdi = "良好";
                } else if (levelindex === 3) {
                    student.dengdi = "及格";
                } else if (levelindex === 4) {
                    student.dengdi = "不及格";
                }
            }
        });

        let dj_ave_score = dj_score_total / dj_student_count;

        let data2 = [];
        topic_models.forEach(tm => {
            let class_model_ave = 0; // 该类型题目下的班级平均分
            let group_model_ave = 0; // 年级平均

            tm.topics.forEach(topic => {
                let spt = student_paper_topics.filter(item => item.paper_id === paper_id && item.topic_id === topic.topic_id);
                let total_score = 0;
                let total_count = 0;
                topic.error_count = 0;
                topic.error_students = [];
                spt.forEach(item => {
                    total_score += item.score;
                    total_count += 1;
                    if (item.status === 2) {
                        topic.error_count += 1;
                        let student = students.find(stu => stu.id === item.student_id);
                        if (student) {
                            topic.error_students.push({
                                name: student.s_realname,
                                score: item.score
                            });
                        }
                    }
                });

                topic.class_ave = (total_score / total_count).toFixed(1);
                class_model_ave += (total_score / total_count);

                // 计算年级平均
                let gpt_total_score = 0;
                let gpt_total_count = 0;
                let gpt = group_paper_topics.filter(item => item.paper_id === paper_id && item.topic_id === topic.topic_id);
                gpt.forEach(item => {
                    gpt_total_score += item.score;
                    gpt_total_count += 1;
                });
                topic.group_ave = (gpt_total_score / gpt_total_count).toFixed(1);
                group_model_ave += (gpt_total_score / gpt_total_count);
            });
            console.log(class_model_ave);

            data2.push({
                label: tm.topic_model_name,
                年级平均: Math.round(group_model_ave * 10) / 10,
                班级平均: Math.round(class_model_ave * 10) / 10
            });
        });

        console.log(data2)
        const data = [
            {
                label: "简答题",
                年级平均: 27,
                班级平均: 28
            },
            {
                label: "填空题",
                年级平均: 25,
                班级平均: 24
            },
            {
                label: "选择题",
                年级平均: 28,
                班级平均: 29.5
            }
        ];
        const ds = new DataSet();
        const dv = ds.createView().source(data2);
        dv.transform({
            type: "fold",
            fields: ["年级平均", "班级平均"],
            // 展开字段集
            key: "type",
            // key字段
            value: "value" // value字段
        });


        return (
            <>
            <Card bordered={false} size={"small"} title={"基本信息"}>
                <Descriptions column={2}>
                    <Descriptions.Item label="试卷名称">{currentPaper.name}</Descriptions.Item>
                    <Descriptions.Item label="科目"><SubjectName id={currentPaper.subject_id}/></Descriptions.Item>
                    <Descriptions.Item label="试卷类别">{currentPaper.category}</Descriptions.Item>
                    <Descriptions.Item label="试卷总分">{currentPaper.score}</Descriptions.Item>
                    <Descriptions.Item label="班级平均">{dj_ave_score.toFixed(1)}</Descriptions.Item>
                    <Descriptions.Item label="班级最高">{max_score === -1 ? "-" : max_score}</Descriptions.Item>
                    <Descriptions.Item label="班级最低">{min_score === -1 ? "-" : min_score}</Descriptions.Item>
                    <Descriptions.Item label="登记人数">{dj_student_count}人</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card bordered={false} size={"small"} title={"试卷概况"}>

                <Row gutter={24}>
                    <Col span={12}>
                        <Chart height={200} data={dv} forceFit>
                            <Legend/>
                            <Coord transpose scale={[1, -1]}/>
                            <Axis
                                name="label"
                                label={{
                                    offset: 12
                                }}
                            />
                            <Axis name="value" position={"right"}/>
                            <Tooltip/>
                            <Geom
                                type="interval"
                                position="label*value"
                                color={"type"}
                                adjust={[
                                    {
                                        type: "dodge",
                                        marginRatio: 1 / 32
                                    }
                                ]}
                            />
                        </Chart>
                    </Col>
                    <Col span={12}>
                        <Table dataSource={stat_score} rowKey={"id"} pagination={false} size={"small"} bordered>
                            <Column
                                title="分值范围"
                                key="level"
                                width={80}
                                render={(record) => {
                                    return `${record.min}~${record.max}`
                                }}
                            />
                            <Column
                                title="人数"
                                dataIndex="students"
                                width={60}
                                key="students_count"
                                render={(students) => students.length}
                            />
                            <Column
                                title="名单"
                                dataIndex="students"
                                key="students"
                                render={(students) => students.join(',')}
                            />
                        </Table>
                    </Col>
                </Row>
            </Card>
            <div className={styles["card-container"]}>

                <Tabs type="card" defaultActiveKey="1" style={{marginBottom: 32}}>
                    <TabPane tab="学员情况" key="1">
                        <Table dataSource={students} rowKey={"id"} pagination={false} size={"small"} bordered
                               style={{width: 400}}>
                            <Column
                                title=""
                                dataIndex="py"
                                key="py"
                                width={30}
                            />
                            <Column
                                title="真实姓名"
                                dataIndex="s_realname"
                                key="s_realname"
                                // width={100}
                            />
                            <Column
                                title="性别"
                                dataIndex="sex"
                                key="sex"
                                render={(sex) => {
                                    return sex === 1 ? "男" : "女"
                                }}
                                // width={100}
                            />
                            <Column
                                title="登记情况"
                                key="status"
                                // width={100}
                                render={(record) => {
                                    let sp = student_papers.find(item => item.student_id === record.id);
                                    if (sp) {
                                        switch (sp.status) {
                                            case 0:
                                                return <Text type="danger">未登记</Text>;
                                            case 1:
                                                return "登记中";
                                            case 2:
                                                return "登记完成";
                                        }
                                    } else {
                                        return <Text type="danger">未登记</Text>;
                                    }

                                }}
                            />
                            <Column
                                title="成绩"
                                key="student_score"
                                align={"right"}
                                // width={100}
                                render={(record) => {
                                    let sp = student_papers.find(item => item.student_id === record.id);
                                    if (sp) {
                                        if (sp.student_score > dj_ave_score) {
                                            return <span>{sp.student_score}<CaretUpOutlined
                                                style={{color: "red"}}/></span>
                                        } else if (sp.student_score < dj_ave_score) {
                                            return <span>{sp.student_score}<CaretDownOutlined style={{color: "green"}}/></span>
                                        } else {
                                            return sp.student_score;

                                        }
                                    } else {
                                        return "-";
                                    }
                                }}
                            />
                            <Column
                                title="等第"
                                dataIndex="dengdi"
                                key="dengdi"
                            />
                        </Table>
                    </TabPane>
                    <TabPane tab="详情分析" key="2">
                        <div>
                            {topic_models.map(tm => <div>
                                <Table dataSource={tm.topics}
                                       rowKey={"id"}
                                       pagination={false}
                                       size={"small"}
                                       bordered
                                       title={() => tm.topic_model_name}
                                >
                                    <Column
                                        title="题号"
                                        dataIndex="topic_number"
                                        key="topic_number"
                                    />
                                    <Column
                                        title="题目分值"
                                        dataIndex="topic_score"
                                        width={80}
                                        key="topic_score"
                                    />
                                    {/*<Column*/}
                                    {/*title="难度系数"*/}
                                    {/*dataIndex="bbb"*/}
                                    {/*width={80}*/}
                                    {/*key="bbb"*/}
                                    {/*/>*/}
                                    <Column
                                        title="题目内容"
                                        dataIndex="topic_content"
                                        key="topic_content"
                                        render={(topic_content) => <div
                                            dangerouslySetInnerHTML={{__html: topic_content}}/>}
                                    />
                                    <Column
                                        title="班级平均"
                                        dataIndex="class_ave"
                                        width={80}
                                        key="bjpj"
                                        render={(class_ave, record) => {
                                            if (class_ave > record.group_ave) {
                                                return <span>{class_ave}<CaretUpOutlined style={{color: "red"}}/></span>
                                            } else if (class_ave < record.group_ave) {
                                                return <span>{class_ave}<CaretDownOutlined
                                                    style={{color: "green"}}/></span>
                                            } else {
                                                return class_ave;
                                            }
                                        }}
                                    />
                                    <Column
                                        title="年级平均"
                                        dataIndex="group_ave"
                                        width={80}
                                        key="group_ave"
                                    />
                                    <Column
                                        title="错题人数"
                                        dataIndex="error_count"
                                        width={80}
                                        key="ctrs"
                                    />
                                    <Column
                                        title="错题名单"
                                        dataIndex="error_students"
                                        key="ctmd"
                                        width={300}
                                        render={(error_students) => {
                                            console.log(error_students);
                                            return <div>{error_students.map(item => <AntdTooltip
                                                title={item.score}><Tag>{item.name}</Tag></AntdTooltip>)}
                                            </div>
                                        }}
                                    />
                                </Table>
                            </div>)}
                        </div>
                    </TabPane>
                    <TabPane tab="班级PK" key="3">
                        PK：
                        <Select defaultValue="" style={{ width: 120 }} onChange={(value) => this.setState({
                            pk_class_id: value,
                            pk_class_name: classes.find(item=> item.id === value).class
                        })}>
                            {classes.map(item => <Option value={item.id}>
                                {item.class}
                            </Option>)}
                        </Select>

                        {/*{classes.map(item => <div>*/}
                            {/*{item.class}*/}
                            {/*<Button onClick={() => this.setState({*/}
                                {/*pk_class_id: item.id*/}
                            {/*})}>PK</Button>*/}
                        {/*</div>)}*/}
                        <Pk key={`${paper_id},${currentClass.class_id},${this.state.pk_class_id}`}
                            paper_id={paper_id}
                            class_id1={currentClass.class_id}
                            class_id2={this.state.pk_class_id}
                            class_name1={currentClass.class}
                            class_name2={this.state.pk_class_name}
                        />
                    </TabPane>
                </Tabs>
            </div>
            </>
        )
    }

    render() {
        let {paper_id, chapters1_id, chapters2_id, chapters1, chapters2, student_papers} = this.state;
        let {currentClass} = this.props;
        let {paperlist, chapters} = this.props.account;
        let currentPaper = paperlist.find(item => item.paper_id === paper_id);
        let papers = paperlist.filter(item => item.class_id === currentClass.class_id);

        // 计算平均


        return (
            <PageContent title={"试卷分析"}>
                <Card bordered={false} size={"small"}>
                    <Space>
                        试卷：
                        <Select defaultValue={0} value={paper_id} style={{width: 400}} onChange={this.filterPapers}>
                            {papers.map(item => <Option value={item.paper_id}>{item.name}</Option>)}
                        </Select>
                    </Space>
                </Card>
                {this.renderPaperInfo()}

            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
