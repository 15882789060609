import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Button,
    Space,
    Select,
    Table,
    Input,
    Divider
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import GradeName from '../../components/grade-name';
import SchoolSelect from '../mystudents/school-select';
import {postSearch} from "../../lib/api_kehu";
import moment from 'moment';
import TeachersSelectModal from './teachers-select-modal';

const {Column} = Table;


class Kehu extends Component {
    state = {
        list: [],
        grade: 0,
        school_id: 0,
        key: "",
        pagination: {
            current: 1,
            pageSize: 20,
        }
    };

    componentDidMount() {
        this.fetchData(this.state.pagination)
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = (pagination) => {
        let {grade, school_id, key} = this.state;
        this.setState({loading: true});

        postSearch(grade, school_id, key, pagination.current, pagination.pageSize)
            .then((result) => {
                if (result.code === 0) {
                    // console.log()
                    this.setState({
                        list: result.data.list,
                        pagination: {
                            ...pagination,
                            total: result.data.total
                        }
                    })
                }
            })
            .finally(() => {
                this.setState({loading: false});
            });

    };


    onSearch = () => {
        this.fetchData(this.state.pagination)
    };

    handleTableChange = (pagination) => {
        this.fetchData(
            pagination,
        );
    };


    render() {

        const {list, pagination, loading} = this.state;
        return (
            <PageContent title={"客户管理"}>
                <Card bordered={false} size={"small"}>
                    <Space>
                        <div>
                            年级：
                            <Select
                                style={{width: 200}}
                                allowClear={false}
                                placeholder="选择年级"
                                optionFilterProp="children"
                                value={this.state.grade}
                                onChange={(value) => this.setState({grade: value})}
                                // filterOption={(input, option) =>
                                //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }
                            >
                                <Select.Option value={0}>全部</Select.Option>
                                {config.grade.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                            </Select>
                        </div>
                        <div>
                            学校：
                            <SchoolSelect onChange={(id) => {
                                this.setState({school_id: id});
                            }}/>
                        </div>
                        <Input onChange={(e) => this.setState({key: e.target.value})}/>

                        <Button type="primary" onClick={this.onSearch}>搜索</Button>
                    </Space>
                </Card>
                <Table dataSource={list} rowKey={"id"}
                       pagination={pagination}
                       size={"small"}
                       loading={loading}
                       onChange={this.handleTableChange}
                >
                    <Column
                        title="序号"
                        key="序号"
                        render={(text, record, index) => {
                            return (pagination.current - 1) * pagination.pageSize + index + 1
                        }}
                    />
                    <Column
                        title="姓名"
                        dataIndex="s_realname"
                        key="s_realname"
                    />
                    <Column
                        title="联系电话"
                        dataIndex="phone"
                        key="phone"
                    />
                    <Column
                        title="学校"
                        dataIndex="school"
                        key="学校"
                        // render={(grade)=><SchoolName id={parseInt(grade)}/>}
                    />
                    <Column
                        title="年级"
                        dataIndex="grade"
                        key="年级"
                        render={(grade) => <GradeName id={parseInt(grade)}/>}
                    />
                    <Column
                        title="跟进时间"
                        dataIndex="contact_time"
                        key="跟进时间"
                        render={(contact_time) => {
                            if (!contact_time) return "";
                            return moment(contact_time).format("YYYY-MM-DD")
                        }}
                    />
                    <Column
                        title="跟进内容"
                        dataIndex="content"
                        key="跟进内容"
                    />
                    <Column
                        title="教师"
                        dataIndex="teacher_name"
                        key="教师"
                    />
                    <Column
                        title="操作"
                        key="操作"
                        render={(record) => (
                            <div>
                                <a onClick={() => this.setState({editItem: record})}>修改教师</a>
                                <Divider type="vertical" />
                                <Link to={`/zixun/kehu/${record.id}`}>查看详情</Link>
                            </div>
                        )}
                    />
                </Table>
                {this.state.editItem &&
                <TeachersSelectModal
                    data={this.state.editItem}
                    onCancel={() => this.setState({editItem: null})}
                    onOk={() => {
                        this.setState({editItem: null});
                        this.fetchData(this.state.pagination)
                    }}
                />}

            </PageContent>
        )
    }
}


Kehu.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Kehu));


