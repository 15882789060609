import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Button,
    Space,
    Select,
    Table,
    Input,
    Descriptions,
    Modal,
    Form,
    DatePicker,
    message,
    Tag,
    Row,
    Col,
    Tabs,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import GradeName from '../../components/grade-name';
import SchoolSelect from '../mystudents/school-select';
import {getStudentInfo, getStudentReport} from '../../lib/api_students';
import RelationTypeName from '../../components/relation_type_name';
import {postContact} from "../../lib/api_contact";
import moment from 'moment';
import ReportPrint from './report-print';

const {Column} = Table;
const {CheckableTag} = Tag;


class KehuDetail extends Component {
    state = {
        student: null,
        contactAdd: false,
        grade: 16,
        subject_id: 3,
        report: null,
    };
    formRef = React.createRef();

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getStudentInfo(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        student: result.data
                    })
                }
            })
        this.fetchReport()

    };

    fetchReport = () => {
        getStudentReport(this.props.id, this.state.grade, this.state.subject_id)
            .then(res => {
                if (res.code === 0) {
                    this.setState({
                        report: res.data
                    })
                }
            })
    }

    onFinish = (values) => {
        console.log(values)
        postContact({
            ...values,
            contact_time: values.contact_time.format("YYYY-MM-DD HH:mm"),
            student_id: this.props.id
        }).then(res => {
            if (res.code === 0) {
                this.setState({contactAdd: false});
                this.fetchData();
            } else {
                message.error(res.message);
            }
        })
    };


    render() {
        const {student} = this.state;
        if (!student) return null;
        return (
            <PageContent title={"学员详情"}>
                <Card bordered={false} size={"small"}>
                    <Descriptions title="学生信息">
                        <Descriptions.Item label="学生姓名">{student.s_realname}</Descriptions.Item>
                        <Descriptions.Item label="就读学校">{student.school}</Descriptions.Item>
                        <Descriptions.Item label="年级"><GradeName id={parseInt(student.grade)}/></Descriptions.Item>
                        <Descriptions.Item label="家长姓名">{student.p_realname}</Descriptions.Item>
                        <Descriptions.Item label="关系"><RelationTypeName id={student.relation_type}/></Descriptions.Item>
                        <Descriptions.Item label="联系电话">{student.phone}</Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card bordered={false} size={"small"} title={"跟进记录"}
                      extra={<Button onClick={() => this.setState({contactAdd: true})}>添加</Button>}>
                    <div style={{maxHeight: 300, overflow: "auto"}}>
                        <Table dataSource={student.contacts} rowKey={"id"} pagination={false} size={"small"}>
                            <Column
                                title="时间"
                                dataIndex="contact_time"
                                key="时间"
                                render={(contact_time) => {
                                    return moment(contact_time).format("YYYY-MM-DD HH:mm")
                                }}
                                width={150}
                            />

                            <Column
                                title="沟通对象"
                                dataIndex="target"
                                key="沟通对象"
                                width={80}
                            />
                            <Column
                                title="跟进内容"
                                dataIndex="content"
                                key="跟进内容"
                            />

                            <Column
                                title="负责人"
                                dataIndex="fuze_name"
                                key="负责人"
                                width={80}
                            />

                        </Table>

                    </div>
                </Card>
                <Card bordered={false} size={"small"} title={"学情分析"}>
                    <Row style={{flexWrap: "nowrap"}}>

                        <Col flex={"40px"}>年级:</Col>
                        <Col flex={"auto"}>
                            {config.grade2.map(tag => (
                                <CheckableTag
                                    key={tag.id}
                                    checked={this.state.grade === tag.id}
                                    onChange={checked => {
                                        this.setState({grade: tag.id}, () => {
                                            this.fetchReport()
                                        });
                                    }}
                                >
                                    {tag.name}
                                </CheckableTag>
                            ))}

                        </Col>
                    </Row>

                    <Row style={{flexWrap: "nowrap"}}>
                        <Col flex={"40px"}>
                            科目:
                        </Col>
                        <Col flex={"auto"}>
                            {config.subjects.map(tag => (
                                <CheckableTag
                                    key={tag.id}
                                    checked={this.state.subject_id === tag.id}
                                    onChange={checked => this.setState({subject_id: tag.id}, this.fetchReport)}
                                >
                                    {tag.name}
                                </CheckableTag>
                            ))}

                        </Col>
                    </Row>

                    {this.state.report &&
                    <div>
                        <Table
                            bordered
                            dataSource={this.state.report.chapters}
                            rowKey={"id"}
                            pagination={false}
                            size={"small"}>

                            <Column
                                title="章节名称"
                                dataIndex="name"
                                key="章节名称"
                            />
                            <Column
                                title="知识点数量"
                                key="知识点数量"
                                render={(record) => {
                                    return record.points.length;
                                }}
                            />
                            <Column
                                title="录入总题数"
                                key="录入总题数"
                                render={(record) => {
                                    return record.right_count + record.wrong_count;
                                }}
                            />
                            <Column
                                title="正确题数"
                                dataIndex="right_count"
                                key="正确题数"
                            />
                            <Column
                                title="困难题正确率"
                                key="困难题正确率"
                                render={(record) => {
                                    if (record.level1_right_count + record.level1_wrong_count === 0) return "-";
                                    return parseInt(record.level1_right_count * 100 / (record.level1_right_count + record.level1_wrong_count)) + "%";
                                }}
                            />
                            <Column
                                title="较难题正确率"
                                key="较难题正确率"
                                render={(record) => {
                                    if (record.level2_right_count + record.level2_wrong_count === 0) return "-";
                                    return parseInt(record.level2_right_count * 100 / (record.level2_right_count + record.level2_wrong_count)) + "%";
                                }}
                            />
                            <Column
                                title="中档题正确率"
                                key="中档题正确率"
                                render={(record) => {
                                    if (record.level3_right_count + record.level3_wrong_count === 0) return "-";
                                    return parseInt(record.level3_right_count * 100 / (record.level3_right_count + record.level3_wrong_count)) + "%";
                                }}
                            />
                            <Column
                                title="较易题正确率"
                                key="较易题正确率"
                                render={(record) => {
                                    if (record.level4_right_count + record.level4_wrong_count === 0) return "-";
                                    return parseInt(record.level4_right_count * 100 / (record.level4_right_count + record.level4_wrong_count)) + "%";
                                }}
                            />
                            <Column
                                title="容易题正确率"
                                key="容易题正确率"
                                render={(record) => {
                                    if (record.level5_right_count + record.level5_wrong_count === 0) return "-";
                                    return parseInt(record.level5_right_count * 100 / (record.level5_right_count + record.level5_wrong_count)) + "%";
                                }}
                            />
                        </Table>
                        <Tabs defaultActiveKey="1">
                            {this.state.report.chapters.map(chapter =>
                                <Tabs.TabPane tab={chapter.name} key={chapter.id + ""}>
                                    <Button onClick={() => {
                                        chapter.showReport = true;
                                        this.setState({})
                                    }}>打印报告单</Button>
                                    <Table
                                        bordered
                                        dataSource={chapter.points}
                                        columns={[{
                                            title: "知识点名称",
                                            dataIndex: "point_main_name",
                                            key: "知识点名称",
                                        }, {
                                            title: "录入题数",
                                            key: "录入题数",
                                            render: (record) => {
                                                return record.right_count + record.wrong_count;
                                            }
                                        }, {
                                            title: "正确题数",
                                            children: [{
                                                title: "学生数据",
                                                dataIndex: "right_count",
                                                key: "学生数据",
                                            }, {
                                                title: "参考值",
                                                key: "参考值",
                                                render: (record) => {
                                                    if (record.grade_student_count === 0) return "-";
                                                    return parseInt(record.grade_right_count / record.grade_student_count);
                                                }
                                            }]
                                        }, {
                                            title: "正确率",
                                            children: [{
                                                title: "学生数据",
                                                key: "学生数据",
                                                render: (record) => {
                                                    if (record.right_count + record.wrong_count === 0) return "-";
                                                    return parseInt(record.right_count * 100 / (record.right_count + record.wrong_count)) + "%";
                                                },
                                            }, {
                                                title: "参考值",
                                                key: "参考值",
                                                render: (record) => {
                                                    if (record.grade_right_count + record.grade_wrong_count === 0) return "-";
                                                    return parseInt(record.grade_right_count * 100 / (record.grade_right_count + record.grade_wrong_count)) + "%";
                                                },
                                            }]
                                        }, {
                                            title: "困难题正确率",
                                            children: [{
                                                title: "学生数据",
                                                key: "学生数据1",
                                                render: (record) => {
                                                    if (record.level1_right_count + record.level1_wrong_count === 0) return "-";
                                                    return parseInt(record.level1_right_count * 100 / (record.level1_right_count + record.level1_wrong_count)) + "%";
                                                },
                                            }, {
                                                title: "参考值",
                                                key: "参考值1",
                                                render: (record) => {
                                                    if (record.grade_level1_right_count + record.grade_level1_wrong_count === 0) return "-";
                                                    return parseInt(record.grade_level1_right_count * 100 / (record.grade_level1_right_count + record.grade_level1_wrong_count)) + "%";
                                                },
                                            }]
                                        }, {
                                            title: "较难题正确率",
                                            children: [{
                                                title: "学生数据",
                                                key: "学生数据2",
                                                render: (record) => {
                                                    if (record.level2_right_count + record.level2_wrong_count === 0) return "-";
                                                    return parseInt(record.level2_right_count * 100 / (record.level2_right_count + record.level2_wrong_count)) + "%";
                                                },
                                            }, {
                                                title: "参考值",
                                                key: "参考值2",
                                                render: (record) => {
                                                    if (record.grade_level2_right_count + record.grade_level2_wrong_count === 0) return "-";
                                                    return parseInt(record.grade_level2_right_count * 100 / (record.grade_level2_right_count + record.grade_level2_wrong_count)) + "%";
                                                },
                                            }]
                                        }, {
                                            title: "中档题正确率",
                                            children: [{
                                                title: "学生数据",
                                                key: "学生数据3",
                                                render: (record) => {
                                                    if (record.level3_right_count + record.level3_wrong_count === 0) return "-";
                                                    return parseInt(record.level3_right_count * 100 / (record.level3_right_count + record.level3_wrong_count)) + "%";
                                                },
                                            }, {
                                                title: "参考值",
                                                key: "参考值3",
                                                render: (record) => {
                                                    if (record.grade_level3_right_count + record.grade_level3_wrong_count === 0) return "-";
                                                    return parseInt(record.grade_level3_right_count * 100 / (record.grade_level3_right_count + record.grade_level3_wrong_count)) + "%";
                                                },
                                            }]
                                        }, {
                                            title: "较易题正确率",
                                            children: [{
                                                title: "学生数据",
                                                key: "学生数据4",
                                                render: (record) => {
                                                    if (record.level4_right_count + record.level4_wrong_count === 0) return "-";
                                                    return parseInt(record.level4_right_count * 100 / (record.level4_right_count + record.level4_wrong_count)) + "%";
                                                },
                                            }, {
                                                title: "参考值",
                                                key: "参考值4",
                                                render: (record) => {
                                                    if (record.grade_level4_right_count + record.grade_level4_wrong_count === 0) return "-";
                                                    return parseInt(record.grade_level4_right_count * 100 / (record.grade_level4_right_count + record.grade_level4_wrong_count)) + "%";
                                                },
                                            }]
                                        }, {
                                            title: "容易题正确率",
                                            children: [{
                                                title: "学生数据",
                                                key: "学生数据5",
                                                render: (record) => {
                                                    if (record.level5_right_count + record.level5_wrong_count === 0) return "-";
                                                    return parseInt(record.level5_right_count * 100 / (record.level5_right_count + record.level5_wrong_count)) + "%";
                                                }
                                            }, {
                                                title: "参考值",
                                                key: "参考值5",
                                                render: (record) => {
                                                    if (record.grade_level5_right_count + record.grade_level5_wrong_count === 0) return "-";
                                                    return parseInt(record.grade_level5_right_count * 100 / (record.grade_level5_right_count + record.grade_level5_wrong_count)) + "%";
                                                }
                                            }]
                                        }]}
                                        rowKey={"point_main_id"}
                                        pagination={false}
                                        size={"small"}>
                                    </Table>
                                    <ReportPrint
                                        visible={chapter.showReport}
                                        chapter={chapter}
                                        school_name={student.school}
                                        student_name={student.s_realname}
                                        class_name={student.class}
                                        onCancel={() => {
                                            chapter.showReport = false;
                                            this.setState({});
                                        }}/>
                                </Tabs.TabPane>
                            )}

                        </Tabs>
                    </div>
                    }

                </Card>
                <Modal title={"添加跟进记录"}
                       visible={this.state.contactAdd}
                       onOk={() => {
                           this.formRef.current
                               .validateFields()
                               .then(values => {
                                   // this.formRef.current.resetFields();
                                   this.onFinish(values);
                               })
                               .catch(info => {
                                   console.log('Validate Failed:', info);
                               });
                       }}
                       onCancel={() => this.setState({contactAdd: false})}
                       destroyOnClose={true}
                >
                    <Form labelCol={{span: 4}}
                          onFinish={this.onFinish}
                          ref={this.formRef}
                          initialValues={{
                              contact_time: moment()
                          }}
                    >
                        <Form.Item
                            label={"时间"}
                            name="contact_time"
                            rules={[{required: true, message: '请选择时间'}]}
                        >
                            <DatePicker
                                format={"YYYY年MM月DD日 HH:mm"}
                                showTime={{format: 'HH:mm'}}
                                style={{width: 200}}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"沟通对象"}
                            name="target"
                            rules={[{required: true, message: '请填写沟通对象'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"跟进内容"}
                            name="content"
                            rules={[{required: true, message: '请填写跟进内容'}]}
                        >
                            <Input.TextArea autoSize={{minRows: 3, maxRows: 6}}/>

                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*label={"负责人"}*/}
                        {/*name="fuze_id"*/}
                        {/*rules={[{required: true, message: '请选择负责人'}]}*/}
                        {/*>*/}
                        {/*<Select defaultValue="lucy" style={{ width: 120 }}>*/}
                        {/*<Select.Option value="jack">Jack</Select.Option>*/}
                        {/*<Select.Option value="lucy">Lucy</Select.Option>*/}
                        {/*<Select.Option value="disabled" disabled>*/}
                        {/*Disabled*/}
                        {/*</Select.Option>*/}
                        {/*<Select.Option value="Yiminghe">yiminghe</Select.Option>*/}
                        {/*</Select>*/}
                        {/*</Form.Item>*/}
                    </Form>
                </Modal>
            </PageContent>
        )
    }
}


KehuDetail.propTypes = {
    id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(KehuDetail));


