import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import styles from './login.module.css';
// import AccountMenuComponent from '../components/account-menu/account-menu';
// import {signOut} from "../reducers/account";
// import {showLoginModal} from "../reducers/login-modal";
import {Form, Input, Icon, Button, Checkbox, Row} from 'antd';
import md5 from 'md5';
import {postAccountLogin, getAccountInfo} from "../../lib/api_account.js";
import {setAccount, signOut} from "../../reducers/account";
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {setClass} from "../../reducers/current-class";

class Login extends Component {
    formRef = React.createRef();
    // handleLogout=()=>{
    //     this.props.signOut();
    //     this.props.showLoginModal();
    // };
    componentDidMount() {
        // 获取用户信息，判断是否已登录
        getAccountInfo()
            .then(res => {
                if (res.code === 0) {
                    // 设置用户信息
                    if(res.data.classinfo.length > 0){
                        this.props.setClass(res.data.classinfo[0])
                    }
                    this.props.setAccount(res.data);

                } else {
                    // 清除登录信息
                    this.props.signOut();
                }
            })
            .catch((err) => {
                // 清除登录信息
                this.props.signOut();
            });
    }

    componentWillUnmount() {
        // 删除特效
    }

    handleSubmit = values => {
        console.log('Received values of form: ', values);
        // 登录请求
        postAccountLogin(values.username, md5(values.password))
            .then((result) => {
                switch (result.code) {
                    case 0: {
                        if(result.data.classinfo.length > 0){
                            this.props.setClass(result.data.classinfo[0])
                        }
                        this.props.setAccount(result.data);

                        break;
                    }
                    case 1011: {
                        this.formRef.current.setFields([{
                            name: "username",
                            value: values.username,
                            errors: [result.message],
                        }]);
                        break;
                    }
                    case 1012: {
                        this.formRef.current.setFields([{
                            name: "password",
                            value: values.password,
                            errors: [result.message],
                        }]);
                        break;
                    }
                    default:
                        break;
                }
            })
    };


    render() {
        // const usernameError = isFieldTouched('username') && getFieldError('username');

        return (
            <Row type="flex" justify="center">
                <div>
                    <Form onFinish={this.handleSubmit}
                          ref={this.formRef}
                          size="large"
                          className={styles["login-form"]}>
                        <h1 style={{textAlign: "center"}}>勤学教育</h1>
                        <Form.Item
                            name="username"
                            rules={[{required: true, message: '请输入账号!'}]}
                        >

                            <Input
                                prefix={<UserOutlined className="site-form-item-icon"/>}
                                placeholder="账户名"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{required: true, message: '请输入密码!'}]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>} type="password"
                                placeholder="密码"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={styles["copy-right"]}>勤学教育 © 2020</div>
            </Row>
        )
    }
}


const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    setAccount: (account) => dispatch(setAccount(account)),
    signOut: () => dispatch(signOut()),
    setClass: (classinfo) => dispatch(setClass(classinfo)),
    // showLoginModal: () => dispatch(showLoginModal()),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login));

