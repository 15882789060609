import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Button,
    Select,
    Table,
    Space,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getClassPaper, postClassPaper, deleteClassPaper} from "../../lib/api_class_paper";
import GradeName1 from "../../components/grade-name";
import GradeName from "../../components/grade-name2";
import SchoolSelect from "../../containers/mystudents/school-select";
import SubjectName from '../../components/subject_name';

const {Column} = Table;


class ClassPaper extends Component {
    state = {
        list: [],
        class_paper: [],
        class_code: [],
        papers: [],
        schools: [],

        records: [],

        filter: {}
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getClassPaper()
            .then((result) => {
                if (result.code === 0) {
                    this.setState(result.data, this.dealTableData)
                }
            })
    };
    dealTableData = () => {
        let {class_paper, class_code, papers, schools, school_id, grade, subject} = this.state;
        let records = [];
        class_paper.forEach(cb => {
            let record = {id: cb.id};
            let cc = class_code.find(item => item.id === cb.class_id);
            if (cc) {
                if (school_id && cc.school_id !== school_id) return;
                // if(grade && cc.grade !== grade) return;
                if (grade) {
                    if (cc.grade === 1 && !(grade === 1 || grade === 21)) {
                        return;
                    }
                    if (cc.grade === 2 && !(grade === 2 || grade === 22)) {
                        return;
                    }
                    if (cc.grade === 3 && !(grade === 3 || grade === 23)) {
                        return;
                    }
                    if (cc.grade === 4 && !(grade === 4 || grade === 24)) {
                        return;
                    }
                    if (cc.grade === 5 && !(grade === 5 || grade === 25)) {
                        return;
                    }
                    if (cc.grade === 6 && !(grade === 6 || grade === 26 || grade === 27)) {
                        return;
                    }
                    if (cc.grade === 7 && !(grade === 7 || grade === 15)) {
                        return;
                    }
                    if (cc.grade === 8 && !(grade === 8 || grade === 16)) {
                        return;
                    }
                    if (cc.grade === 9 && !(grade === 9 || grade === 17 || grade === 13)) {
                        return;
                    }
                    if (cc.grade === 10 && !(grade === 10 || grade === 18)) {
                        return;
                    }
                    if (cc.grade === 11 && !(grade === 11 || grade === 19)) {
                        return;
                    }
                    if (cc.grade === 12 && !(grade === 12 || grade === 20 || grade === 14)) {
                        return;
                    }
                }
                record.school_id = cc.school_id;
                record.grade = cc.grade;
                record.class = cc.class;
                record.code = cc.code;
                record.year = cc.year;

                let school = schools.find(item => item.id === cc.school_id);
                if (school) {
                    record.school_name = school.school_name;
                }
            }

            console.log(cb);
            let bk = papers.find(item => item.papers_id === cb.paper_id);
            if (bk) {
                if (subject && bk.x !== subject) return;
                record.paper_name = bk.name;
                record.paper_n = cb.grade;
                record.paper_x = cb.subject_id;
                record.paper_c = bk.c;
                record.paper_l = bk.l;
                record.paper_score = bk.score;
                record.paper_type = bk.type;
            }
            records.push(record);
        });

        this.setState({records: records})
    };

    handleBind = () => {
        let {paper_id, class_id, grade2, subject} = this.state;
        if (paper_id && class_id && grade2 && subject) {
            postClassPaper(paper_id, class_id, grade2, subject).then(res => {
                if (res.code === 0) {
                    this.fetchData()
                }
            })
        }
    };

    handleDelete = (id) => {
        deleteClassPaper(id).then(res => {
            if (res.code === 0) {
                this.fetchData()
            }
        })
    };

    render() {
        let {class_paper, class_code, papers, records, school_id, grade, subject} = this.state;

        if (school_id) {
            // console.log(school_id)
            class_code = class_code.filter(item => item.school_id === school_id);
        }

        if (grade) {
            class_code = class_code.filter(item => item.grade === grade);
            papers = papers.filter(item => item.n === grade);
        }

        if (subject) {
            // console.log(school_id)
            papers = papers.filter(item => item.x === subject);
        }

        return (
            <PageContent title={"试卷和班级绑定"}>
                <Card bordered={false} size={"small"}>
                    <Space>
                        筛选：
                        <SchoolSelect onChange={(id) => {
                            this.setState({school_id: id}, this.dealTableData);
                        }}/>
                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择年级"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({grade: value}, this.dealTableData)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {config.grade.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>

                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择科目"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({subject: value}, this.dealTableData)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {config.subjects.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Space>
                </Card>
                <Card bordered={false} size={"small"}>
                    <Space>
                        绑定：
                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择班级"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({class_id: value})}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {class_code.map(item => <Select.Option value={item.id}>{item.class}</Select.Option>)}
                        </Select>
                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择年级"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({grade2: value})}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {config.grade2.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                        <Select
                            style={{width: 400}}
                            allowClear
                            showSearch
                            placeholder="选择试卷"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({paper_id: value})}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {papers.map(item => <Select.Option value={item.papers_id}>{item.name}</Select.Option>)}
                        </Select>
                        <Button type={"primary"} onClick={this.handleBind}>添加绑定</Button>
                    </Space>
                </Card>
                <Card bordered={false} size={"small"}>
                    <Table dataSource={records} rowKey={"id"} size={"small"}>
                        <Column
                            title="学校"
                            dataIndex="school_name"
                            key="school_name"
                        />
                        <Column
                            title="年级"
                            dataIndex="grade"
                            key="grade"
                            render={(grade) => <GradeName1 id={grade}/>}
                        />
                        <Column
                            title="班级"
                            dataIndex="class"
                            key="class"
                        />
                        <Column
                            title="班级代码"
                            dataIndex="code"
                            key="code"
                        />
                        <Column
                            title="入学年份"
                            dataIndex="year"
                            key="year"
                        />


                        <Column
                            title="试卷名称"
                            dataIndex="paper_name"
                            key="paper_name"
                        />

                        <Column
                            title="试卷年级"
                            dataIndex="paper_n"
                            key="paper_n"
                            render={(grade) => <GradeName id={grade}/>}
                        />
                        <Column
                            title="试卷学科"
                            dataIndex="paper_x"
                            key="paper_x"
                            render={(book_x) => <SubjectName id={book_x}/>}
                        />


                        <Column
                            title="操作"
                            key="action"
                            // width={250}
                            render={(text, record) => (
                                <div>
                                    <a onClick={() => this.handleDelete(record.id)}>删除</a>
                                </div>
                            )}
                        />
                    </Table>
                </Card>
            </PageContent>
        )
    }
}


ClassPaper.propTypes = {};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClassPaper));


