import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Button,
    Select,
    Table,
    Space,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getClassBooks, postClassBooks, deleteClassBooks} from "../../lib/api_class_book";
import GradeName from "../../components/grade-name2";
import SchoolSelect from "../../containers/mystudents/school-select";
import SubjectName from '../../components/subject_name';

const {Column} = Table;


class ClassBook extends Component {
    state = {
        list: [],
        class_book: [],
        class_code: [],
        book: [],
        schools: [],

        records: [],

        filter: {}
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        getClassBooks()
            .then((result) => {
                if (result.code === 0) {
                    this.setState(result.data, this.dealTableData)
                }
            })
    };
    dealTableData = () => {
        let {class_book, class_code, book, schools, school_id, grade, subject} = this.state;
        let records = [];
        class_book.forEach(cb => {
            let record = {
                id: cb.id,
                name: cb.name,
                grade: cb.grade,
                subject_id: cb.subject_id,
            };
            let cc = class_code.find(item => item.id === cb.class_id);
            if (cc) {
                if (school_id && cc.school_id !== school_id) return;
                // if (grade && cc.grade !== grade) return;
                record.school_id = cc.school_id;
                // record.grade = cc.grade;
                record.class = cc.class;
                record.code = cc.code;
                record.year = cc.year;

                let school = schools.find(item => item.id === cc.school_id);
                if (school) {
                    record.school_name = school.school_name;
                }
            }

            // let bk = book.find(item => item.book_id === cb.book_id);
            // if (bk) {
            //     if (subject && bk.x !== subject) return;
            //     record.book_name = bk.name;
            //     record.book_n = bk.n;
            //     record.book_x = bk.x;
            //     record.book_c = bk.c;
            //     record.book_l = bk.l;
            //     record.book_chapter = bk.chapter;
            //     record.book_point = bk.book_point;
            //     record.book_rpoint = bk.book_rpoint;
            //     record.book_diff = bk.diff;
            // }
            records.push(record);
        });

        this.setState({records: records})
    };

    handleBind = () => {
        let {book_id, class_id, grade2, subject, name} = this.state;
        if (book_id && class_id && grade2 && subject) {
            postClassBooks(book_id, class_id, grade2, subject, name).then(res => {
                if (res.code === 0) {
                    this.fetchData()
                }
            })
        }
    };

    handleDelete = (id) => {
        deleteClassBooks(id).then(res => {
            if (res.code === 0) {
                this.fetchData()
            }
        })
    };

    render() {
        let {class_book, class_code, book, records, school_id, grade, subject} = this.state;

        if (school_id) {
            // console.log(school_id)
            class_code = class_code.filter(item => item.school_id === school_id);
        }

        if (grade) {
            // console.log(school_id)
            class_code = class_code.filter(item => item.grade === grade);
            book = book.filter(item => item.n === grade);
        }

        if (subject) {
            // console.log(school_id)
            book = book.filter(item => item.x === subject);
        }

        return (
            <PageContent title={"作业本和班级绑定"}>
                <Card bordered={false} size={"small"}>
                    筛选：
                    <Space>
                        <SchoolSelect onChange={(id) => {
                            this.setState({school_id: id}, this.dealTableData);
                        }}/>
                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择年级"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({grade: value}, this.dealTableData)}
                            // filterOption={(input, option) =>
                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                        >
                            {config.grade.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>

                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择科目"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({subject: value}, this.dealTableData)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {config.subjects.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Space>
                </Card>
                <Card bordered={false} size={"small"}>
                    绑定：
                    <Space>
                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择班级"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({class_id: value})}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {class_code.map(item => <Select.Option value={item.id}>{item.class}</Select.Option>)}
                        </Select>
                        <Select
                            style={{width: 200}}
                            allowClear
                            placeholder="选择年级"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({grade2: value})}
                            // filterOption={(input, option) =>
                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                        >
                            {config.grade2.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>


                        <Select
                            style={{width: 200}}
                            allowClear
                            showSearch
                            labelInValue
                            placeholder="选择作业本"
                            optionFilterProp="children"
                            onChange={(value) => this.setState({book_id: value.key, name: value.label})}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {book.map(item => <Select.Option value={item.book_id}>{item.name}</Select.Option>)}
                        </Select>
                        <Button type={"primary"} onClick={this.handleBind}>添加绑定</Button>
                    </Space>
                </Card>
                <Card bordered={false} size={"small"}>
                    <Table dataSource={records} rowKey={"id"} size={"small"}>
                        <Column
                            title="学校"
                            dataIndex="school_name"
                            key="school_name"
                        />
                        {/*<Column*/}
                        {/*title="年级"*/}
                        {/*dataIndex="grade"*/}
                        {/*key="grade"*/}
                        {/*render={(grade) => <GradeName id={grade}/>}*/}
                        {/*/>*/}
                        <Column
                            title="班级"
                            dataIndex="class"
                            key="class"
                        />
                        <Column
                            title="班级代码"
                            dataIndex="code"
                            key="code"
                        />
                        <Column
                            title="入学年份"
                            dataIndex="year"
                            key="year"
                        />


                        <Column
                            title="作业本名称"
                            dataIndex="name"
                            key="name"
                        />

                        <Column
                            title="作业本年级"
                            dataIndex="grade"
                            key="grade"
                            render={(grade) => <GradeName id={grade}/>}
                        />
                        <Column
                            title="作业本学科"
                            dataIndex="subject_id"
                            key="subject_id"
                            render={(subject_id) => <SubjectName id={subject_id}/>}
                        />


                        <Column
                            title="操作"
                            key="action"
                            // width={250}
                            render={(text, record) => (
                                <div>
                                    <a onClick={() => this.handleDelete(record.id)}>删除</a>
                                </div>
                            )}
                        />
                    </Table>
                </Card>
            </PageContent>
        )
    }
}


ClassBook.propTypes = {};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClassBook));


