import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {Card, Table, Divider, Select, Space, Tabs, Descriptions, Typography, Row, Col, Button} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";
import styles from './index.module.css';
import {getPaperPK} from "../../../lib/api_school_papers";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";

let {Column} = Table;
let {Option} = Select;
let {TabPane} = Tabs;
const {Text} = Typography;


class Pk extends Component {
    state = {
        paper_id: 0,
        books: [],
        list: [],
        papers: [],
        // currentPaper: null,
        chapters1: [],
        chapters2: [],
        chapters1_id: 0,
        chapters2_id: 0,
        student_papers: [], // 学生试卷
        students: [], // 学员列表
        topic_models: [],
        student_paper_topics: [], // 获取学生具体题目登记情况
        group_paper_topics: [], // 年级数据
        classes: [], // 所有的同年级班级
        loading: false,
        classesinfo1: null,
        classesinfo2: null
    };

    componentWillMount() {

    }


    componentDidMount() {
        this.fetchPaperInfo();
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchPaperInfo = () => {
        let {paper_id, class_id1, class_id2} = this.props;
        if (paper_id && class_id1 && class_id2) {
            getPaperPK({paper_id, class_id1, class_id2})
                .then((result) => {
                    if (result.code === 0) {

                        this.setState({
                            topic_models: result.data.topic_models,
                            group_paper_topics: result.data.group_paper_topics,
                            classesinfo1: result.data.classesinfo1,
                            classesinfo2: result.data.classesinfo2,
                        })
                    }
                })
        }

    };

    fetchData = () => {
        // this.fetchClassBooks();
    };

    render() {
        let {classesinfo1, classesinfo2, topic_models, group_paper_topics} = this.state;
        let {paperlist, chapters} = this.props.account;
        let {paper_id, class_id1, class_id2,class_name1,class_name2} = this.props;

        if (!(classesinfo1 && classesinfo2)) {
            return null;
        }
        let currentPaper = paperlist.find(item => item.paper_id === paper_id);

        // 计算分值段
        let stat_score = []; // 分值范围统计
        stat_score.push({
            max: currentPaper.score * 1,
            min: parseInt(currentPaper.score * 0.9),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.9),
            min: parseInt(currentPaper.score * 0.8),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.8),
            min: parseInt(currentPaper.score * 0.7),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.7),
            min: parseInt(currentPaper.score * 0.6),
            students: []
        });
        stat_score.push({
            max: parseInt(currentPaper.score * 0.6),
            min: 0,
            students: []
        });
        let data1 = stat_score.map(item => {
            let model = {
                label: `${item.min}~${item.max}`,
                dmax: item.max,
                dmin: item.min,
            };
            model[class_name1] = 0;
            model[class_name2] = 0;
            return model
        });

        classesinfo1.student_papers.forEach(sp => {
            let levelindex = data1.findIndex(ss => ss.dmax >= sp.student_score && ss.dmin <= sp.student_score);
            let level = data1[levelindex];
            if (level) {
                level[class_name1] += 1
            }
        });
        classesinfo2.student_papers.forEach(sp => {
            let levelindex = data1.findIndex(ss => ss.dmax >= sp.student_score && ss.dmin <= sp.student_score);
            let level = data1[levelindex];
            if (level) {
                level[class_name2] += 1
            }
        });

        console.log(data1);

        let data2 = [];
        topic_models.forEach(tm => {
            let class_model_ave1 = 0; // 该类型题目下的班级平均分
            let class_model_ave2 = 0; // 该类型题目下的班级平均分
            let group_model_ave = 0; // 年级平均

            tm.topics.forEach(topic => {
                let spt1 = classesinfo1.student_paper_topics.filter(item => item.paper_id === paper_id && item.topic_id === topic.topic_id);
                let spt2 = classesinfo2.student_paper_topics.filter(item => item.paper_id === paper_id && item.topic_id === topic.topic_id);
                let total_score1 = 0;
                let total_count1 = 0;
                spt1.forEach(item => {
                    total_score1 += item.score;
                    total_count1 += 1;
                });
                let total_score2 = 0;
                let total_count2 = 0;
                spt2.forEach(item => {
                    total_score2 += item.score;
                    total_count2 += 1;
                });

                topic.class_ave1 = (total_score1 / total_count1).toFixed(1);
                class_model_ave1 += (total_score1 / total_count1);

                topic.class_ave2 = (total_score2 / total_count2).toFixed(1);
                class_model_ave2 += (total_score2 / total_count2);

                topic.class_ave_two = ((total_score1 + total_score2) / (total_count1+total_count2)).toFixed(1);

                // 计算年级平均
                let gpt_total_score = 0;
                let gpt_total_count = 0;
                let gpt = group_paper_topics.filter(item => item.paper_id === paper_id && item.topic_id === topic.topic_id);
                gpt.forEach(item => {
                    gpt_total_score += item.score;
                    gpt_total_count += 1;
                });
                topic.group_ave = (gpt_total_score / gpt_total_count).toFixed(1);
                group_model_ave += (gpt_total_score / gpt_total_count);
            });
            console.log(class_model_ave1, class_model_ave2);

            let model = {
                label: tm.topic_model_name,
                年级平均: Math.round(group_model_ave * 10) / 10,
            };
            model[class_name1] = Math.round(class_model_ave1 * 10) / 10
            model[class_name2] = Math.round(class_model_ave2 * 10) / 10
            data2.push(model);
        });


        // let data1 = [{
        //     label: '90~100',
        //     101: 100,
        //     102: 200
        // }];

        const ds1 = new DataSet();
        const dv1 = ds1.createView().source(data1);
        dv1.transform({
            type: "fold",
            fields: [class_name1+"", class_name2+""],
            // 展开字段集
            key: "type",
            // key字段
            value: "value" // value字段
        });


        // let data2 = [{
        //     label: '90~100',
        //     101: 100,
        //     102: 200,
        //     年级平均: 150
        // }];
        const ds2 = new DataSet();
        const dv2 = ds2.createView().source(data2);
        dv2.transform({
            type: "fold",
            fields: [ "年级平均",class_name1, class_name2],
            // 展开字段集
            key: "type",
            // key字段
            value: "value" // value字段
        });

        return (
            <Card bordered={false} size={"small"}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Card size="small">
                            <h3 className='main-title' style={{textAlign: "center"}}>
                                分值段人数分布
                            </h3>
                            <Chart height={200} data={dv1} forceFit>

                                <Legend/>
                                <Coord transpose scale={[1, -1]}/>
                                <Axis
                                    name="label"
                                    label={{
                                        offset: 12
                                    }}
                                />
                                <Axis name="value" position={"right"}/>
                                <Tooltip/>
                                <Geom
                                    type="interval"
                                    position="label*value"
                                    color={"type"}
                                    adjust={[
                                        {
                                            type: "dodge",
                                            marginRatio: 1 / 32
                                        }
                                    ]}
                                />
                            </Chart>
                        </Card>

                    </Col>
                    <Col span={12}>
                        <Card size="small">
                            <h3 className='main-title' style={{textAlign: "center"}}>
                                题型平均分布
                            </h3>
                            <Chart height={200} data={dv2} forceFit>

                                <Legend/>
                                <Coord transpose scale={[1, -1]}/>
                                <Axis
                                    name="label"
                                    label={{
                                        offset: 12
                                    }}
                                />
                                <Axis name="value" position={"right"}/>
                                <Tooltip/>
                                <Geom
                                    type="interval"
                                    position="label*value"
                                    color={"type"}
                                    adjust={[
                                        {
                                            type: "dodge",
                                            marginRatio: 1 / 32
                                        }
                                    ]}
                                />
                            </Chart>
                        </Card>

                    </Col>
                    <Col span={24}>
                        {topic_models.map(tm => <div>
                            <Table dataSource={tm.topics}
                                   rowKey={"id"}
                                   pagination={false}
                                   size={"small"}
                                   bordered
                                   title={() => tm.topic_model_name}
                            >
                                <Column
                                    title="题号"
                                    dataIndex="topic_number"
                                    key="topic_number"
                                />
                                <Column
                                    title="年级平均"
                                    dataIndex="group_ave"
                                    key="group_ave"
                                />
                                <Column
                                    title={class_name1}
                                    dataIndex="class_ave1"
                                    key="class_ave1"
                                />
                                <Column
                                    title={class_name2}
                                    dataIndex="class_ave2"
                                    key="class_ave2"
                                />

                                <Column
                                    title="PK班级平均分"
                                    dataIndex="class_ave_two"
                                    key="class_ave_two"
                                />

                            </Table>
                        </div>)}
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Pk));
