import axios from './rest-axios';

const getClassStudents = (class_id,book_id) => axios.get(`/school/v1/classes/${class_id}`, {book_id});
const getClassStudents2 = (class_id) => axios.get(`/school/v1/classes/${class_id}/students`, {});
const getClassBooks = (class_id) => axios.get(`/school/v1/classes/${class_id}/books`);
const getClassBookInfo = (class_id,book_id,chapter_id) => axios.get(`/school/v1/classes/${class_id}/books/${book_id}`, {chapter_id});

export {
    getClassStudents,
    getClassBooks,
    getClassBookInfo,
    getClassStudents2,
};
