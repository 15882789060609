import PropTypes from 'prop-types';
import React from 'react';
import config from '../config';

const SubjectName = ({id}) => {
    let model = config.subjects.find(item => item.id === id);
    if (model) {
        return model.name;
    } else {
        return null;
    }
};

SubjectName.propTypes={
    id: PropTypes.number.isRequired
};

export default SubjectName