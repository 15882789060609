import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {postStudent} from "../../lib/api_students";
import {
    Modal,
    Input,
    Form,
    Checkbox,
    Radio,
    Select
} from 'antd';
import config from '../../config';
import md5 from 'md5';
import SchoolSelect from './school-select';


class UserEditModal extends Component {
    formRef = React.createRef();

    state = {
        loading: false
    };

    onSubmit = (values) => {
        values.password = md5(values.password);
        postStudent(values)
            .then(result => {
                console.log(result);
                // 刷新数据
                this.handleOK();
            })
    };


    handleFinish = () => {
        this.formRef.current
            .validateFields()
            .then(values => {
                console.log(values)
                this.onSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {visible} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        let initialValues = {
            password: "123456",
            sex:0
        };

        return (
            <Modal
                title="添加学生信息"
                visible={visible}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                <Form {...formItemLayout}
                      initialValues={initialValues}
                      ref={this.formRef}
                >
                    <Form.Item
                        label={"姓名"}
                        name="realname"
                        rules={[{required: true, message: '请输入姓名'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"账号"}
                        name="name"
                        rules={[{required: true, message: '请输入账号'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"密码"}
                        name="password"
                        rules={[{required: true, message: '请输入密码'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"性别"}
                        name="sex"
                        rules={[{required: true, message: '请选择性别'}]}
                    >
                        <Radio.Group onChange={this.onChange} value={this.state.value}>
                            <Radio value={0}>男</Radio>
                            <Radio value={1}>女</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={"手机号"}
                        name="mobile"
                        rules={[{required: true, message: '请输入手机号码'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"学校"}
                        name="school_id"
                        rules={[{required: true, message: '请选择学校'}]}
                    >
                        <SchoolSelect/>
                    </Form.Item>
                    <Form.Item
                        label={"年级"}
                        name="grade"
                        rules={[{required: true, message: '请选择年级'}]}
                    >
                        <Select
                            style={{width: 200}}
                            allowClear={false}
                            placeholder="选择年级"
                            optionFilterProp="children"
                            // value={this.state.grade}
                            // onChange={(value) => this.setState({grade: value})}
                            // filterOption={(input, option) =>
                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                        >
                            {config.grade.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

}


UserEditModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditModal));
