/**
 * @name: 学生端
 * @description:
 * @author: Wood
 * @date: 2020/4/26
 * @update:
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {Card, Tag, Row, Col, Typography, Progress, Space, Button, Collapse, Divider, message} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getTeachingDocMy, getTeachingDocById} from "../../../lib/api_teaching_doc";
import TeachingDocStatusName from '../../../components/teaching_doc/teaching-doc-status-name';
import SemesterName from '../../../components/semester-name';
import {SnippetsOutlined, ClockCircleOutlined, ContainerOutlined, AimOutlined} from '@ant-design/icons';
import styles from './detail.module.css';
import html2canvas from "html2canvas";
import {getTeachingDocUnSubmit} from "../../../lib/api_teaching_doc";

const {Panel} = Collapse;

const {Text} = Typography;
const url = window.location.host.indexOf('localhost') > -1 ? "http://localhost:8001" : `${window.location.protocol}//${window.location.host}`;
const socket = require('socket.io-client')(url);

const windowToCanvas = (canvas, x, y) => {
    //获取canvas元素距离窗口的一些属性，MDN上有解释
    let rect = canvas.getBoundingClientRect();
    // console.log(rect);
    // console.log(canvas.width, canvas.height, x, y);
    //x和y参数分别传入的是鼠标距离窗口的坐标，然后减去canvas距离窗口左边和顶部的距离。
    // return {
    //     x: x - rect.left * (canvas.width / rect.width),
    //     y: y - rect.top * (canvas.height / rect.height)
    // }
    return {
        x: (x - rect.left) * (canvas.width / rect.width),
        y: (y - rect.top) * (canvas.width / rect.width)
    }
}

// function scaleImageData(c, imageData, scale) {
//     var scaled = c.createImageData(imageData.width * scale, imageData.height * scale);
//
//     for(var row = 0; row < imageData.height; row++) {
//         for(var col = 0; col < imageData.width; col++) {
//             var sourcePixel = [
//                 imageData.data[(row * imageData.width + col) * 4 + 0],
//                 imageData.data[(row * imageData.width + col) * 4 + 1],
//                 imageData.data[(row * imageData.width + col) * 4 + 2],
//                 imageData.data[(row * imageData.width + col) * 4 + 3]
//             ];
//             for(var y = 0; y < scale; y++) {
//                 var destRow = row * scale + y;
//                 for(var x = 0; x < scale; x++) {
//                     var destCol = col * scale + x;
//                     for(var i = 0; i < 4; i++) {
//                         scaled.data[(destRow * scaled.width + destCol) * 4 + i] =
//                             sourcePixel[i];
//                     }
//                 }
//             }
//         }
//     }
//
//     return scaled;
// }

function scaleImageData(ctx, imageData, scale) {
    var scaled = ctx.createImageData(imageData.width * scale, imageData.height * scale);
    var subLine = ctx.createImageData(scale, 1).data
    for (var row = 0; row < imageData.height; row++) {
        for (var col = 0; col < imageData.width; col++) {
            var sourcePixel = imageData.data.subarray(
                (row * imageData.width + col) * 4,
                (row * imageData.width + col) * 4 + 4
            );
            for (var x = 0; x < scale; x++) subLine.set(sourcePixel, x * 4)
            for (var y = 0; y < scale; y++) {
                var destRow = row * scale + y;
                var destCol = col * scale;
                scaled.data.set(subLine, (destRow * scaled.width + destCol) * 4)
            }
        }
    }

    return scaled;
}


class JingZhunDetail extends Component {


    dom = React.createRef();
    // img = React.createRef();
    canvas = React.createRef();
    drawcanvas = React.createRef();

    state = {
        loading: false,
        list: [],
        data: null,
        topic: null,
        room_id: this.props.account.id,
        topics: [], // 题目队列
        topic_index: -1
    };

    constructor(props) {
        super(props);
        // this.domRRR = React.createRef();
        // console.log(this.domRRR);
    }

    componentDidMount() {
        // const dom = ReactDOM.findDOMNode(this);
        // this.fetchData();


        const canvas = this.canvas.current;
        if (canvas.getContext) {
            this.ctx = canvas.getContext("2d");
        }

        // 处理鼠标绘制事件
        const drawcanvas = this.drawcanvas.current;
        if (drawcanvas.getContext) {
            let ctx = drawcanvas.getContext("2d");
            ctx.fillStyle = 'rgba(255, 255, 255, 0)';
            // console.log(this.ctx);
            // console.log(Object.getPrototypeOf(this.ctx));

            let isAllowDrawLine = false;

            drawcanvas.onmousedown = (e) => {
                isAllowDrawLine = true;
                //获得鼠标按下的点相对canvas的坐标。
                let ele = windowToCanvas(drawcanvas, e.clientX, e.clientY);
                //es6的解构赋值
                let {x, y} = ele;
                //绘制起点。
                ctx.moveTo(x, y);
                //鼠标移动事件
                drawcanvas.onmousemove = (e) => {
                    if (isAllowDrawLine) {
                        e.preventDefault();
                        //移动时获取新的坐标位置，用lineTo记录当前的坐标，然后stroke绘制上一个点到当前点的路径
                        let ele = windowToCanvas(drawcanvas, e.clientX, e.clientY);
                        // console.log(ele);
                        let {x, y} = ele;
                        ctx.lineTo(x, y);
                        ctx.stroke()
                    }
                }
            };
            drawcanvas.onmouseup = () => {
                isAllowDrawLine = false
            }
        }


        socket.on("reconnect", () => {
            socket.emit("student join", {
                room_id: this.props.account.id
            });
        });

        socket.emit("student join", {
            room_id: this.props.account.id
        });
        socket.on('teacher joined', (data) => {
            console.log("老师加入房间")
        });
        socket.on('student joined', (data) => {
            console.log("学生加入房间")
        });

        socket.on('new topic', (data) => {
            console.log("有新题目了");
            this.fetchData(data.teaching_doc_id);
        });

        socket.on('success', (data) => {
            message.success(data.message)
        });
        socket.on('error', (data) => {
            message.error(data.message)
        });


        socket.on('submit success', (data) => {
            message.success(data.message);
            // this.removeTopic();
            this.clearDrawCanvas();
            this.clearCanvas();

            this.setState({
                loading: false,
                topic: null
            }, () => {
                this.fetchData(data.teaching_doc_id)
            })
        });

        socket.on('send topic', (data) => {
            console.log("收到题目", data);
            // this.setState({
            //     topic: data.data
            // });
            this.addTopic(data.data);
        });

        socket.on('send topics', (data) => {
            console.log("收到题目", data);
            // this.setState({
            //     topic: data.data
            // });

            this.addTopics(data);
        });
    }

    addTopics = (topics) => {
        this.state.topics.push(...topics);

        this.showTopic();
        this.setState({})
    };

    // 添加题目进入待答题列表
    addTopic = (topic) => {
        this.state.topics.push(topic);
        // this.setState({})
        this.showTopic();
        this.setState({})
        // if (this.state.topic_index === -1) {
        //     this.state.topic_index = 0;
        // }
    };

    // 移除题目
    removeTopic = () => {
        if (this.state.topic_index === 0) {
            this.state.topic_index = -1;
            this.state.topics.shift();
            this.showTopic();
        }
    };

    // 显示题目
    showTopic = () => {
        if (this.state.topics.length === 0) return; // 没有题目
        if (this.state.topic) return; // 正在作答
        // if (this.state.topic_index === 0) return; // 正在作答
        // if (this.state.topic_index === -1) {
        //     // 没有作答
        //     this.state.topic_index = 0;
        // }
        this.state.topic = this.state.topics[0];

        if (this.state.topic.lastAnswer) {
            let path = this.state.topic.lastAnswer.replace('public', url);
            this.dom.current.innerHTML = `<p><img src="${path}"  title="1568163853480070.png" alt="image.png" width="564"/></p>`
        } else {
            this.dom.current.innerHTML = this.state.topic.origin.topic_content;
        }

        let copyDom = this.dom.current;
        let width = copyDom.offsetWidth; //dom宽
        let height = copyDom.offsetHeight; //dom高
        let scale = 2; //放大倍数

        setTimeout(()=>{
            html2canvas(
                this.dom.current,
                {
                    useCORS: true,
                    // allowTaint:false,
                    dpi: window.devicePixelRatio * scale,
                    scale: scale,
                    width: width,
                    heigth: height,
                    // scale: 2
                }
            ).then(
                (canvas) => {
                    const dataURL = canvas.toDataURL('image/png', 1.0);
                    // this.img.current.src = dataURL;
                    var img = new Image();
                    img.src = dataURL;
                    img.crossOrigin = 'Anonymous';
                    img.onload = () => {
                        this.drawImage(img);
                    }
                }
            )
            this.setState({})

        }, 1000);

    };

    clearDrawCanvas = () => {
        this.drawcanvas.current.height += 0;
    };
    clearCanvas = () => {
        this.canvas.current.height += 0;
    };
    // 绘制题目图片到画布上，进行宽高适配
    drawImage = (img) => {
        let canvas = this.canvas.current;
        const minWidth = 1600;
        const minHeight = 1200;
        // const minWidth = 800;
        // const minHeight = 600;
        let width = minWidth;
        let height = minHeight;
        if (img.height + 1000 > minHeight) {
            height = img.height + 1000
        }
        if (img.width + 100 > minWidth) {
            width = img.width + 100
        }
        // var nc = document.createElement("canvas");
        // nc.width = this.canvas.width;
        // nc.height = this.canvas.height;
        // nc.getContext("2d").drawImage(this.canvas,0,0);

        canvas.width = width;
        canvas.height = height;
        // this.ctx.putImageData(imgData,0,0);
        // this.ctx.drawImage(nc,0,0);
        // this.ctx.scale(0.5, 0.5);
        this.ctx.drawImage(img, 0, 0, img.width, img.height, 50, 50, img.width, img.height);

        let drawcanvas = this.drawcanvas.current;
        let ctx = drawcanvas.getContext("2d");
        var imgData = ctx.getImageData(0, 0, drawcanvas.width, drawcanvas.height);
        drawcanvas.width = width;
        drawcanvas.height = height;
        ctx.putImageData(imgData, 0, 0);
    };


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = (id) => {
        if (!id) {
            return;
        }
        // 获取学生未作答的数据
        getTeachingDocUnSubmit(id)
            .then(res => {
                if (res.code === 0) {
                    this.setState({
                        topics: res.data
                    }, this.showTopic)
                }
            })
    };

    cropSignatureCanvas = (canvas) => {
        // 复制画布 避免影响之前的画布
        var croppedCanvas = document.createElement('canvas'),
            croppedCtx = croppedCanvas.getContext('2d');

        croppedCanvas.width = canvas.width;
        croppedCanvas.height = canvas.height;
        croppedCtx.drawImage(canvas, 0, 0);

        // 处理画布空白
        var w = croppedCanvas.width,
            h = croppedCanvas.height,
            pix = {x: [], y: []},
            imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height), // 获取canvas像素数据
            x, y, index;
        // console.log(imageData);
        // console.log(w,h)
        for (y = 0; y < h; y++) {
            for (x = 0; x < w; x++) {
                index = (y * w + x) * 4 + 3;  //根据行、列读取某像素点的R/G/B/A值的公式：
                if (imageData.data[index] > 0) {
                    pix.x.push(x);
                    pix.y.push(y);
                }
            }
        }
        // console.log(pix);

        pix.x.sort(function (a, b) {
            return a - b
        });
        pix.y.sort(function (a, b) {
            return a - b
        });
        var n = pix.x.length - 1;

        w = pix.x[n] - pix.x[0];
        h = pix.y[n] - pix.y[0];
        // console.log(w,h)
        var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);
        // console.log(w,h);
        // cut = scaleImageData(croppedCtx, {
        //     width:w,
        //     height:h,
        //     data: cut
        // }, 0.5)
        croppedCanvas.width = w;
        croppedCanvas.height = h;
        croppedCtx.putImageData(cut, 0, 0);
        return croppedCanvas.toDataURL("image/png", 1.0);
    };

    // 提交作答
    handleSubmit = () => {
        this.setState({
            loading: true
        });
        let drawcanvas = this.drawcanvas.current;
        let ctx = drawcanvas.getContext("2d");
        let img = document.createElement('img');
        img.src = drawcanvas.toDataURL('image/png', 1.0);
        img.onload = () => {
            this.ctx.drawImage(img, 0, 0);
            let data = this.cropSignatureCanvas(this.canvas.current);
            console.log(data);

            // data = scaleImageData(this.ctx, data, 0.5);

            this.state.topic.answerData = data;
            socket.emit("submit topic", {
                room_id: this.state.room_id,
                data: this.state.topic
            })

            // var img2 = new Image();
            // img2.src = data;
            // img2.crossOrigin = 'Anonymous';
            // img2.onload = () => {
            //     var croppedCanvas = document.createElement('canvas'),
            //         croppedCtx = croppedCanvas.getContext('2d');
            //     croppedCanvas.width = img2.width / 2;
            //     croppedCanvas.height = img2.height / 2;
            //     croppedCtx.drawImage(img2, 0, 0, img2.width, img2.height, 0, 0, croppedCanvas.width, croppedCanvas.height);
            //     data = croppedCanvas.toDataURL('image/png', 1.0);
            //
            //     this.state.topic.answerData = data;
            //     socket.emit("submit topic", {
            //         room_id: this.state.room_id,
            //         data: this.state.topic
            //     })
            //     // console.log(img.width);
            //     // this.canvas.current.width = img.width;
            //     // this.canvas.current.height = img.height;
            //     // this.canvas.current.style.width = img.width/2 + "px";
            //     // this.ctx.drawImage(img, 0, 0);
            // }


        }

        // var imgData = ctx.getImageData(0, 0, drawcanvas.width, drawcanvas.height);
        // for(var i=0; i<imgData.data.length; i+=4)
        // {
        //     if((i/4)%30 > 15) continue;
        //     imgData.data[i]=255;
        //     imgData.data[i+1]=0;
        //     imgData.data[i+2]=0;
        //     imgData.data[i+3]=255;
        // }
        // console.log(alpha);
        // this.ctx.putImageData(imgData, 100, 100);
        // let data = this.cropSignatureCanvas(this.canvas.current);
        // console.log(data);


    };


    render() {
        let {loading,topics} = this.state;
        // let {list, filter, data} = this.state;
        // if (!data) return null;
        // let points = data.points.filter(point => point.selectExamples && point.selectExamples.length > 0);
        return (
            <div style={{
                height: "100%",
                overflow: "hidden"
            }}>
                <div className={styles.student_header_wrapper}>
                    <h1>精准教学</h1>
                    <div className={styles.student_header_extr_wrapper}>
                        <Space size={30}>
                            {topics.length > 1 ?
                                <span>
                                剩余题目：{this.state.topics.length}
                                </span>
                                : null
                            }

                            <Button onClick={this.clearDrawCanvas} size={"large"} style={{width: 120}}>清除</Button>
                            <Button onClick={this.handleSubmit} type={"primary"} style={{width: 120}} size={"large"}
                                    loading={loading} disabled={this.state.topic === null}>提交</Button>
                        </Space>
                    </div>
                </div>

                <Card bordered={false} size={"small"} className={styles.bodyWrapper}>
                    <div style={{position: "fixed", left: -2000, maxWidth: 547, height: "auto"}}>
                        <div ref={this.dom}/>
                    </div>
                    {/*<img ref={this.img}/>*/}
                    <div className={styles.canvasWrapper}>
                        <canvas ref={this.canvas} width="1600" height="1200" style={{width: "100%"}}>
                            您的浏览器不支持canvas，请更换浏览器.
                        </canvas>
                        <canvas ref={this.drawcanvas} width="1600" height="1200"
                                style={{width: "100%", position: "absolute", left: 0, top: 0}}>
                            您的浏览器不支持canvas，请更换浏览器.
                        </canvas>
                    </div>
                </Card>
            </div>
        )
    }
}


JingZhunDetail.propTypes = {
    // id: PropTypes.number.isRequired
};


const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(JingZhunDetail));


