/**
 * @name: 左侧菜单
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/4/21
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from "react-router-dom";
import styles from './sider-menu.module.css';
import {Menu, Icon} from 'antd';
import {connect} from 'react-redux';
import {SettingOutlined, SolutionOutlined} from "@ant-design/icons";

const SiderMenu = (props) => (
    <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[props.defaultSelectedKeys]}
        defaultOpenKeys={["/zixun","/teaching","/settings"]}
    >
        <Menu.SubMenu
            key="/zixun"
            title={
                <span>
                    <SolutionOutlined/>
                    <span>咨询中心</span>
                </span>
            }
        >
            <Menu.Item key="/zixun/kehu">
                <Link to={"/zixun/kehu"}>
                    客户管理
                </Link>
            </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
            key="/teaching"
            title={
                <span>
                    <SolutionOutlined/>
                    <span>教学中心</span>
                </span>
            }
        >

            <Menu.Item key="/class_book">
                <Link to={"/class_book"}>
                    作业本绑定
                </Link>
            </Menu.Item>
            <Menu.Item key="/class_paper">
                <Link to={"/class_paper"}>
                    试卷绑定
                </Link>
            </Menu.Item>
            <Menu.Item key="/chapter">
                <Link to={"/chapter"}>
                    章节绑定
                </Link>
            </Menu.Item>
            <Menu.Item key="/mystudents">
                <Link to={"/mystudents"}>
                    我的学员
                </Link>
            </Menu.Item>
            <Menu.Item key="/teaching_doc">
                <Link to={"/teaching_doc"}>
                    教案管理
                </Link>
            </Menu.Item>
            <Menu.Item key="/jingzhun">
                <Link to={"/jingzhun"}>
                    精准教学
                </Link>
            </Menu.Item>
            {/*<Menu.Item key="3">教案管理</Menu.Item>*/}
            {/*<Menu.Item key="3">精准教学</Menu.Item>*/}
        </Menu.SubMenu>

        <Menu.SubMenu
            key="/settings"
            title={
                <span>
                  <SettingOutlined/>
                  <span>系统管理</span>
                </span>
            }
        >
            <Menu.Item key="/users">
                <Link to={"/users"}>
                    教师管理
                </Link>
            </Menu.Item>
            <Menu.Item key="/students">
                <Link to={"/students"}>
                    学员管理
                </Link>
            </Menu.Item>

            <Menu.Item key="/roles">
                <Link to={"/roles"}>
                    角色管理
                </Link>
            </Menu.Item>

            <Menu.Item key="/luti">
                <Link to={"/luti"}>
                    扫描录题
                </Link>
            </Menu.Item>
            {/*<Menu.Item key="3">配置</Menu.Item>*/}
        </Menu.SubMenu>

    </Menu>
);

SiderMenu.propTypes = {
    defaultSelectedKeys: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    // school: state.school,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiderMenu));




