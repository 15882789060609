import axios from './rest-axios';

const getPaperInfo = (class_id, paper_id) => axios.get(`/school/v1/papers/`, {class_id, paper_id});
const getPaperPK = (model) => axios.get(`/school/v1/papers/pk`, model);
const getStudentPaper = (id) => axios.get(`/school/v1/papers/${id}`);
const getPaperStudentPK = (paper_id,student_ids) => axios.get(`/school/v1/papers/student_pk`, {paper_id,student_ids});

export {
    getPaperInfo,
    getPaperPK,
    getStudentPaper,
    getPaperStudentPK
};
