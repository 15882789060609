import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import './App.css';
import {Layout, Menu, Row, Col} from 'antd';
import {setAccount} from "./reducers/account";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Login from './containers/login/login';
import SiderMenu from './components/sider-menu/sider-menu.jsx';
import Users from './containers/teachers/teachers';
import Roles from './containers/roles/roles';
import BindStudents from './containers/teachers/bind-student';
import TeachingDoc from './containers/teaching_doc/list';
import TeachingDocMy from './containers/teaching_doc/my';
import TeachingDocCreate from './containers/teaching_doc/create';
import TeachingDocPreview from './containers/teaching_doc/preview-page';
import MyStudents from './containers/mystudents/list';
import JingZhun from './containers/teaching_doc/jingzhun';
import JingZhunDetail from './containers/teaching_doc/jingzhun/detail-2';
import JingZhunStudent from './containers/teaching_doc/jingzhun/student';
import AccountMenu from './components/account-menu/account-menu';
import ClassBook from './containers/class_book/class_book';
import ClassPaper from './containers/class_paper/class_paper';
import Chapter from './containers/chapter/chapter';
import LuTi from './containers/luti/list';
import School from './school';
import PaperDetail from './school/containers/paper/detail';
import Kehu from './containers/zixun/kehu';
import KehuDetail from './containers/zixun/detail';
import Students from './containers/students/students';

const {Header, Sider, Content} = Layout;


class App extends Component {
    state = {
        collapsed: false,
        defaultSelectedKeys: '/'
    };

    componentWillMount() {
        let key = this.props.history.location.pathname.match(/^(\/[^\/]*)/)[1];
        this.state.defaultSelectedKeys = key;
    }

    componentDidMount() {
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {

        if(this.props.location.pathname.startsWith("/export/paper/")){
            console.log(this.props.location);
            let pathname = this.props.location.pathname;
            let passes = pathname.split('/');
            return(<PaperDetail id={parseInt(passes[passes.length-1])}/>)
        }
        // return(<)
        if (this.props.account) {
            if (this.props.account.type === 1) {
                return (
                    <JingZhunStudent/>
                )
            } else if (this.props.account.type === 3){
                return (<School />)
            } else {
                return (
                    <Layout style={{minHeight: "100vh"}}>
                        <Switch>
                            <Route path="/jingzhun">
                                <Switch>
                                    <Route exact path="/jingzhun">
                                        <JingZhun/>
                                    </Route>
                                    <Route exact path="/jingzhun/:id"
                                           component={({match}) => <JingZhunDetail id={parseInt(match.params.id)}/>}/>
                                </Switch>
                            </Route>
                            <Route>
                                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                                    <div className="logo">
                                        {this.state.collapsed
                                            ? "勤学"
                                            : "勤学教育"}
                                    </div>
                                    <SiderMenu defaultSelectedKeys={this.state.defaultSelectedKeys}/>
                                </Sider>
                                <Layout className="site-layout">
                                    <Header className="site-layout-background" style={{
                                        padding: 0,
                                        boxShadow: "0 1px 4px rgba(0,21,41,.08)",
                                        zIndex: 2,
                                    }}>
                                        <Row align="middle">
                                            <Col flex={"auto"}>
                                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                                    className: 'trigger',
                                                    onClick: this.toggle,
                                                })}
                                            </Col>
                                            <Col>
                                                <AccountMenu/>
                                            </Col>
                                        </Row>
                                        {/*<Menu mode="horizontal">*/}
                                        {/*<Menu.Item>菜单项</Menu.Item>*/}
                                        {/*<Menu.SubMenu title="子菜单">*/}
                                        {/*<Menu.Item>子菜单项</Menu.Item>*/}
                                        {/*</Menu.SubMenu>*/}
                                        {/*</Menu>*/}
                                    </Header>
                                    <Content
                                        // className="site-layout-background"
                                        // style={{
                                        //     margin: '24px 16px',
                                        //     padding: 24,
                                        //     minHeight: 280,
                                        // }}
                                    >
                                        <Switch>
                                            <Route exact path="/users"
                                                   component={Users}/>
                                            <Route exact path="/roles"
                                                   component={Roles}/>
                                            <Route exact path="/users/:id"
                                                   component={({match}) => <BindStudents
                                                       id={parseInt(match.params.id)}/>}/>
                                            <Route exact path="/teaching_doc"
                                                   component={TeachingDoc}/>
                                            <Route exact path="/teaching_doc/my"
                                                   component={TeachingDocMy}/>
                                            <Route exact path="/teaching_doc/create"
                                                   component={TeachingDocCreate}/>
                                            <Route exact path="/teaching_doc/create/:id"
                                                   component={({match}) => <TeachingDocCreate
                                                       id={parseInt(match.params.id)}/>}/>
                                            <Route exact path="/teaching_doc/preview/:id"
                                                   component={({match}) => <TeachingDocPreview
                                                       id={parseInt(match.params.id)}/>}/>
                                            <Route exact path="/mystudents"
                                                   component={MyStudents}/>

                                            <Route exact path="/class_book"
                                                   component={ClassBook}/>
                                            <Route exact path="/class_paper"
                                                   component={ClassPaper}/>
                                            <Route exact path="/chapter"
                                                   component={Chapter}/>
                                            <Route exact path="/luti"
                                                   component={LuTi}/>
                                            <Route exact path="/zixun/kehu"
                                                   component={Kehu}/>
                                            <Route exact path="/zixun/kehu/:id"
                                                   render={({match}) => <KehuDetail
                                                       id={parseInt(match.params.id)}/>}/>

                                            <Route exact path="/students"
                                                   component={Students}/>



                                            {/*<Route exact path="/"*/}
                                            {/*component={HomeworProjects}/>*/}
                                            {/*<Route exact path="/myprojects"*/}
                                            {/*component={Projects}/>*/}
                                            {/*<Route exact path="/courses"*/}
                                            {/*component={Courses}/>*/}
                                            {/*<Route exact path="/courses/:id"*/}
                                            {/*component={({match}) => <ClassesProjects id={parseInt(match.params.id)}/>}/>*/}
                                            {/*<Route exact path="/project/:id"*/}
                                            {/*component={({match}) => <Project id={parseInt(match.params.id)}/>}/>*/}
                                            {/*<Route exact path="/classes"*/}
                                            {/*component={Classes}/>*/}
                                            {/*<Route exact path="/classes/:id"*/}
                                            {/*component={({match}) => <ClassesDetail id={parseInt(match.params.id)}/>}/>*/}
                                        </Switch>
                                    </Content>
                                </Layout>
                            </Route>
                        </Switch>


                    </Layout>
                );
            }
        } else {
            return (<Login/>)
        }
    }

}

const mapStateToProps = state => ({
    // school: state.school,
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    setAccount: account => dispatch(setAccount(account)),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
