import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card,Popover} from 'antd';
import {
    PlusOutlined,
    SolutionOutlined,
    DragOutlined,
    ReadOutlined,
    CodepenOutlined,
    ContainerOutlined,
    WifiOutlined,
    ExperimentOutlined,
    SnippetsOutlined,
    AimOutlined
} from '@ant-design/icons';
import moment from 'moment';

class Preview extends Component {
    render() {
        const {data} = this.props;

        let str_important = [];
        let str_difficult = [];
        data.points.forEach(point => {
            if (point.important) {
                str_important.push(point.point_main_name)
            }
            if (point.difficult) {
                str_difficult.push(point.point_main_name)
            }
        });
        let tt_index = 0;
        let example_index = 0;
        return (
            <Card bordered={false} style={{width: 1075}} bodyStyle={{padding:"50px 80px"}} >
                <h1 style={{textAlign: "center", fontSize: 36}}>
                    <SnippetsOutlined/> {data.name}
                </h1>
                <p style={{textAlign: "center", fontSize: 18}}>
                    学生姓名：{data.student.realname}
                </p>
                <h2>
                    <CodepenOutlined/> 重难点
                </h2>
                <p style={{marginLeft: 30}}>
                    <p><strong>教学重点</strong>：{str_important.join(",")}</p>
                    <p><strong>教学难点</strong>：{str_difficult.join(",")}</p>
                </p>
                <h2>
                    <ReadOutlined/> 知识梳理
                </h2>
                <p style={{marginLeft: 30}}>
                    {data.points.map(point => <>
                    {point.point_contents
                        ? point.point_contents.filter(item => item.isShow).map((item, index) => <>
                        <h3>
                            <WifiOutlined/> 知识点-{index + 1}
                        </h3>
                        <p dangerouslySetInnerHTML={{__html: item.point_content_content}}/>
                        </>)
                        : null
                    }
                    </>)}
                    {/*{data.points.filter(item => item.isShow).map((item, index) => <>*/}
                    {/*<h3>*/}
                    {/*<WifiOutlined/> 知识点-{index + 1}*/}
                    {/*</h3>*/}
                    {/*<p dangerouslySetInnerHTML={{__html: item.point_main_content}}/>*/}
                    {/*</>)}*/}
                </p>

                <h2>
                    <ExperimentOutlined/> 例题精讲
                </h2>
                <p style={{marginLeft: 30}}>

                    {data.points.map((point, index) => {
                        let point_title = (<h2>
                            {point.point_main_name}
                        </h2>);
                        let examples = point.examples.map((item, index) =>
                            <>

                            <h3>
                                <ContainerOutlined/> 例题-{index+1}
                                {item.wrong &&
                                <Popover content={<span>
                    {moment(item.wrong.ctime*1000).format("YYYY-MM-DD")}<br/>
                                    {item.wrong.from}
                </span>}>
                                    <div style={{
                                        display:"inline-block",
                                        padding: "5px 10px",
                                        backgroundColor: "#ffffff",
                                        borderRadius: 5,
                                        border: "solid 1px red",
                                        color: "red"
                                    }}>学生错题</div>
                                </Popover>
                                }
                            </h3>
                            <p dangerouslySetInnerHTML={{__html: item.topic_content}}/>
                            </>);
                        let training1= [];
                        point.training1.forEach(id=>{
                           let topic = point.exercises.find(item=> item.topic_id===id);
                           if(topic){
                               training1.push(<><h3>
                                   <AimOutlined/> 闯关训练1-{training1.length + 1}
                               </h3>
                               <p dangerouslySetInnerHTML={{__html: topic.topic_content}}/>
                               </>)
                           }
                        });
                        // let training1 = point.exercises.filter(item => point.training1.includes(item.topic_id)).map((item, exindex) =>
                        //     <><h3>
                        //         <AimOutlined/> 闯关训练1-{exindex + 1}
                        //     </h3>
                        //     <p dangerouslySetInnerHTML={{__html: item.topic_content}}/>
                        //     </>);
                        let training2= [];
                        point.training2.forEach(id=>{
                            let topic = point.exercises.find(item=> item.topic_id===id);
                            if(topic){
                                training2.push(<><h3>
                                    <AimOutlined/> 闯关训练2-{training2.length + 1}
                                </h3>
                                <p dangerouslySetInnerHTML={{__html: topic.topic_content}}/>
                                </>)
                            }
                        });

                        // let training2 = point.exercises.filter(item => point.training2.includes(item.topic_id)).map((item, exindex) =>
                        //     <><h3>
                        //         <AimOutlined/> 闯关训练2-{exindex + 1}
                        //     </h3>
                        //     <p dangerouslySetInnerHTML={{__html: item.topic_content}}/>
                        //     </>);

                        return [point_title, ...examples,...training1, ...training2];

                        return point.topicTypes.filter(item => item.example_count > 0).map((tt, tt_index) => {
                            // tt_index += 1;
                            let examples = tt.examples.map((item, index) =>
                                <>
                                <h3>
                                    <ContainerOutlined/> 题型-{tt_index+1} 例题-{index+1}
                                </h3>
                                <p dangerouslySetInnerHTML={{__html: item.topic_content}}/>
                                </>);
                            let training1 = tt.exercises.filter(item => tt.training1.includes(item.topic_id)).map((item, exindex) =>
                                <><h3>
                                    <AimOutlined/> 题型-{tt_index+1} 闯关训练1-{exindex + 1}
                                </h3>
                                <p dangerouslySetInnerHTML={{__html: item.topic_content}}/>
                                </>)
                            let training2 = tt.exercises.filter(item => tt.training2.includes(item.topic_id)).map((item, exindex) =>
                                <><h3>
                                    <AimOutlined/> 题型-{tt_index+1} 闯关训练2-{exindex + 1}
                                </h3>
                                <p dangerouslySetInnerHTML={{__html: item.topic_content}}/>
                                </>)

                            return [...examples,...training1, ...training2];
                        });
                        //
                        // if (item.selectExamples && item.selectExamples.length > 0) {
                        //     return item.selectExamples.map((se) => {
                        //         index += 1;
                        //         return (<>
                        //         <h3>
                        //             <ContainerOutlined/> 例题-{index}
                        //         </h3>
                        //         <p dangerouslySetInnerHTML={{__html: se.topic_content}}/>
                        //         {se.exercises && se.exercises.length > 0 && se.exercises.map((ex, exindex) => <>
                        //         <h3>
                        //             <AimOutlined/> 例题-{index} 闯关训练-{exindex + 1}
                        //         </h3>
                        //         <p dangerouslySetInnerHTML={{__html: ex.topic_content}}/>
                        //         </>)}
                        //         </>);
                        //     })
                        // } else {
                        //     return null;
                        // }
                    })}
                </p>
            </Card>
        )
    }
}

Preview.propTypes = {
    data: PropTypes.object,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Preview));


