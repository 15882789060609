import PropTypes from 'prop-types';
import React from 'react';
import {Table, Button, Space} from 'antd';
import config from '../../config';
import TeachingDocStatusName from '../../components/teaching_doc/teaching-doc-status-name';
import {Link, withRouter} from 'react-router-dom';

const {Column} = Table;

const TeachingDocList = ({list}) => {
    return (
        <Table dataSource={list} rowKey={"id"} size={"small"}>
            <Column
                title="序号"
                dataIndex="id"
                key="id"
                width={80}
            />

            <Column
                title="教案名称"
                dataIndex="name"
                key="name"
            />
            <Column
                title="教师"
                dataIndex="teacher_name"
                key="teacher_name"
            />
            <Column
                title="制作时间"
                dataIndex="create_on"
                key="create_on"
            />
            <Column
                title="知识点数量"
                dataIndex="point_count"
                key="point_count"
                // render={rowSpanRender}
            />
            <Column
                title={<div style={{display: "flex"}}>
                    <div style={{flex: 1}}>涉及知识点</div>
                    {/*<div style={{width: 40}}>平均难度</div>*/}
                    {/*<div style={{width: 30}}>题型数</div>*/}
                    <div style={{width: 30}}>例题数</div>
                    <div style={{width: 30}}>手选习题</div>
                </div>}
                dataIndex="point_config"
                key="point_config"
                // render={()=> "T"}
                render={(point_config) => {
                    if (point_config) {
                        return (
                            <div>{point_config.map(item => <div style={{display: "flex"}}>
                                <div style={{flex: 1}}>{item.point_main_name}</div>
                                {/*<div style={{width: 40}}>{(item.difficult_level/100.0).toFixed(2)}</div>*/}
                                {/*<div style={{width: 30}}>{item.topicType_count}</div>*/}
                                <div style={{width: 30}}>{item.example_count}</div>
                                <div style={{width: 30}}>{item.exercises_count}</div>

                            </div>)}
                            </div>
                        )
                    }
                    return null;
                }}
            />
            <Column
                title="状态"
                dataIndex="status"
                key="status"
                width={60}
                render={(status) => (<TeachingDocStatusName status={status}/>)}
            />
            <Column
                title="完成度"
                dataIndex="id"
                key="wct"
                render={() => "T"}
            />
            <Column
                title="参考次数"
                dataIndex="use_times"
                key="use_times"
                width={50}
            />
            <Column
                title="学期"
                dataIndex="semester"
                key="semester"
                render={(semester) => {
                    let model = config.semester.find(item => item.id === semester);
                    if (model) {
                        return model.name;
                    } else {
                        return null;
                    }
                }}
            />
            <Column
                title="操作"
                key="action"
                width={100}
                render={(text, record) => (
                    <Space direction="vertical">
                        <Link to={`/teaching_doc/preview/${record.id}`}>
                            <Button>在线预览</Button>
                        </Link>
                        <Link to={{
                            pathname: `/teaching_doc/create`,
                            state: {template: record.id}
                        }}>
                            <Button>作为模板</Button>
                        </Link>
                    </Space>
                )}
            />
        </Table>
    )
};

TeachingDocList.propTypes = {
    list: PropTypes.array.isRequired
};

export default TeachingDocList