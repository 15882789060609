export default {
    host: 'http://localhost:8001',
    subjects: [
        {id: 1, name: "语文"},
        {id: 2, name: "英语"},
        {id: 3, name: "数学"},
        {id: 4, name: "物理"},
        {id: 5, name: "生物"},
        {id: 6, name: "化学"},
        {id: 11, name: "科学"},
        {id: 14, name: "历史"},
        {id: 20, name: "地理"},
        {id: 21, name: "政治"},
        {id: 22, name: "通用"},
        {id: 23, name: "信息"},
    ],
    // 年级
    grade: [
        {id: 1, name: "一年级"},
        {id: 2, name: "二年级"},
        {id: 3, name: "三年级"},
        {id: 4, name: "四年级"},
        {id: 5, name: "五年级"},
        {id: 6, name: "六年级"},
        {id: 7, name: "初一"},
        {id: 8, name: "初二"},
        {id: 9, name: "初三"},
        {id: 10, name: "高一"},
        {id: 11, name: "高二"},
        {id: 12, name: "高三"},
        {id: 13, name: "中考"},
        {id: 14, name: "高考"},
    ],

    // 2类年级
    grade2: [
        {id: 1, name: "一年级上"},
        {id: 21, name: "一年级下"},
        {id: 2, name: "二年级上"},
        {id: 22, name: "二年级下"},
        {id: 3, name: "三年级上"},
        {id: 23, name: "三年级下"},
        {id: 4, name: "四年级上"},
        {id: 24, name: "四年级下"},
        {id: 5, name: "五年级上"},
        {id: 25, name: "五年级下"},
        {id: 6, name: "六年级上"},
        {id: 26, name: "六年级下"},
        {id: 27, name: "小升初"},
        {id: 7, name: "初一上"},
        {id: 15, name: "初一下"},
        {id: 8, name: "初二上"},
        {id: 16, name: "初二下"},
        {id: 9, name: "初三上"},
        {id: 17, name: "初三下"},
        {id: 10, name: "高一上"},
        {id: 18, name: "高一下"},
        {id: 11, name: "高二上"},
        {id: 19, name: "高二下"},
        {id: 12, name: "高三上"},
        {id: 20, name: "高三下"},
        {id: 13, name: "中考"},
        {id: 14, name: "高考"},
    ],
    semester: [
        {id: 1, name: "全部"},
        {id: 2, name: "春季"},
        {id: 3, name: "暑期"},
        {id: 4, name: "秋季"},
        {id: 5, name: "寒假"},

    ],
    // 难度
    nandu: [
        {id: 1, name: "易", min: 0.85, max: 1},
        {id: 2, name: "较易", min: 0.75, max: 0.85},
        {id: 3, name: "中档", min: 0.65, max: 0.75},
        {id: 4, name: "较难", min: 0.5, max: 0.65},
        {id: 5, name: "难", min: 0, max: 0.5},
    ],
    // 题型
    tixing: [
        {id: 1, name: "单选"},
        {id: 2, name: "多选"},
        {id: 3, name: "判断"},
        {id: 4, name: "填空"},
    ],
    // 来源
    laiyuan: [
        {id: 1, name: "中考真题"},
        {id: 2, name: "期末试题"},
        {id: 3, name: "期中试题"},
        {id: 4, name: "学考试题"},
        {id: 5, name: "单元测验"},
        {id: 6, name: "同步练习"},
        {id: 7, name: "假期作业"},
        {id: 8, name: "其他"},
    ],
    // 知识点类型
    point_type: [
        {id: 1, name: "章节目录"},
        {id: 2, name: "中考目录"},
        {id: 3, name: "高考目录"},
    ]
}