import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, Tabs} from 'antd';
import Preview from '../preview-2';
import Report2 from './report-2';

const {TabPane} = Tabs;

class Report extends Component {
    render() {
        let {data,answers} = this.props;
        console.log(answers);
        return (
            <div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="课堂总结" key="1">
                        <Report2 data={data} answers={answers}/>
                    </TabPane>
                    <TabPane tab="上课讲义" key="2">
                        <Preview data={data}/>
                    </TabPane>
                    <TabPane tab="课后作业" key="3">
                        Content of Tab Pane 3
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

Report.propTypes = {
    data: PropTypes.object,
    answers: PropTypes.object,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Report));


