import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {putTeacher} from "../../lib/api_teachers";
import {
    Modal,
    Input,
    Form,
    Checkbox,
    Select
} from 'antd';
import config from '../../config';
import {getTeachers} from "../../lib/api_teachers";
import {putStudent} from "../../lib/api_kehu";


class UserEditModal extends Component {
    // formRef = React.createRef();

    state = {
        loading: false,
        list: [],
        id: 0,
        teacher_id: 0,
    };

    componentDidMount() {
        this.setState({
            teacher_id: this.props.data.teacher_id
        });
        this.fetch()
    }

    fetch = () => {
        getTeachers()
            .then(res => {
                if (res.code === 0) {
                    this.setState({
                        list: res.data.users
                    })
                }
            })
    };

    onSubmit = (values) => {
        values.id = this.props.data.id;

        putTeacher(values)
            .then(result => {
                console.log(result);
                // 刷新数据
                this.handleOK();
            })
    };


    handleFinish = () => {
        putStudent({
            id: this.props.data.id,
            teacher_id: this.state.teacher_id
        }).then(res => {
            if (res.code === 0) {
                   console.log(res);
                   this.handleOK();
            }
        })
    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {list} = this.state;
        const {data} = this.props;
        return (
            <Modal
                title="修改教师"
                visible={!!data}
                onOk={this.handleFinish}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                <Select
                    style={{width: 200}}
                    allowClear={false}
                    placeholder="选择教师"
                    optionFilterProp="children"
                    value={this.state.teacher_id}
                    onChange={(value) => this.setState({teacher_id: value})}
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                >
                    {list.map(item => <Select.Option value={item.id}>{item.realname}</Select.Option>)}
                </Select>
            </Modal>
        )
    }

}


UserEditModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditModal));
