import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from "react-router-dom";
import {connect} from 'react-redux';
import {MenuUnfoldOutlined, MenuFoldOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import PageContent from '../../../components/page-content/page-content';
import {
    Card,
    Table,
    Divider,
    Select,
    Space,
    Tabs,
    Descriptions,
    Typography,
    Row,
    Col,
    Button,
    Tooltip as AntdTooltip,
    Tag
} from 'antd';
import {getClassBookInfo, getClassBooks} from "../../../lib/api_school_classes";
import pinyin from 'pinyin';
import SubjectName from "../../../components/subject_name";
// import styles from './index.module.css';
import {getPaperInfo} from "../../../lib/api_school_papers";
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import DataSet from "@antv/data-set";
// import Pk from './pk';
import {getStudentInfo} from "../../../lib/api_school_students";
import DengDiName from '../../components/dengdi-name';
import GradeName from '../../../components/grade-name2';

import {getChapters, postSearch} from "../../../lib/api_school_books";

let {Column} = Table;
let {Option} = Select;
let {TabPane} = Tabs;
const {Text} = Typography;


class Index extends Component {
    state = {
        student_info: null,
        grades: [],
        grade: null,
        error_topics: [],
        isVIP: false,
        total_count:0,
        error_count:0
    };

    componentWillMount() {
        this.fetchData();
    }

    componentDidMount() {
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    fetchData = () => {
        let {currentClass} = this.props;
        // console.log(this.props.currentClass);
        // this.fetchClassBooks();
        getChapters(this.props.id, currentClass.grade, currentClass.subject_id)
            .then(res => {
                if (res.code === 0) {
                    console.log(res.data);
                    this.setState({
                        isVIP: true
                    });
                    if (res.data && res.data.length > 0) {
                        this.setState({
                            grades: res.data,
                        }, () => {
                            this.setGrade(res.data[0])
                        });
                    }else if(res.code === 1001){
                        this.setState({
                            isVIP: false
                        })
                    }
                }
            })
    };

    setGrade = (grade) => {
        this.setState({
            grade: grade
        }, () => {
            console.log(grade);
            if (grade.chapters && grade.chapters.length > 0) {
                this.setRootChapter(grade.chapters[0].id);
            }
        })
    };

    setRootChapter = (id) => {
        // console.log(id);
        let chapter = this.state.grade.chapters.find(item => item.id === id);
        this.setState({
            root_chapter: chapter
        }, () => {
            if (chapter.chapters && chapter.chapters.length > 0) {
                this.setChapter(chapter.chapters[0].id);
            }
        })
    };

    setChapter = (id) => {
        let chapter = this.state.root_chapter.chapters.find(item => item.id === id);
        this.setState({
            chapter: chapter
        }, () => {
            if (chapter.points && chapter.points.length > 0) {
                this.setPoint(chapter.points[0].point_main_id);
            }
        })
    };
    setPoint = (id) => {
        let point = this.state.chapter.points.find(item => item.point_main_id === id);
        this.setState({
            point: point
        },()=>{
            this.handleSearch();
        })
    };

    handleSearch = () => {
        let {currentClass} = this.props;
        let {point} = this.state;
        // 搜索操作
        postSearch(this.props.id, currentClass.grade, currentClass.subject_id, point.point_main_id)
            .then(res => {
                console.log(res);
                if (res.code === 0) {
                    this.setState({
                        error_topics: res.data,
                        total_count: res.total_count,
                        error_count: res.error_count,
                    })
                }
            })
    };

    render() {
        let {id} = this.props;
        let {grades, error_topics, isVIP,total_count,error_count} = this.state;

        if (!isVIP) {
            return <div>该学生不是会员</div>
        }
        if (!grades || grades.length === 0) {
            return null;
        }
        return (
            <PageContent title={"学生错题本"}>
                <Card bordered={false} size={"small"}>
                    <Row>
                        <Col flex={"auto"}>
                            <Space>
                                {/*<Space size={24}>*/}
                                {/*<span>学生姓名：{student_info.s_realname}</span>*/}
                                {/*<span>班级：{student_info.class_info.class}</span>*/}
                                {/*</Space>*/}
                                <Select
                                    value={this.state.grade ? this.state.grade.grade : 0}
                                    style={{width: 100}}>
                                    {grades.map(item => <Option value={item.grade}><GradeName
                                        id={item.grade}/></Option>)}
                                </Select>
                                {this.state.root_chapter && <Select
                                    value={this.state.root_chapter.id}
                                    style={{width: 200}}
                                    onChange={(value) => {
                                        this.setRootChapter(value)
                                    }}>

                                    {this.state.grade.chapters && this.state.grade.chapters.map(item => <Option
                                        value={item.id}>{item.name}</Option>)}
                                </Select>
                                }

                                {this.state.chapter && <Select
                                    value={this.state.chapter.id}
                                    style={{width: 200}}
                                    onChange={(value) => {
                                        this.setChapter(value)
                                    }}
                                >
                                    {this.state.root_chapter.chapters && this.state.root_chapter.chapters.map(item =>
                                        <Option
                                            value={item.id}>{item.name}</Option>)}
                                </Select>
                                }
                                {this.state.point && <Select
                                    value={this.state.point.point_main_id}
                                    style={{width: 200}}
                                    onChange={(value) => {
                                        this.setPoint(value)
                                    }}>
                                    {this.state.chapter.points && this.state.chapter.points.map(item => <Option
                                        value={item.point_main_id}>{item.point_main_name}</Option>)}
                                </Select>
                                }
                                {/*<Button onClick={this.handleSearch}>搜索</Button>*/}
                            </Space>
                            {/*<Select defaultValue={0} value={chapters1_id} style={{width: 200}}*/}
                            {/*onChange={(value) => this.setState({*/}
                            {/*chapters1_id: value,*/}
                            {/*chapters2_id: 0,*/}
                            {/*}, this.filterChapter2)}>*/}
                            {/*{chapters1.map(item => <Option value={item.id}>{item.name}</Option>)}*/}
                            {/*</Select>*/}
                            {/*<Select defaultValue={0} value={chapters2_id} style={{width: 200}}*/}
                            {/*onChange={(value) => this.setState({chapters2_id: value})}>*/}
                            {/*{chapters2.map(item => <Option value={item.id}>{item.name}</Option>)}*/}
                            {/*</Select>*/}

                        </Col>
                        <Col>
                            <Link to={`/school/student/${id}`}>
                                <Button>查看学生档案</Button>
                            </Link>
                        </Col>
                    </Row>
                    {/*<span>*/}
                        {/*已登记人数：{total_count}*/}
                    {/*</span>*/}

                    {/*<span>*/}
                        {/*错题总人数：{error_count}*/}
                    {/*</span>*/}
                </Card>

                <Card bordered={false} size={"small"}>

                    {error_topics.map(topic => <Card>
                        <div dangerouslySetInnerHTML={{__html: topic.topic_content}}/>
                    </Card>)}

                </Card>

            </PageContent>
        );
    }
}

Index.propTypes = {
    id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    currentClass: state.currentClass
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index));
