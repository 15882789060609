import PropTypes from 'prop-types';
import React from 'react';

const RelationTypeName = ({id}) => {
    switch (id){
        case 1:
            return "父亲";
        case 2:
            return "母亲";
        case 3:
            return "教师";
        case 0:
            return "其他";
        default:
            return "";
    }
};

RelationTypeName.propTypes={
    id: PropTypes.number.isRequired
};

export default RelationTypeName