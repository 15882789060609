/**
 * @name: 左侧菜单
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/4/21
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from "react-router-dom";
import styles from './sider-menu.module.css';
import {Menu, Icon} from 'antd';
import {connect} from 'react-redux';
import {SettingOutlined, SolutionOutlined,BankOutlined,CalculatorOutlined,FileOutlined,CloseSquareOutlined} from "@ant-design/icons";

const SiderMenu = (props) => (
    <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[props.defaultSelectedKeys]}
    >
        {/*<Menu.Item key="/">*/}
            {/*<Link to={"/"}>*/}
                {/*首页*/}
            {/*</Link>*/}
        {/*</Menu.Item>*/}
        <Menu.Item key="/school/class" icon={<BankOutlined />}>
            <Link to={"/school/class"}>
                班级信息
            </Link>
        </Menu.Item>
        <Menu.Item key="/school/books" icon={<CalculatorOutlined />}>
            <Link to={"/school/books"}>
                作业分析
            </Link>
        </Menu.Item>
        <Menu.Item key="/school/wronglist" icon={<CloseSquareOutlined />}>
            <Link to={"/school/wronglist"}>
                错题分析
            </Link>
        </Menu.Item>
        <Menu.Item key="/school/paper" icon={<FileOutlined />}>
            <Link to={"/school/paper"}>
                试卷分析
            </Link>
        </Menu.Item>
        {/*<Menu.Item key="/">*/}
            {/*<Link to={"/"}>*/}
                {/*试卷管理*/}
            {/*</Link>*/}
        {/*</Menu.Item>*/}
    </Menu>
);

SiderMenu.propTypes = {
    defaultSelectedKeys: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    // school: state.school,
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiderMenu));




