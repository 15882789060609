import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    Form,
    Steps,
    Row,
    Col,
    Input,
    Select,
    Radio,
    Typography,
    message,
    Space
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDoc} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import PointSelect from './point-select';
import MyStudentsSelect from './my-students-select';
import {getMytudents} from "../../lib/api_students";
import GradeName from '../../components/grade-name';
import {postTeachingDoc} from "../../lib/api_teaching_doc";
import {getPointsStat} from "../../lib/api_point";
import {getTeachingDocPrev} from "../../lib/api_teaching_doc";

const {CheckableTag} = Tag;
const {Text} = Typography;


class Step1 extends Component {

    formRef = React.createRef();


    state = {
        step: 0,
        data: {
            name: "", // 教案名称
            teacher_id: 0, // 老师id
            grade: 0, // 年级
            subject: 0, // 学科
            semester: 1, // 学期
            student_id: 0, // 学员id
            points: [], // 知识点列表,
        },
        myStudentList: [],
        studentList: [],
        subjectList: [],
        currentStudent: null,
        prevDoc: null, // 上次教案
        prevPoints: [], // 上次教案的知识点
    };

    componentWillMount() {
        this.setState({data: Object.assign(this.props.data, {point: JSON.stringify(this.props.data.points)})});
        // this.formRef.current.setFieldsValue(nextProps.data);
        // console.log(nextProps.data)
    }

    componentDidMount() {


        this.fetchData()
    }

    // componentWillReceiveProps(nextProps){
    //     if(nextProps.data){
    //         this.setState({data: nextProps.data});
    //         this.formRef.current.setFieldsValue(nextProps.data);
    //         console.log(nextProps.data)
    //     }
    // }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    fetchData = () => {
        // getTeachingDoc()
        //     .then((result) => {
        //         if (result.code === 0) {
        //             this.setState({
        //                 list: result.data,
        //             })
        //         }
        //     })
        this.fetchStudentData();

        this.fetchPointData()

        // 获取上次教案知识点
        this.fetchPrevDoc();

    };
    fetchPrevDoc = () => {
        let {data} = this.state;
        if (data.student_id) {

            getTeachingDocPrev(data.student_id, data.subject, data.id)
                .then(res => {
                    if (res.code === 0) {
                        // console
                        if (res.data && res.data.point_config) {
                            this.setState({
                                prevPoints: JSON.parse(res.data.point_config)
                            })
                        }
                    }
                })
        }
    };
    fetchPointData = (n, x) => {
        let {data} = this.state;
        if (data.student_id) {
            getPointsStat(data.student_id, data.points.map(item => item.point_main_id))
                .then(res => {
                    if (res.code === 0) {
                        console.log(res)
                        // 加载数据
                        data.points.forEach(point => {
                            let info = res.data.find(item => item.point_main_id === point.point_main_id);
                            if (info) {
                                point.right_count = info.right_count;
                                point.wrong_count = info.wrong_count;
                                point.learn_times = info.learn_times;
                            } else {
                                point.right_count = 0;
                                point.wrong_count = 0;
                                point.learn_times = 0;
                            }
                        });
                        this.formRef.current.setFieldsValue({
                            point: JSON.stringify(data.points)
                        })
                    }
                })
        }
    };
    // 获取学生数据
    fetchStudentData = () => {
        // 获得我的学生
        getMytudents()
            .then((result) => {
                if (result.code === 0) {
                    // 处理学生数据
                    let studentList = []; // 学员
                    result.data.forEach(item => {
                        if (studentList.findIndex((stu) => stu.id === item.student_id) === -1) {
                            studentList.push({
                                id: item.student_id,
                                name: item.realname
                            })
                        }
                    });

                    this.setState({
                        myStudentList: result.data,
                        studentList: studentList
                    }, () => {
                        if (this.state.data.student_id > 0) {
                            this.handleStudentChange(this.state.data.student_id)
                        }
                    })
                }
            })
    };


    // 选择学生
    handleStudentChange = (id) => {
        // 生成学科数据
        // let currentStudent = null;// 学生信息
        const subjectList = []; // 科目
        this.state.myStudentList.filter(item => item.student_id === id).forEach(item => {
            let subject = config.subjects.find(sub => sub.id === item.subject);
            if (subject) {
                subjectList.push({
                    id: item.subject,
                    name: subject.name
                })
            }
        });

        let subject = this.state.data.subject;
        if (subjectList.length > 0) {
            if (subjectList.findIndex(item => item.id === subject) === -1) {
                subject = subjectList[0].id
            }
        }

        this.setState({
            // student_id: id,
            subjectList: subjectList,
            currentStudent: this.state.myStudentList.find(item => item.student_id === id),
            data: Object.assign(this.state.data, {student_id: id})
            // subject: subject,
        }, () => {
            this.handleSubjectChange(subject);
            this.formRef.current.setFieldsValue({
                subject: subject || null,
            });


            // this.props.onChange && this.props.onChange({
            //     student_id: id,
            //     subject: subject
            // })
        })
    };

    // 选择科目
    handleSubjectChange = (value) => {
        this.setState({data: Object.assign(this.state.data, {subject: value})}, () => {
            this.fetchPrevDoc();
        })

    };

    onFinish = values => {
        console.log(values);
        // {student_id: 6, subject: 11, semester: 1, point: "[{"point_main_id":60,"point_main_name":"地球上水的不同分类"…me":"水和生命 ","important":false,"difficult":false}]", name: "123"}
        this.setState({
            data: Object.assign(this.state.data, values, {
                student: this.state.currentStudent,
                points: JSON.parse(values.point),
                point: ""
            })
        }, () => {
            this.state.data.points.forEach(item => {
                if (!item.topicTypes) {
                    item.topicTypes = [];
                }
                if (!item.point_contents) {
                    item.point_contents = [];
                }
            });
            // 数据返回
            this.props.onSubmit && this.props.onSubmit(this.state.data);
        });
    };

    handleSave = () => {
        let {currentStudent, data} = this.state;
        this.formRef.current.validateFields()
            .then(values => {
                console.log(values);
                this.setState({
                    data: Object.assign(this.state.data, values, {
                        student: this.state.currentStudent,
                        points: JSON.parse(values.point),
                        point: ""
                    })
                }, () => {
                    this.props.onSave && this.props.onSave(data);
                });

            })

    };


    render() {
        const {step, data, studentList, subjectList, currentStudent} = this.state;

        console.log(data);
        return (
            <Card bordered={false}>
                <Form ref={this.formRef}
                      initialValues={data}
                      onFinish={this.onFinish}
                >
                    <div className="ant-pro-table-toolbar">
                        <div className="ant-pro-table-toolbar-title"><SolutionOutlined/> 学生信息</div>
                    </div>
                    <div>
                        <Form.Item
                            label="学员"
                            required
                        >
                            <Form.Item
                                name="student_id"
                                noStyle
                                rules={[{type:"number", required: true, min:1, message: '请选择学员'}]}
                            >
                                <Select
                                    showSearch
                                    style={{width: 200}}
                                    placeholder="选择学生"
                                    optionFilterProp="children"
                                    // value={student_id || ""}
                                    onChange={(value) => this.handleStudentChange(value)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {studentList.map(item => <Select.Option
                                        value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            {currentStudent && <span>
                            <span style={{marginLeft: 20}}>年级：<Text type="secondary"><GradeName
                                id={currentStudent.grade}/></Text></span>
                            <span style={{marginLeft: 20}}>学校：<Text type="secondary">{currentStudent.school_name}</Text></span>
                            <span style={{marginLeft: 20}}>授课次数：<Text
                                type="secondary">{currentStudent.study_times}</Text></span>
                            <span style={{marginLeft: 20}}>最近测试成绩：<Text
                                type="secondary">{currentStudent.last_score}</Text></span>
                            <span style={{marginLeft: 20}}>薄弱知识点：<Text type="secondary">T</Text></span>
                        </span>}

                        </Form.Item>
                        <Form.Item
                            label="科目"
                            name="subject"
                            rules={[{type:"number",required: true,min:1,  message: '请选择科目'}]}
                        >
                            <Select
                                // showSearch
                                style={{width: 200}}
                                virtual={false}
                                placeholder="选择科目"
                                optionFilterProp="children"
                                // value={subject || ""}
                                onChange={(value) => this.handleSubjectChange(value)}
                            >
                                {subjectList.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                            </Select>
                        </Form.Item>


                        <Form.Item
                            label="学期"
                            name="semester"
                            rules={[{required: true, message: 'Please input your username!'}]}
                        >
                            <Radio.Group>
                                {config.semester.map(item => <Radio value={item.id}>{item.name}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div className="ant-pro-table-toolbar">
                        <div className="ant-pro-table-toolbar-title"><SolutionOutlined/> 知识点选择</div>
                    </div>
                    <Form.Item
                        name="point"
                        rules={[{required: true, message: '请选择知识点'}]}
                    >
                        <PointSelect student_id={data.student_id}
                                     n={currentStudent ? currentStudent.grade : 0}
                                     x={data.subject}
                                     prevPoints={this.state.prevPoints}
                        />
                        {/*<PointSelect n={6} x={3}/>*/}
                    </Form.Item>


                    <div className="ant-pro-table-toolbar">
                        <div className="ant-pro-table-toolbar-title"><SolutionOutlined/> 教案信息完善</div>
                    </div>
                    <div>
                        <Form.Item
                            label="教案名称"
                            name="name"
                            rules={[{required: true, message: '请输入教案名称'}]}
                        >
                            <Input style={{width: 300}}/>
                        </Form.Item>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <Space>
                            <Button type="primary" onClick={this.handleSave}>
                                暂存
                            </Button>

                            <Button type="primary" htmlType="submit">
                                下一步
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        )
    }
}


Step1.propTypes = {
    // classes_id: PropTypes.number
    data: PropTypes.object,
    onSubmit: PropTypes.func
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1));


