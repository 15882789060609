import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    Row,
    Col,
    Typography,
    Tree,
    Space,
    Empty,
    Affix,
    Checkbox,
} from 'antd';
import config from '../../config';
import {PlusOutlined, SolutionOutlined} from '@ant-design/icons';
import GradeName from '../../components/grade-name';
import {getExample, getExampleChild} from "../../lib/api_example";
import TopicOpt from '../../components/teaching_doc/topic-opt';
import moment from 'moment';
import styles from './step2.module.css';
import {putCollect, putUnCollect} from "../../lib/api_topic_collect";
import {getTopics} from "../../lib/api_topic";

const {CheckableTag} = Tag;
const {Text} = Typography;
const {DirectoryTree} = Tree;

class Step2 extends Component {

    formRef = React.createRef();


    state = {
        step: 0,
        data: {
            name: "", // 教案名称
            teacher_id: 0, // 老师id
            grade: 0, // 年级
            subject: 0, // 学科
            semester: 1, // 学期
            student_id: 0, // 学员id
            points: [], // 知识点列表,
            topics: [], // 例题列表

        },
        examples: [], // 母题和子题
        topics: [], // 所有题目
        point_contents: [], // 知识点内容
        topicType: null, // 当前题型
        currentExample: null, // 当前母题
        currentPoint: null, // 当前知识点
        currentPointContents: [], // 当前的知识点内容
        canNext: false, // 是否允许下一步
        laiyuan: [], // 题目来源
        filter: {
            nandu: 0,
            tixing: 0,
            laiyuan: 0,
            year: 0,
            order: 0
        }
    };

    componentWillMount() {
        let {data} = this.props;
        console.log(data);
        data.points.forEach(point => {
            if (!point.examples) point.examples = [];
            if (!point.exercises) point.exercises = [];
            if (!point.notUse) point.notUse = [];
            if (!point.training1) point.training1 = [];
            if (!point.training2) point.training2 = [];
        });
        this.setState({
            data: data
        }, () => {
            this.resetTree();
        })
    }

    componentDidMount() {
        // // 数据格式化
        // let {data} = this.props;
        // let point = JSON.parse(data.point);
        // this.setState({
        //     point: point
        // }, ()=>{
        //     this.fetchData()
        // });
        // console.log(point);
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        // 读取所有母题(一次性获取所有数据)
        // console.log(this.props.data);
        let {data} = this.state;
        let pointids = data.points.map(item => item.point_main_id).join(',');
        console.log(pointids);
        getTopics(pointids)
            .then(res => {
                console.log(res);
                if (res.code === 0) {
                    // 保存题目数据
                    this.setState({
                        topics: res.data.topics,
                        point_contents: res.data.point_contents
                    });

                    this.statCount();
                    // this.resetTree();
                }
            })
    };


    // 重置树结构
    resetTree = () => {
        let treeData = [];
        let {points} = this.state.data;
        points.forEach(point => {
            // if (!point.selectExamples) point.selectExamples = [];
            treeData.push({
                title: point.point_main_name,
                key: point.point_main_id,
                isLeaf: true,
                data: point,
            },)
        });
        this.setState({
            treeData
        })
    };

    onSelect = (keys, event) => {
        console.log('Trigger Select', keys, event);
        if (keys.length > 0) {
            // console.log(event.node.data);
            // 设置选中状态
            let currentPoint = event.node.data;
            // 知识点内容
            let currentPointContents = this.getPointContents(event.node.pid);
            currentPointContents.forEach(item => {
                if (currentPoint.point_contents.findIndex(pc => pc.point_content_id === item.point_content_id) !== -1) {
                    item.isShow = true;
                }
            });


            // let laiyuan = [];
            // if (currentExample && currentExample.children.length > 0) {
            //     console.log(currentExample);
            //     currentExample.children.forEach(item => {
            //         if (!laiyuan.includes(item.source2)) {
            //             laiyuan.push(item.source2);
            //         }
            //     })
            // }


            this.setState({
                currentPoint: currentPoint,
                currentPointContents: currentPointContents,
                // laiyuan: laiyuan
            }, () => {
                let topics = this.getTopics();
                let {currentPoint} = this.state;
                currentPoint.examples.forEach(item => {
                    let model = topics.find(topic => topic.topic_id === item.topic_id);
                    if (model) {
                        model.isExample = true;
                    }
                });
                currentPoint.exercises.forEach(item => {
                    let model = topics.find(topic => topic.topic_id === item.topic_id);
                    if (model) {
                        model.isExercise = true;
                    }
                });
                currentPoint.notUse.forEach(item => {
                    let model = topics.find(topic => topic.topic_id === item);
                    if (model) {
                        model.isNotUse = true;
                    }
                });
                this.statCount();
                // if (!event.node.data.isRemove) {
                //     this.fetchTopicData(keys[0]);
                // }
            });
        }
        // this.
        // this.props.onChange && this.props.onChange(keys[0], event.node.title);
    };

    getPointContents = (id) => {
        return this.state.point_contents.filter(item => item.point_main_uid === id);
    };

    // 获得知识点
    getPoint = (data) => {
        let {points} = this.state.data;

        let point = points.find(item => item.point_main_id === data.point_main_uid);
        if (!point.selectExamples) {
            point.selectExamples = [];
        }
        if (!point.exercises) {
            point.exercises = [];
        }
        return point;
    };
    getSimpleTopic = (data) => {
        return {
            // example_child_id: data.example_child_id,
            // example_id: data.example_uid || data.example_id,
            topic_id: data.topic_id,
            topic_content: data.topic_content,
            analysis: data.analysis,
            answers: data.answers,
            isExample: data.isExample,
            isNotUse: data.isNotUse,
            isExercise: data.isExercise,
            difficult_level: data.difficult_level,
            correct: data.correct,
            wrong: data.wrong
        }
    };
    // 将题目插入例题
    pushExample = (data) => {
        // let point = this.getPoint(data);
        let {currentPoint} = this.state;
        if (currentPoint) {
            // let selectExamples = point.selectExamples || [];
            currentPoint.examples.push(this.getSimpleTopic(data));
            // point.selectExamples = selectExamples;
            data.isExample = true;
            return true;
        }
        return false;
    };
    popExample = (data) => {
        // let point = this.getPoint(data);
        let {currentPoint} = this.state;
        if (currentPoint) {
            // let selectExamples = point.selectExamples || [];
            currentPoint.examples = currentPoint.examples.filter(item => item.topic_id !== data.topic_id);
            data.isExample = false;
            // point.selectExamples = selectExamples;
            return true;
        }
        return false;
    };

    // 设为例题
    handleExample = (data) => {
        this.popExercise(data);
        this.popNotUse(data);
        if (this.pushExample(data)) {
            this.statCount();
            // this.resetTree();
        }
    };
    // 取消例题
    handleUnExample = (data) => {
        if (this.popExample(data)) {
            this.statCount();
            // this.resetTree();
        }
    };

    // 将题目插入练习
    pushExercise = (data) => {
        // let point = this.getPoint(data);
        console.log(data);
        let {currentPoint} = this.state;
        if (currentPoint) {
            // let exercises = point.exercises || [];
            currentPoint.exercises.push(this.getSimpleTopic(data));
            // point.exercises = exercises;
            data.isExercise = true;
            return true;
        }
        return false;
    };
    popExercise = (data) => {
        // let point = this.getPoint(data);
        let {currentPoint} = this.state;
        if (currentPoint) {
            // let exercises = point.exercises || [];
            // console.log(data);
            // console.log(topicType.exercises);
            currentPoint.exercises = currentPoint.exercises.filter(item => item.topic_id !== data.topic_id);
            data.isExercise = false;
            // point.exercises = exercises;
            return true;
        }
        return false;
    };

    // 加入练习
    handleExercise = (data) => {
        this.popExample(data);
        this.popNotUse(data);
        if (this.pushExercise(data)) {
            this.statCount();
            // this.resetTree();
            // this.setState({});
        }
    };
    // 取消加入练习
    handleUnExercise = (data) => {
        if (this.popExercise(data)) {
            this.statCount();
            // this.resetTree();
            // this.setState({});
        }
    };

    // 将题目插入不使用
    pushNotUse = (data) => {
        // let point = this.getPoint(data);
        let {currentPoint} = this.state;
        if (currentPoint) {
            // let notUse = point.notUse || [];
            currentPoint.notUse.push(data.topic_id);
            // point.notUse = notUse;
            data.isNotUse = true;
            return true;
        }
        return false;
    };
    // 将题目移出不使用
    popNotUse = (data) => {
        // let point = this.getPoint(data);
        let {currentPoint} = this.state;
        if (currentPoint) {
            // let notUse = point.notUse || [];
            currentPoint.notUse = currentPoint.notUse.filter(item => item !== data.topic_id);
            data.isNotUse = false;
            // point.notUse = notUse;
            return true;
        }
        return false;
    };

    // 加入不使用
    handleNotUse = (data) => {
        this.popExample(data);
        this.popExercise(data);
        if (this.pushNotUse(data)) {
            this.statCount();
            // this.resetTree();
            // this.setState({});
        }
    };
    // 取消加入不使用
    handleUse = (data) => {
        if (this.popNotUse(data)) {
            this.statCount();
            // this.resetTree();
            // this.setState({});
        }
    };

    // 收藏
    handleCollect = (data) => {
        putCollect(data.topic_id)
            .then(res => {
                if (res.code === 0) {
                    data.isCollect = true;
                    this.setState({})
                    // this.fetchData()
                }
            })
    };

    // 取消收藏
    handleUnCollect = (data) => {
        putUnCollect(data.topic_id)
            .then(res => {
                if (res.code === 0) {
                    data.isCollect = false;
                    // this.fetchData()
                    this.setState({})
                }
            })
    };


    getTopic = (id) => {
        let {examples} = this.state;
        for (let i in examples) {
            if (examples[i].topic_id === id) {
                return examples[i];
            }
            for (let j in examples[i].children) {
                if (examples[i].children[j].topic_id === id) {
                    return examples[i].children[j];
                }
            }

        }
    };


    handlePointContent = (data, checked) => {
        data.isShow = checked;
        let {currentPoint} = this.state;
        if (checked) {
            currentPoint.point_contents.push({...data});
        } else {
            currentPoint.point_contents = currentPoint.point_contents.filter(item => item.point_content_id !== data.point_content_id)
        }
        this.setState({})
    };

    // 获得母题
    getExample = (example_id) => {
        let {examples} = this.state;
        return examples.find(item => item.example_id === example_id);
    };


    handleSave = () => {
        this.props.onSave && this.props.onSave(this.state.data);
    };

    handlePrev = () => {
        this.props.onPrev && this.props.onPrev(this.state.data);
    };

    handleNext = () => {
        this.props.onNext && this.props.onNext(this.state.data);
    };

    // 统计数量
    statCount = () => {
        let {data} = this.state;
        let canNext = true;
        // 统计涉及知识点，题型，例题，练习题
        let point_count = 0;  // 知识点
        let example_count = 0;  // 例题
        let exercises_count = 0;  //练习题
        data.points.forEach(point => {
            point.example_count = point.examples.length; // 例题数量
            point.exercises_count = point.exercises.length; // 练习题数量
            point.wrong_count = point.exercises.filter(item => item.correct === 0).length; // 错题数量

            example_count += point.example_count;
            exercises_count += point.exercises_count;
            if (point.example_count > 0) {
                point_count += 1;
            }
        });
        data.point_count = point_count;
        data.example_count = example_count;
        data.exercises_count = exercises_count;
        if (point_count === 0) {
            canNext = false;
        }
        this.setState({data: data, canNext: canNext})
    };

    getTopics = () => {
        const {currentPoint, topics} = this.state;
        if (currentPoint) {
            return topics.filter(item => item.answers.some(ans => ans.topic_answer_point1 === currentPoint.point_main_id || ans.topic_answer_point2 === currentPoint.point_main_id || ans.topic_answer_point3 === currentPoint.point_main_id))
        }
        return [];
    };

    render() {
        const {data, treeData, filter, topicType, currentExample, currentPoint, currentPointContents, canNext, topics} = this.state;
        let filterChildren = [];
        // 获得当前知识点下的题目
        let laiyuan = [];
        let filterTopics = []
        if (currentPoint) {
            filterTopics = topics.filter(item => item.answers.some(ans => ans.topic_answer_point1 === currentPoint.point_main_id || ans.topic_answer_point2 === currentPoint.point_main_id || ans.topic_answer_point3 === currentPoint.point_main_id))
        }

        // 获得所有年份
        let years = [];
        if (filterTopics) {
            filterTopics.forEach(item => {
                if (item.topic_time) {
                    let time = moment(item.topic_time);
                    if (time) {
                        let year = time.year();
                        if (!years.includes(year)) {
                            years.push(year)
                        }
                    }
                }
                if (!laiyuan.includes(item.source)) {
                    laiyuan.push(item.source);
                }
            });
            years.sort((a, b) => {
                return a < b ? 1 : -1
            });
            if (filter.nandu) {
                let nandu = config.nandu.find(item => item.id === filter.nandu);
                if (nandu) {
                    filterTopics = filterTopics.filter(item => item.difficult_level / 100.0 >= nandu.min && item.difficult_level / 100.0 <= nandu.max);
                }
            }
            if (filter.laiyuan) {
                filterTopics = filterTopics.filter(item => item.source === filter.laiyuan);
            }
            if (filter.year > 0) {
                filterTopics = filterTopics.filter(item => moment(item.topic_time).year() === filter.year);
            }
            if (filter.tixing > 0) {
                filterTopics = filterTopics.filter(item => {
                    for (let i in item.answers) {
                        if (item.answers[i].topic_answer_type === filter.tixing) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            switch (filter.order) {
                case 0:// 综合排序，学生错题，难度系数
                    filterTopics.sort((a, b) => {
                        if (a.correct === b.correct) {
                            if (a.difficult_level === b.difficult_level) {
                                return 0;
                            } else {
                                return a.difficult_level - b.difficult_level
                            }

                        }
                        if (a.correct === 0) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                    break;
                case 1:// 使用次数，次数，时间
                    filterTopics.sort((a, b) => {
                        return b.use_times - a.use_times;
                    });
                    break;
                case 2:// 试题难度，难度系数，时间
                    filterTopics.sort((a, b) => {
                        if (a.difficult_level === b.difficult_level) {
                            if (moment(a.topic_time).isBefore(b.topic_time)) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return a.difficult_level - b.difficult_level;
                        }
                    });
                    break;
                case 3:// 收藏，收藏，时间
                    filterTopics.sort((a, b) => {
                        if (a.isCollect === b.isCollect) {
                            if (moment(a.topic_time).isBefore(b.topic_time)) {
                                return 1
                            } else {
                                return -1
                            }
                        }
                        if (a.isCollect) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                    break;
            }
        }


        return (
            <div>
                <Card bordered={false} size={"small"}>
                    <Row align="middle">
                        <Col flex={"auto"}>
                            <Space size={24}>
                                <Text>
                                    教案名称：<Text type="secondary">{data.name}</Text>
                                </Text>
                                <Text>
                                    姓名：<Text type="secondary">{data.student.realname}</Text>
                                </Text>
                                <Text>
                                    年级：<Text type="secondary"><GradeName id={data.student.grade}/></Text>
                                </Text>
                                <Text>
                                    涉及知识点：<Text type="secondary">{data.point_count}</Text>
                                </Text>
                                <Text>
                                    例题：<Text type="secondary">{data.example_count}</Text>
                                </Text>
                                <Text>
                                    练习题：<Text type="secondary">{data.exercises_count}</Text>
                                </Text>
                            </Space>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={this.handleSave}>
                                    保存草稿
                                </Button>
                                <Button type="primary" onClick={this.handlePrev}>
                                    上一步
                                </Button>
                                <Button type="primary" onClick={this.handleNext} disabled={!canNext}>
                                    下一步
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
                <Row style={{flexWrap: "nowrap"}} gutter={[16, 16]}>
                    <Col flex="240px">
                        <Affix offsetTop={10}>
                            <DirectoryTree
                                multiple={false}
                                // height={300}
                                defaultExpandAll
                                defaultExpandParent
                                expandedKeys={data.points.map(item => item.point_main_id)}
                                onSelect={this.onSelect}
                                // onExpand={onExpand}
                                treeData={treeData}
                            />
                        </Affix>
                    </Col>
                    <Col flex="auto">
                        {currentPoint &&
                        <>

                        <Card bordered={false} size={"small"}>
                            {/*{currentPoint.point_main_content}*/}
                            <Space size={24}>
                                <Text>
                                    已选例题：{currentPoint.example_count} 题
                                </Text>
                                <Text>
                                    已选练习题：{currentPoint.exercises_count} 题
                                </Text>
                                <Text>
                                    学生错题：{currentPoint.wrong_count} 题
                                </Text>
                            </Space>
                        </Card>


                        <Card bordered={false} size={"small"}>
                            <Space direction="vertical">
                                <div>
                                    难度：
                                    <CheckableTag
                                        checked={filter.nandu === 0}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.nandu = 0;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        全部
                                    </CheckableTag>
                                    {config.nandu.map(item => (
                                        <CheckableTag
                                            key={item.id}
                                            checked={filter.nandu === item.id}
                                            onChange={checked => {
                                                if (checked) {
                                                    filter.nandu = item.id;
                                                    this.setState({})
                                                }
                                            }}
                                        >
                                            {item.name}
                                        </CheckableTag>
                                    ))}
                                </div>
                                <div>
                                    题型：
                                    <CheckableTag
                                        checked={filter.tixing === 0}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.tixing = 0;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        全部
                                    </CheckableTag>
                                    {config.tixing.map(item => (
                                        <CheckableTag
                                            key={item.id}
                                            checked={filter.tixing === item.id}
                                            onChange={checked => {
                                                if (checked) {
                                                    filter.tixing = item.id;
                                                    this.setState({})
                                                }
                                            }}
                                        >
                                            {item.name}
                                        </CheckableTag>
                                    ))}
                                </div>
                                <div>
                                    来源：
                                    <CheckableTag
                                        checked={!filter.laiyuan}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.laiyuan = null;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        全部
                                    </CheckableTag>
                                    {laiyuan.map(item => (
                                        <CheckableTag
                                            key={item}
                                            checked={filter.laiyuan === item}
                                            onChange={checked => {
                                                if (checked) {
                                                    filter.laiyuan = item;
                                                    this.setState({})
                                                }
                                            }}
                                        >
                                            {item}
                                        </CheckableTag>
                                    ))}
                                </div>
                                <div>
                                    年份：
                                    <CheckableTag
                                        checked={filter.year === 0}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.year = 0;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        全部
                                    </CheckableTag>
                                    {years.map(item => (
                                        <CheckableTag
                                            key={item}
                                            checked={filter.year === item}
                                            onChange={checked => {
                                                if (checked) {
                                                    filter.year = item;
                                                    this.setState({})
                                                }
                                            }}
                                        >
                                            {item}
                                        </CheckableTag>
                                    ))}
                                    {/*{config.laiyuan.map(item => (*/}
                                    {/*<CheckableTag*/}
                                    {/*key={item.id}*/}
                                    {/*checked={filter.laiyuan === item.id}*/}
                                    {/*// onChange={checked => this.handleGradeChange(item.id, checked)}*/}
                                    {/*>*/}
                                    {/*{item.name}*/}
                                    {/*</CheckableTag>*/}
                                    {/*))}*/}
                                </div>
                                <div>
                                    排序：
                                    <CheckableTag
                                        checked={filter.order === 0}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.order = 0;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        综合排序
                                    </CheckableTag>
                                    <CheckableTag
                                        checked={filter.order === 1}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.order = 1;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        使用次数
                                    </CheckableTag>
                                    <CheckableTag
                                        checked={filter.order === 2}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.order = 2;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        试题难度
                                    </CheckableTag>
                                    <CheckableTag
                                        checked={filter.order === 3}
                                        onChange={checked => {
                                            if (checked) {
                                                filter.order = 3;
                                                this.setState({})
                                            }
                                        }}
                                    >
                                        我的收藏
                                    </CheckableTag>
                                    {/*{config.laiyuan.map(item => (*/}
                                    {/*<CheckableTag*/}
                                    {/*key={item.id}*/}
                                    {/*checked={filter.laiyuan === item.id}*/}
                                    {/*// onChange={checked => this.handleGradeChange(item.id, checked)}*/}
                                    {/*>*/}
                                    {/*{item.name}*/}
                                    {/*</CheckableTag>*/}
                                    {/*))}*/}
                                </div>
                            </Space>
                        </Card>
                        {filterTopics.length === 0
                            ? <Card bordered={false}><Empty/></Card>
                            : filterTopics.map(item =>
                                <TopicOpt
                                    data={item}
                                    onExample={this.handleExample}
                                    onUnExample={this.handleUnExample}
                                    onExercise={this.handleExercise}
                                    onUnExercise={this.handleUnExercise}
                                    onUse={this.handleUse}
                                    onNotUse={this.handleNotUse}
                                    onCollect={this.handleCollect}
                                    onUnCollect={this.handleUnCollect}
                                />)}


                        </>
                        }


                    </Col>
                    <Col flex={"300px"}>
                        <Card bordered={false} size={"small"} title={"知识点列表"}>

                            {(currentPointContents && currentPointContents.length > 0) ?
                                currentPointContents.map(item =>
                                    <div bordered={false} size={"small"} className={styles.point_main_content}>
                                        {/*{currentPoint.point_main_content}*/}
                                        <div dangerouslySetInnerHTML={{__html: item.point_content_content}}/>
                                        <Checkbox checked={item.isShow} onChange={(e) => {
                                            this.handlePointContent(item, e.target.checked);
                                            // item.isShow = e.target.checked;
                                            // this.setState({})
                                        }}>加入教案</Checkbox>
                                    </div>)
                                : <Empty/>
                            }
                        </Card>
                    </Col>
                </Row>

            </div>
        )
    }
}


Step2.propTypes = {
    // classes_id: PropTypes.number
    data: PropTypes.object,
    currentStudent: PropTypes.object,
    onPrev: PropTypes.func,
    onSave: PropTypes.func,
    onNext: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2));


