import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    Form,
    Steps,
    Row,
    Col,
    Input,
    Select
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDoc} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import PointTree from './point-tree';
import Step1 from './step1';
import {getMytudents} from "../../lib/api_students";

const {CheckableTag} = Tag;
const {Option} = Select;


class MyStudentsSelect extends Component {
    state = {
        // student_id: 0,
        // subject: 0,
        list: [],
        studentList: [],
        subjectList: []
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        // 获得我的学生
        getMytudents()
            .then((result) => {
                if (result.code === 0) {
                    // 处理学生数据
                    let studentList = []; // 学员
                    result.data.forEach(item => {
                        if (studentList.findIndex((stu) => stu.id === item.student_id) === -1) {
                            studentList.push({
                                id: item.student_id,
                                name: item.realname
                            })
                        }
                    });

                    this.setState({
                        list: result.data,
                        studentList: studentList
                    })
                }
            })
    };

    handleStudentChange = (id) => {
        // 生成学科数据
        const subjectList = []; // 科目
        this.state.list.filter(item => item.student_id === id).forEach(item => {
            let subject = config.subjects.find(sub => sub.id === item.subject);
            if (subject) {
                subjectList.push({
                    id: item.subject,
                    name: subject.name
                })
            }
        });
        let subject = 0;
        if (subjectList.length > 0) {
            subject = subjectList[0].id
        }

        this.setState({
            student_id: id,
            subjectList: subjectList,
            subject: subject,
        }, ()=>{
            this.props.onChange && this.props.onChange({
                student_id: id,
                subject: subject
            })
        })
    };

    handleSubjectChange = (value)=>{
        this.props.onChange && this.props.onChange({
            student_id: this.props.student_id,
            subject: value
        })
    };


    render() {
        let {studentList, subjectList} = this.state;
        let {student_id, subject} = this.props;
        // let studentList = []; // 学员
        // const subjectList = []; // 科目
        // list.forEach(item => {
        //     if (studentList.findIndex((stu) => stu.id === item.student_id) === -1) {
        //         studentList.push({
        //             id: item.student_id,
        //             realname: item.realname
        //         })
        //     }
        //     if (student_id > 0 && item.student_id === student_id) {
        //         let subject = config.subjects.find(sub => sub.id === item.subject);
        //         if (subject) {
        //             subjectList.push({
        //                 id: item.subject,
        //                 name: subject.name
        //             })
        //         }
        //     }
        // });
        // if (subjectList.length > 0 && (subject === 0 || subjectList.findIndex(item => item.id === subject) === -1)) {
        //     subject = subjectList[0].id;
        //     this.state.subject = subject;
        // }
        console.log(subject);

        return (
            <div>
                <Form.Item
                    label="学员"
                    // name="student_id"
                    rules={[{required: true, message: '请选择学员'}]}
                >
                    <Select
                        showSearch
                        style={{width: 200}}
                        placeholder="选择学生"
                        optionFilterProp="children"
                        value={student_id || ""}
                        onChange={(value) => this.handleStudentChange(value)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {studentList.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="科目"
                    // name="subject"
                    rules={[{required: true, message: '请选择科目'}]}
                >
                    <Select
                        // showSearch
                        style={{width: 200}}
                        virtual={false}
                        placeholder="选择科目"
                        optionFilterProp="children"
                        value={subject || ""}
                        onChange={(value) => this.handleSubjectChange(value)}
                    >
                        {subjectList.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)}
                    </Select>
                </Form.Item>
            </div>
        )
    }
}


MyStudentsSelect.propTypes = {
    student_id: PropTypes.number,
    subject: PropTypes.number,
    onChange: PropTypes.func
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyStudentsSelect));


