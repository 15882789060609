import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
// import './App.css';
import {Layout, Menu, Row, Col} from 'antd';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import SiderMenu from './components/sider-menu/sider-menu.jsx';
import AccountMenu from './components/account-menu/account-menu';
import ClassInfo from './containers/class';
import Books from './containers/books';
import WrongList from './containers/wronglist';
import Paper from './containers/paper';
import Student from './containers/student';
import PaperDetail from './containers/paper/detail';
import StudentBook from './containers/student/book';

const {Header, Sider, Content} = Layout;


class App extends Component {
    state = {
        collapsed: false,
        defaultSelectedKeys: '/'
    };

    componentWillMount() {
        let key = this.props.history.location.pathname.match(/^(\/[^\/]*)/)[1];
        this.state.defaultSelectedKeys = key;
    }

    componentDidMount() {
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        let {currentClass} = this.props;
        return (
            <Layout style={{minHeight: "100vh"}}>
                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>

                    <div className="logo">
                        {currentClass ? (this.state.collapsed
                                ? currentClass.school_name.slice(0, 2)
                                : currentClass.school_name
                        ) : "老师版"}
                    </div>
                    <SiderMenu defaultSelectedKeys={this.state.defaultSelectedKeys}/>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{
                        padding: 0,
                        boxShadow: "0 1px 4px rgba(0,21,41,.08)",
                        zIndex: 2,
                    }}>
                        <Row align="middle">
                            <Col flex={"auto"}>
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: this.toggle,
                                })}
                            </Col>
                            <Col>
                                <AccountMenu/>
                            </Col>
                        </Row>
                        {/*<Menu mode="horizontal">*/}
                        {/*<Menu.Item>菜单项</Menu.Item>*/}
                        {/*<Menu.SubMenu title="子菜单">*/}
                        {/*<Menu.Item>子菜单项</Menu.Item>*/}
                        {/*</Menu.SubMenu>*/}
                        {/*</Menu>*/}
                    </Header>
                    <Content
                        // className="site-layout-background"
                        // style={{
                        //     margin: '24px 16px',
                        //     padding: 24,
                        //     minHeight: 280,
                        // }}
                        key={currentClass.class_id}
                    >
                        <Switch>
                            <Route exact path="/school/class"
                                   component={ClassInfo}/>
                            <Route exact path="/school/books"
                                   component={Books}/>
                            <Route exact path="/school/wronglist"
                                   component={WrongList}/>
                            <Route exact path="/school/paper"
                                   component={Paper}/>
                            <Route exact path="/school/student/:id"
                                   component={({match}) => <Student
                                       id={parseInt(match.params.id)}/>}/>/>
                            <Route exact path="/school/paper/:id"
                                   component={({match}) => <PaperDetail
                                       id={parseInt(match.params.id)}
                                   />}
                            />
                            <Route exact path="/school/student/:id/book"
                                   component={({match}) => <StudentBook
                                       id={parseInt(match.params.id)}/>}/>/>



                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    currentClass: state.currentClass
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
