import axios from './rest-axios';

const getRoles = () => axios.get('/v1/roles/');
const postRoles = (model) => axios.post('/v1/roles/', model);
const putRoles = (model) => axios.put(`/v1/roles/${model.id}`, model);
const deleteRoles = (id) => axios.delete(`/v1/roles/${id}`);

export {
    getRoles,
    postRoles,
    putRoles,
    deleteRoles
};
