import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Card,
    Tag,
    Button,
    Form,
    Steps,
    Row,
    Col,
    Input,
    message
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import config from '../../config';
import {getTeachingDocById} from "../../lib/api_teaching_doc";
import {PlusOutlined, SolutionOutlined} from '@ant-design/icons';
import TeachingDocList from '../../components/teaching_doc/teaching-doc-list';
import PointTree from './point-tree';
import Step1 from './step1';
import Step2 from './step2-2';
import Step3 from './step3-2';
import Step4 from './step4-2';
import {postTeachingDoc,submitTeachingDoc} from "../../lib/api_teaching_doc";

const {CheckableTag} = Tag;


class TeachingDocCreate extends Component {
    state = {
        data: {
            id: 0,
            step: -1, // 第几步了
            name: "", // 教案名称
            teacher_id: 0, // 老师id
            grade: 0, // 年级
            subject: 0, // 学科
            semester: 1, // 学期
            student_id: 0, // 学员id
            points: [], // 知识点列表,
            point_count:0, // 总知识点数
            topicType_count:0, // 总题型数
            example_count:0, // 总例题数
            exercises_count:0, // 总练习题数
            // topics: [], // 例题列表

        }
    };

    componentDidMount() {

        let template_id = this.props.history.location.state ? this.props.history.location.state.template : 0;
        if(template_id){
            this.fetchTemplateData(template_id);
        }else{
            if (this.props.id > 0) {
                this.fetchData()
            } else {
                this.setState(Object.assign(this.state.data, {step: 0}))
            }
        }
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchTemplateData = (template_id) => {
        getTeachingDocById(template_id)
            .then((result) => {
                if (result.code === 0) {
                    console.log(result);
                    this.setState({
                        data: Object.assign(JSON.parse(result.data.config), {
                            id: 0,
                            step: 0,
                            teacher_id: 0,
                            grade: 0,
                            subject: 0,
                            semester: 1,
                            student_id: 0
                        }),
                        // step: 0,
                    })
                }
            })
    };


    fetchData = () => {
        getTeachingDocById(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    console.log(result);
                    this.setState({
                        data: Object.assign(JSON.parse(result.data.config), {id: this.props.id}),
                        // step: 0,
                    })
                }
            })
    };

    next = (data) => {
        this.setState({
            data: Object.assign(this.state.data, data, {step: this.state.data.step + 1})
        });
        // const step = this.state.step + 1;
        // this.setState({step});
    };

    prev = (data) => {
        console.log(data);
        this.setState({
            data: Object.assign(this.state.data, data, {step: this.state.data.step - 1})
        });
        // const step = this.state.step - 1;
        // this.setState({step});
    };

    handleStep1Submit = (data) => {
        // console.log(data, currentStudent);

        this.setState({
            data: Object.assign(this.state.data, data),
        });
        this.next();
    };

    handleSave = (data) => {
        // let {data} = this.state;
        this.setState({
            data: Object.assign(this.state.data, data),
        }, () => {
            let points = this.state.data.points;
            // 计算平均难度 difficult_level
            // points.forEach(point=>{
            //     let difficult_level_total = 0;
            //     let difficult_level_count = 0;
            //     point.topicTypes.forEach(tt=>{
            //         tt.examples.forEach(te=>{
            //             console.log(te.difficult_level);
            //             difficult_level_total += te.difficult_level;
            //             difficult_level_count += 1
            //         });
            //         tt.exercises.forEach(te=>{
            //             console.log(te.difficult_level);
            //             difficult_level_total += te.difficult_level;
            //             difficult_level_count += 1
            //         });
            //     });
            //     console.log(difficult_level_total, difficult_level_count);
            //     point.difficult_level = difficult_level_total/difficult_level_count;
            // });
            console.log(points);
            postTeachingDoc({
                id: data.id,
                name: data.name,
                grade: data.student.grade,
                subject: data.subject,
                semester: data.semester,
                student_id: data.student_id,
                config: JSON.stringify(this.state.data),
                point_count: points.length,
                point_config: JSON.stringify(points.map(point => {
                    return {
                        point_main_id: point.point_main_id,
                        point_id: point.point_id,
                        point_main_name: point.point_main_name,
                        topicType_count: point.topicType_count, // 题型数
                        example_count: point.example_count, // 例题数
                        exercises_count: point.exercises_count, // 练习题数
                        difficult_level: point.difficult_level,
                    }
                }))
            })
                .then(res => {
                    if (res.code === 0) {
                        message.success("保存成功");
                        if (data.id === 0 && res.data) {
                            data.id = res.data;
                            this.props.history.push(`/teaching_doc/create/${res.data}`);
                        }
                    }
                })

        });

    };

    handleSubmit = (data)=>{
        let points = this.state.data.points;

        submitTeachingDoc({
            id: data.id,
            name: data.name,
            grade: data.student.grade,
            subject: data.subject,
            semester: data.semester,
            student_id: data.student_id,
            config: JSON.stringify(this.state.data),
            point_count: points.length,
            point_config: JSON.stringify(points.map(point => {
                return {
                    point_main_id: point.point_main_id,
                    point_id: point.point_id,
                    point_main_name: point.point_main_name,
                    topicType_count: point.topicType_count, // 题型数
                    example_count: point.example_count, // 例题数
                    exercises_count: point.exercises_count, // 练习题数
                    difficult_level: point.difficult_level,
                }
            }))
        })
            .then(res => {
                if (res.code === 0) {
                    message.success("提交成功");
                    this.props.history.push(`/teaching_doc/my`);
                }
            })
    }

    render() {
        const {data, currentStudent} = this.state;
        const {step} = data;

        return (
            <PageContent
                title={"制作教案"}
                routes={[
                    {
                        path: '//teaching_doc',
                        breadcrumbName: '教案中心',
                    },
                    {
                        path: `//${this.props.id}`,
                        breadcrumbName: '制作教案',
                    },
                ]}
            >


                <Card bordered={false}>
                    <Steps current={step}>
                        <Steps.Step key={0} title={"基本信息"}/>
                        <Steps.Step key={1} title={"选题"}/>
                        <Steps.Step key={2} title={"呈现顺序"}/>
                        <Steps.Step key={3} title={"确认效果"}/>
                    </Steps>
                </Card>
                <div>
                    {step === 0 && <Step1 data={data}
                                          onSubmit={this.handleStep1Submit}
                                          onSave={this.handleSave}
                    />}
                    {step === 1 &&
                    <Step2 data={data}
                           currentStudent={currentStudent}
                           onPrev={this.prev}
                           onSave={this.handleSave}
                           onNext={this.next}
                    />}
                    {step === 2 &&
                    <Step3 datame={data}
                           currentStudent={currentStudent}
                           onPrev={this.prev}
                           onSave={this.handleSave}
                           onNext={this.next}
                    />}
                    {step === 3 &&
                    <Step4 datame={data}
                           onPrev={this.prev}
                           onSave={this.handleSave}
                           onSubmit={this.handleSubmit}
                    />}
                    {/*{step === 1 && <Step1 onSubmit={this.handleStep1Submit}/>}*/}


                    {/*{step < 3 && (*/}
                    {/*<Button type="primary" onClick={() => this.next()}>*/}
                    {/*下一步*/}
                    {/*</Button>*/}
                    {/*)}*/}
                    {/*{step === 3 && (*/}
                    {/*<Button type="primary">*/}
                    {/*提交*/}
                    {/*</Button>*/}
                    {/*)}*/}
                    {/*{step > 0 && (*/}
                    {/*<Button style={{margin: 8}} onClick={() => this.prev()}>*/}
                    {/*上一步*/}
                    {/*</Button>*/}
                    {/*)}*/}
                </div>
            </PageContent>
        )
    }
}


TeachingDocCreate.propTypes = {
    id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TeachingDocCreate));


