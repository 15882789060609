import axios from './rest-axios';

const getTeachers = () => axios.get('/v1/teachers/');
const postTeacher = (model) => axios.post('/v1/teachers/', model);
const putTeacher = (model) => axios.put(`/v1/teachers/${model.id}`, model);
const deleteTeacher = (id) => axios.delete(`/v1/teachers/${id}`);
const resetPassword = (id, password) => axios.put(`/v1/teachers/${id}/resetpassword`, {password});
const getBindStudentsInfo = (id) => axios.get(`/v1/teachers/${id}/bindstudents`);
const postBindStudentsInfo = (model) => axios.post(`/v1/teachers/${model.teacher_id}/bindstudents`, model);
const deleteBindStudentsInfo = (id) => axios.delete(`/v1/teachers/${id}/bindstudents`);

export {
    getTeachers,
    postTeacher,
    putTeacher,
    deleteTeacher,
    resetPassword,
    getBindStudentsInfo,
    postBindStudentsInfo,
    deleteBindStudentsInfo
};
